import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import AppLayout from '../../layout/app.layout';
import Loading from '../../components/table-elements/loading.component';
import SnackbarInfo from '../../components/common/snackbar-info.component';
import { checkAdminUserLoggedIn } from '../../utils/local-cache.util';
import {
  adminUserChangePassword,
  adminUserValidateSession,
} from '../../services/auth.service';
import validationRules from '../../utils/validations.util';
import Iconify from '../../components/common/iconify.component';
import ROUTE_PATH from '../../config/routes.config';

const UserBasicDetails = ({ user }) => (
  <Box
    sx={{
      backgroundColor: '#f5f5f5',
      padding: 2,
      borderRadius: 2,
      boxShadow: 1,
    }}
  >
    <Typography variant="h6" gutterBottom>
      Basic Details
    </Typography>
    <Typography>Name: {user.name}</Typography>
    <Typography>Email: {user.email}</Typography>
    <Typography>Role: {user.role}</Typography>
  </Box>
);

UserBasicDetails.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

const ChangePassword = ({ onSuccess }) => {
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });
  const passwordFieldValues = watch('password');

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      newPassword: data.password,
    };
    adminUserChangePassword(payload)
      .then(() => {
        onSuccess('Password changed successfully.');
        navigate(ROUTE_PATH.LOGOUT);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: 2,
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      <Typography variant="h6" gutterBottom>
        Change Password
      </Typography>
      <form id="change-password" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="password"
              control={control}
              rules={{
                ...validationRules.REQUIRED,
                ...validationRules.PASSWORD,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="New Password *"
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.password}
                  helperText={errors?.password?.message || null}
                  fullWidth
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          title={
                            !showPassword ? 'Show password' : 'Hide password'
                          }
                        >
                          <Iconify
                            icon={
                              !showPassword
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                ...validationRules.REQUIRED,
                ...validationRules.PASSWORD,
                validate: (value) =>
                  value !== passwordFieldValues
                    ? 'Passwords do not match.'
                    : true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Re-enter New Password *"
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.confirmPassword}
                  helperText={errors?.confirmPassword?.message || null}
                  fullWidth
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          title={
                            !showPassword ? 'Show password' : 'Hide password'
                          }
                        >
                          <Iconify
                            icon={
                              !showPassword
                                ? 'eva:eye-fill'
                                : 'eva:eye-off-fill'
                            }
                          />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            type="submit"
            form="change-password"
            color="primary"
            variant="contained"
            disabled={formSubmitted}
          >
            Change Password
          </Button>
        </Box>
      </form>
      <Typography variant="body2" color="secondary" mt={2}>
        * indicates a required field
      </Typography>
      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

ChangePassword.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

const Profile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [user, setUser] = useState({ name: '', email: '', role: '' });

  useEffect(() => {
    const isAdminLoggedIn = checkAdminUserLoggedIn();
    if (isAdminLoggedIn) {
      adminUserValidateSession()
        .then((res) => {
          const loggedInUserPayload = {
            name: res.data?.name || '',
            email: res.data?.email || '',
            role: res.data?.role || '',
          };
          setUser(loggedInUserPayload);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <AppLayout pageTitle="My Profile" showBack={false}>
      <Box height="100%" display="flex">
        <Box flexGrow={1} display="flex" flexDirection="column">
          {loading && <Loading />}

          {!loading && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <UserBasicDetails user={user} />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <ChangePassword
                  onCancel={() => navigate(-1)}
                  onSuccess={(message) =>
                    setSnackbarInfo({ show: true, type: 'success', message })
                  }
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </AppLayout>
  );
};

export default Profile;
