import React from 'react';
import { Paper, Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import CellValue from './cell-value.component';

const ViewGridData = ({ columns, row }) => (
  <Box component={Paper} padding={2}>
    <Grid container spacing={2}>
      {columns.map((c) => (
        <Grid item xs={12} sm={6} md={6}>
          <Box fontWeight="bold">{c.label}</Box>
          <CellValue
            type={c.type}
            dataKey={c.dataKey}
            value={row[c.dataKey] || '-'}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
);

ViewGridData.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  row: PropTypes.shape().isRequired,
};

export default ViewGridData;
