import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSMTPSettings from '../../components/smtp/list-smtp-settings.component';

const SMTPSettingsView = () => {
  const pageTitle = 'Settings / Mail Server';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListSMTPSettings />
    </AppLayout>
  );
};

export default SMTPSettingsView;
