const MANAGE_COLUMNS = [
  {
    id: 'ROKU',
    label: 'Roku',
    dataKey: 'ROKU',
  },
  {
    id: 'FIRETV',
    label: 'Fire TV',
    dataKey: 'FIRETV',
  },
  {
    id: 'FIRE_MOBILE',
    label: 'Fire Mobile',
    dataKey: 'FIRE_MOBILE',
  },
  {
    id: 'ANDROIDTV',
    label: 'Android TV',
    dataKey: 'ANDROIDTV',
  },
  {
    id: 'ANDROID_MOBILE',
    label: 'AndroidMobile',
    dataKey: 'ANDROID_MOBILE',
  },
  {
    id: 'APPLETV',
    label: 'Apple TV',
    dataKey: 'APPLETV',
    type: 'TEXT',
  },
  {
    id: 'APPLE_MOBILE',
    label: 'Apple Mobile',
    dataKey: 'APPLE_MOBILE',
  },
  {
    id: 'WEB',
    label: 'Web',
    dataKey: 'WEB',
  },
  {
    id: 'SAMSUNGTV',
    label: 'Samsung TV',
    dataKey: 'SAMSUNGTV',
  },
  {
    id: 'LGTV',
    label: 'LG TV',
    dataKey: 'LGTV',
  },
];

export default MANAGE_COLUMNS;
