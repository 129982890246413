import React, { useState, useEffect } from 'react';
import {
  Card,
  Stack,
  Button,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../common/iconify.component';
import {
  approveVideoEdits,
  getVideoEditHistoryByUser,
  getVideoEditHistoryByVideo,
  rejectVideoEdits,
} from '../../services/video-edit-history.service';
import VideoEditFields from '../form-elements/video-edit-fields.component';
import Loading from '../table-elements/loading.component';
import NoData from '../table-elements/no-data.component';
import Error from '../table-elements/error.component';
import { formatTimestamp } from '../../utils/datetime.util';
import { VIDEO_EDIT_FIELDS } from '../../config/const.config';

const VideoEditApprovals = ({ dataId, byVideo }) => {
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    filtersCounter: 0,
  });
  const [selectedVideoField, setSelectedVideoField] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleApprove = (id) => {
    setProcessing(true);
    approveVideoEdits({ id: [id] })
      .then(() => {
        setProcessing(false);
        handleRefreshData();
      })
      .catch(() => {
        setProcessing(false);
      });
  };
  const handleRemove = (id) => {
    setProcessing(true);
    rejectVideoEdits({ id: [id] })
      .then(() => {
        setProcessing(false);
        handleRefreshData();
      })
      .catch(() => {
        setProcessing(false);
      });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    if (selectedVideoField) {
      params.push(`field=${selectedVideoField}`);
    }
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    const fetchData = byVideo
      ? getVideoEditHistoryByVideo
      : getVideoEditHistoryByUser;

    fetchData(dataId, paramsQuery)
      .then((res) => {
        setOptions((prevOptions) => ({
          ...prevOptions,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        }));
      })
      .catch(() => {
        setOptions((prevOptions) => ({
          ...prevOptions,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        }));
      });
  }, [options.reloadCounter]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 0, py: 2 }}
      >
        <Box width="20%" display="flex" alignItems="center">
          <VideoEditFields
            id="video_field"
            name="video_field"
            label="Video Field"
            defaultValue={selectedVideoField}
            onChange={(e) => {
              setSelectedVideoField(e.target.value);
              handleRefreshData();
            }}
          />
        </Box>
        <Box>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </Box>
      </Stack>

      <Card>
        {options.rows.length > 0 && (
          <TablePagination
            component="div"
            count={options.totalRows}
            page={options.page}
            onPageChange={(e, newPage) => handlePageChange(newPage)}
            rowsPerPage={options.rowsPerPage}
            onRowsPerPageChange={(e) => {
              handleRowsPerPageChange(Number(e.target.value));
            }}
          />
        )}

        <TableContainer>
          {options.loading && <Loading />}

          {!options.loading && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    width="10%"
                    sx={{
                      cursor: 'pointer',
                      padding: '16px',
                      fontSize: '0.875rem !important',
                    }}
                  >
                    Edited Time
                  </TableCell>
                  <TableCell
                    width="10%"
                    sx={{
                      cursor: 'pointer',
                      padding: '16px',
                      fontSize: '0.875rem !important',
                    }}
                  >
                    Video
                  </TableCell>
                  <TableCell
                    width="15%"
                    sx={{
                      cursor: 'pointer',
                      padding: '16px',
                      fontSize: '0.875rem !important',
                    }}
                  >
                    Field
                  </TableCell>
                  <TableCell
                    width="15%"
                    sx={{
                      cursor: 'pointer',
                      padding: '16px',
                      fontSize: '0.875rem !important',
                    }}
                  >
                    Old value
                  </TableCell>
                  <TableCell
                    width="15%"
                    sx={{
                      cursor: 'pointer',
                      padding: '16px',
                      fontSize: '0.875rem !important',
                    }}
                  >
                    New value
                  </TableCell>
                  <TableCell
                    width="15%"
                    sx={{
                      cursor: 'pointer',
                      padding: '16px',
                      fontSize: '0.875rem !important',
                      textAlign: 'center',
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {options.rows.length > 0 &&
                  options.rows.map((v) => (
                    <TableRow key={`row-${v.id}`}>
                      <TableCell width="15%" sx={{ verticalAlign: 'center' }}>
                        <Typography variant="body2" component="span">
                          {formatTimestamp(v.editTime || null, 'lll')}
                        </Typography>
                      </TableCell>
                      <TableCell
                        width="20%"
                        sx={{
                          verticalAlign: 'center',
                        }}
                      >
                        {v.videoTitle}
                      </TableCell>
                      <TableCell
                        width="20%"
                        sx={{
                          verticalAlign: 'center',
                        }}
                      >
                        {VIDEO_EDIT_FIELDS[v.field]}
                      </TableCell>
                      <TableCell
                        width="15%"
                        sx={{
                          verticalAlign: 'center',
                        }}
                      >
                        {v.currentValue}
                      </TableCell>
                      <TableCell
                        width="15%"
                        sx={{
                          verticalAlign: 'center',
                        }}
                      >
                        {v.changedTo}
                      </TableCell>
                      <TableCell
                        width="15%"
                        sx={{
                          verticalAlign: 'center',
                        }}
                      >
                        <Box display="flex">
                          <Button
                            color="success"
                            variant="contained"
                            startIcon={<Iconify icon="charm:tick" />}
                            onClick={() => handleApprove(v.id)}
                            disabled={options.loading || processing}
                          >
                            Approve
                          </Button>
                          <Button
                            color="primary"
                            variant="contained"
                            startIcon={
                              <Iconify icon="material-symbols-light:delete" />
                            }
                            onClick={() => handleRemove(v.id)}
                            disabled={options.loading || processing}
                            sx={{ ml: 1 }}
                          >
                            Remove
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {!options.loading &&
                  !options.error &&
                  options.totalRows === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <NoData />
                      </TableCell>
                    </TableRow>
                  )}

                {!options.loading &&
                  options.error &&
                  options.totalRows === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        <Error />
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          )}
        </TableContainer>

        {options.rows.length > 0 && (
          <TablePagination
            component="div"
            count={options.totalRows}
            page={options.page}
            onPageChange={(e, newPage) => handlePageChange(newPage)}
            rowsPerPage={options.rowsPerPage}
            onRowsPerPageChange={(e) => {
              handleRowsPerPageChange(Number(e.target.value));
            }}
          />
        )}
      </Card>
    </Box>
  );
};

VideoEditApprovals.propTypes = {
  dataId: PropTypes.number.isRequired,
  byVideo: PropTypes.bool,
};
VideoEditApprovals.defaultProps = {
  byVideo: false,
};

export default VideoEditApprovals;
