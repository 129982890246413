import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListSubscriptions from '../../components/billing/list-subscriptions.component';

const BillingSubscriptionsView = () => {
  const pageTitle = 'Manage Users / All Subscribers';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListSubscriptions />
    </AppLayout>
  );
};

export default BillingSubscriptionsView;
