/* eslint-disable import/prefer-default-export */
import CELL_DATA_TYPES from '../cell-types.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    width: '98%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TOOLTIP_TEXT,
  },
];
