import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import {
  ROKU_FEED_STATUS_LABELS,
  ROKU_FEED_STATUS_COLORS,
} from '../../config/const.config';

const RokuFeedStatusLabel = ({ value }) => {
  const label = ROKU_FEED_STATUS_LABELS[value] || 'Unknown';
  const color = ROKU_FEED_STATUS_COLORS[value] || 'primary';

  return (
    <Chip
      label={label}
      color={color}
      variant="filled"
      size="small"
      sx={{ fontWeight: 700 }}
    />
  );
};

RokuFeedStatusLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default RokuFeedStatusLabel;
