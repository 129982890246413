import { LS_ADMIN_USER_AUTH_TOKEN } from '../config/const.config';
import { getDeviceId } from './device-info.util';
import { getLocalValue } from './local-cache.util';

// Get option headers for admin API calls
const prepareRequestOptions = (url, method, data = null, auth = false) => {
  const baseAPIUrl = process.env.REACT_APP_CMS_API_ENDPOINT;
  const uuid = getDeviceId();

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Req-Device': uuid,
    },
    data: JSON.stringify(data || {}),
  };
  if (auth) {
    const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);
    options.headers['X-Admin-Auth-Token'] = adminAuth;
  }

  return options;
};

export const prepareUploadRequestOptions = (
  url,
  method,
  data = null,
  auth = false
) => {
  const baseAPIUrl = process.env.REACT_APP_CMS_API_ENDPOINT;
  const uuid = getDeviceId();

  const options = {
    url: `${baseAPIUrl}${url}`,
    method,
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Req-Device': uuid,
    },
    data,
  };
  if (auth) {
    const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);
    options.headers['X-Admin-Auth-Token'] = adminAuth;
  }

  return options;
};

export const prepareS3UploadRequestOptions = (
  url,
  method,
  data = null,
  auth = false,
  onUploadProgress = null
) => {
  const options = {
    url: `${url}`,
    method,
    headers: {
      'Content-Type': data.type,
    },
    data,
  };
  if (auth) {
    const adminAuth = getLocalValue(LS_ADMIN_USER_AUTH_TOKEN);
    options.headers['X-Admin-Auth-Token'] = adminAuth;
  }

  if (onUploadProgress) {
    options.onUploadProgress = onUploadProgress;
  }

  return options;
};
export default prepareRequestOptions;
