import React from 'react';
import AppLayout from '../../layout/app.layout';
import TrialSubscribers from '../../components/reports/trial-subscribers.component';

const TrialSubscribersView = () => {
  const pageTitle = 'Analytics / Monetization / Trial Period Subscribers';

  return (
    <AppLayout pageTitle={pageTitle}>
      <TrialSubscribers />
    </AppLayout>
  );
};

export default TrialSubscribersView;
