import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListCouponCodes from '../../components/coupon-codes/list-coupon-codes.component';

const CouponCodesView = () => {
  const pageTitle = 'Manage Channels / Promo Codes';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListCouponCodes />
    </AppLayout>
  );
};

export default CouponCodesView;
