import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/packages.service';
import StyledDialogActions from '../../theme/dialogActionStyles';
import Status from '../form-elements/status.component';
import ConfirmPopup from '../common/confirm-popup.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditContentAppPackage = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  channelId,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      name: '',
      frequency: '',
      price: null,
      features: '',
      sku: '',
      status: 1,
      order: 0,
      trialDays: 0,
      createForAllChannel: false,
    },
  });

  const [showConfirmDailog, setShowConfirmDailog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const initialPriceRef = useRef(null);

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New package created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setShowConfirmDailog(false);
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('Package details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const handleSaveData = () => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const data = getValues();
    const payload = {
      channelId,
      name: data.name,
      frequency: data.frequency,
      price: data.price,
      features: data.features,
      sku: data.sku,
      trialDays: data.trialDays || 0,
      status: data.status,
      order: data.order,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      payload.createForAllChannel = data.createForAllChannel;
      saveNewData(payload);
    }
  };

  const onFormSubmit = (data) => {
    if (
      (dataId && initialPriceRef.current === data.price) ||
      (!dataId && !data.createForAllChannel)
    ) {
      handleSaveData();
    } else if (data.createForAllChannel) {
      setShowConfirmDailog(true);
    } else if (initialPriceRef.current !== data.price) {
      setShowConfirmDailog(true);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        initialPriceRef.current = res.data?.price;
        setValue('name', res.data?.name || '');
        setValue('frequency', res.data?.frequency || '');
        setValue('price', res.data?.price || 0);
        setValue(
          'features',
          res.data?.features.replaceAll('<br />', '\n') || ''
        );
        setValue('sku', res.data?.sku || '');
        setValue('trialDays', res.data?.trialDays || '');
        setValue('status', res.data?.status || 0);
        setValue('order', res.data?.packageOrder || 0);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}
        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(100),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Name *"
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="frequency"
                  control={control}
                  rules={{
                    ...maxLengthValidation(100),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Frequency"
                      type="text"
                      error={!!errors.frequency}
                      helperText={errors?.frequency?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="price"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Price *"
                      type="number"
                      error={!!errors.price}
                      helperText={errors?.price?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="trialDays"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Trial Days"
                      type="number"
                      error={!!errors.trialDays}
                      helperText={errors?.trialDays?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="features"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Features *"
                      type="text"
                      error={!!errors.features}
                      minRows={3}
                      multiline
                      helperText={errors?.features?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="sku"
                  control={control}
                  rules={{
                    ...maxLengthValidation(50),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="SKU"
                      type="text"
                      error={!!errors.sku}
                      helperText={errors?.sku?.message || null}
                      disabled={dataId}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="cc_status"
                      name="cc_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="order"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Order *"
                      type="number"
                      error={!!errors.order}
                      helperText={errors?.order?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              {!dataId && !!channelId && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="createForAllChannel"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="createForAllChannel"
                            onChange={onChange}
                            value={value}
                            defaultChecked={false}
                          />
                        }
                        label="Create for all channels"
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {showConfirmDailog && (
        <ConfirmPopup
          title="Price change confirmation"
          message={`You're attempting to change the price of the product. Please confirm this change before proceeding. This price will take affect on future new subscriptions, existing subscriptions would still use the old pricing.`}
          onCancel={() => setShowConfirmDailog(false)}
          onSuccess={handleSaveData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditContentAppPackage.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  channelId: PropTypes.number.isRequired,
};

export default AddEditContentAppPackage;
