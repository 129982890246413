// Define cell data type
const CELL_DATA_TYPES = {
  TEXT: 'text',
  EMAIL: 'email',
  NUMBER: 'number',
  DATE: 'date',
  DATETIME: 'datetime',
  TIMESTAMP: 'timestamp',
  DURATION: 'duration',
  STATUS: 'status',
  PLATFORM: 'platform',
  MONEY: 'money',
  PERCENTAGE: 'percent',
  BOOL: 'boolean',
  CODE: 'code',
  ADMIN_ROLE: 'role',
  PASSWORD: 'password',
  SUBSCRIPTION_SKU: 'sub-sku',
  MIGRATION_STATUS: 'migration-status',
  COUPON_APPLICABLE_ON: 'coupon-applicable-on',
  BRD_STATUS: 'brd-status',
  HTML: 'html',
  SWITCH: 'switch',
  LINK: 'link',
  SECONDARY_GENRES: 'secondory-genres',
  LIST_IMAGE: 'list-image',
  VIEW_IMAGE: 'view-image',
  MANUAL_STATUS: 'manual-status',
  CONTENT_OWNER_ROLE: 'content-owner-role',
  PNM_CELL: 'pnm-cell',
  SUB_LIST: 'sub-list',
  ROKU_FEED_STATUS: 'roku-feed-status',
  AD_TIMING: 'ad-timing',
  TOOLTIP_TEXT: 'tooltip-text',
  PLAYLIST_CHANNELS: 'playlist-channels',
};

export default CELL_DATA_TYPES;
