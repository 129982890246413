import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnStrikeThrough,
  BtnUnderline,
  Editor,
  EditorProvider,
  HtmlButton,
  Toolbar,
} from 'react-simple-wysiwyg';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/faqs.service';
import StyledDialogActions from '../../theme/dialogActionStyles';
import Status from '../form-elements/status.component';
import ConfirmPopup from '../common/confirm-popup.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditContentAppFAQ = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  channelId,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      status: 1,
      order: 0,
      createForAllChannel: false,
    },
  });

  const [showConfirmDailog, setShowConfirmDailog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New FAQ created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('FAQ details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const handleSaveData = () => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const data = getValues();
    const payload = {
      channelId,
      title: data.title,
      description: data.description,
      status: data.status,
      order: data.order,
      createForAllChannel: data.createForAllChannel,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  const onFormSubmit = (data) => {
    if (data.createForAllChannel) {
      setShowConfirmDailog(true);
    } else {
      handleSaveData();
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('description', res.data?.description || '');
        setValue('status', res.data?.status || 0);
        setValue('order', res.data?.faqOrder || 0);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...maxLengthValidation(120),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Title *"
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      variant="filled"
                      onBlur={() => {
                        const titleValue = getValues('title');
                        const currentHeader = getValues('header');
                        if (!currentHeader) {
                          setValue('header', titleValue);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <EditorProvider>
                      <Editor value={value} onChange={onChange}>
                        <Toolbar style={{ backgroundColor: '#919eab1f' }}>
                          <Box
                            padding="0px 8px"
                            color="#637381"
                            borderRight="1px solid #637381"
                            fontSize="15px"
                          >
                            Description
                          </Box>
                          <BtnBold />
                          <BtnItalic />
                          <BtnUnderline />
                          <BtnStrikeThrough />
                          <BtnNumberedList />
                          <BtnBulletList />
                          <BtnLink />
                          <BtnClearFormatting />
                          <HtmlButton />
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                  )}
                />
              </Grid>
              <style>
                {`
                  .rsw-editor ul {
                    list-style-type: disc !important;
                    padding-left: 20px !important;
                  }

                  .rsw-editor ol {
                    list-style-type: decimal !important;
                    padding-left: 20px !important;
                  }

                  ul {
                    list-style: disc !important;
                  }
                `}
              </style>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="cc_status"
                      name="cc_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="order"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Order *"
                      type="number"
                      error={!!errors.order}
                      helperText={errors?.order?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
              {!!channelId && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="createForAllChannel"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="createForAllChannel"
                            onChange={onChange}
                            value={value}
                            defaultChecked={false}
                          />
                        }
                        label={`${
                          dataId
                            ? 'Update for all channels'
                            : 'Create for all channels'
                        }`}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {showConfirmDailog && (
        <ConfirmPopup
          title={`${
            dataId ? 'Update' : 'Create'
          } for all channels confirmation`}
          message={`You have selected '${
            dataId ? 'update' : 'create'
          } for all channels'. Do you want to ${
            dataId ? 'update' : 'create'
          } ${getValues(
            'title'
          )} faq for all channels? You can not undo this action!`}
          onCancel={() => setShowConfirmDailog(false)}
          onSuccess={handleSaveData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditContentAppFAQ.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  channelId: PropTypes.number.isRequired,
};

export default AddEditContentAppFAQ;
