const MANAGE_COLUMNS = [
  {
    id: 'homePageHeroImage',
    label: 'Home Page Hero Image',
    dataKey: 'homePageHeroImage',
    type: 'IMAGE',
  },
  {
    id: 'browsePageBgImage',
    label: 'Browse Page Background Image',
    dataKey: 'browsePageBgImage',
    type: 'IMAGE',
  },
  {
    id: 'favIconImage',
    label: 'Fav Icon Image',
    dataKey: 'favIconImage',
    type: 'IMAGE',
  },
  {
    id: 'logoImage',
    label: 'Logo Image',
    dataKey: 'logoImage',
    type: 'IMAGE',
  },
  {
    id: 'themeBackgroundColor',
    label: 'Theme Background Color',
    dataKey: 'themeBackgroundColor',
    type: 'COLOR',
    text: 'Note: This color will be used for website background.',
  },
  {
    id: 'themeTextColor',
    label: 'Theme Text Color',
    dataKey: 'themeTextColor',
    type: 'COLOR',
    text: 'Note: This color will be used for website text.',
  },
  {
    id: 'themeHeaderColor',
    label: 'Theme Header Text Color',
    dataKey: 'themeHeaderColor',
    type: 'COLOR',
    text: 'Note: This color will be used for header text.',
  },
  {
    id: 'themePrimaryColor',
    label: 'Theme Primary Color',
    dataKey: 'themePrimaryColor',
    type: 'COLOR',
    text: 'Note: This color will be used as button background, loading indicator color, selected text color and other text highlights.',
  },
  {
    id: 'themePrimaryTextColor',
    label: 'Theme Primary Text Color',
    dataKey: 'themePrimaryTextColor',
    type: 'COLOR',
    text: 'Note: This color will be used as foreground color when background color is set to Theme Primary Color.',
  },
  {
    id: 'themeSecondaryColor',
    label: 'Theme Secondary Color',
    dataKey: 'themeSecondaryColor',
    type: 'COLOR',
    text: 'Note: This color will be used as secondary color for poster backgrounds, button mouse hover, navigation background etc.',
  },
  {
    id: 'themeSecondaryTextColor',
    label: 'Theme Secondary Text Color',
    dataKey: 'themeSecondaryTextColor',
    type: 'COLOR',
    text: 'Note: This color will be used as foreground color when background color is set to Theme Secondary Color.',
  },
  {
    id: 'themeCardPrimaryBackgroundColor',
    label: 'Card Primary Background Color',
    dataKey: 'themeCardPrimaryBackgroundColor',
    type: 'COLOR',
  },
  {
    id: 'themeCardPrimaryTextColor',
    label: 'Card Primary Text Color',
    dataKey: 'themeCardPrimaryTextColor',
    type: 'COLOR',
  },
  {
    id: 'themeCardSecondaryBackgroundColor',
    label: 'Card Secondary Background Color',
    dataKey: 'themeCardSecondaryBackgroundColor',
    type: 'COLOR',
  },
  {
    id: 'themeCardSecondaryTextColor',
    label: 'Card Secondary Text Color',
    dataKey: 'themeCardSecondaryTextColor',
    type: 'COLOR',
  },
  {
    id: 'themeVideoProgressColor',
    label: 'Theme Video Progress Color',
    dataKey: 'themeVideoProgressColor',
    type: 'COLOR',
  },
];

export default MANAGE_COLUMNS;
