import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { getSearchableSecondaryGenresData } from '../../services/genres.service';

const SecondaryGenres = ({
  id,
  name,
  label,
  defaultValue,
  multiple,
  disabled,
  onChange,
  sx,
  error,
  primaryGenreId,
}) => {
  const selectedValue = defaultValue || (multiple ? [] : null);

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(selectedValue);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const params = primaryGenreId ? `?primaryGenreId=${primaryGenreId}` : '';
    getSearchableSecondaryGenresData(params)
      .then((res) => {
        if (res?.data) {
          const data = primaryGenreId
            ? [{ name: '* (All)' }, { name: '/ (None)' }, ...res.data]
            : res.data;
          setOptions(data);
        } else {
          setOptions([]);
        }
        setLoading(false);
      })
      .catch(() => {
        // upon error, show loading only
      });
  }, [primaryGenreId]);

  useEffect(() => {
    if (multiple ? selectedValue?.length > 0 : selectedValue)
      setValue(selectedValue);
  }, [selectedValue]);

  return (
    <Autocomplete
      id={id}
      name={name}
      options={options}
      getOptionLabel={(option) => `${option.name}`}
      sx={sx}
      value={value}
      onChange={(e, v) => {
        setValue(v);
        onChange(v);
      }}
      inputValue={inputValue}
      onInputChange={(e, v) => {
        setInputValue(v);
      }}
      multiple={multiple}
      disabled={disabled}
      loading={loading}
      isOptionEqualToValue={(op, vl) => op.name === vl.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error !== ''}
          helperText={error}
          variant="filled"
        />
      )}
    />
  );
};

SecondaryGenres.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  primaryGenreId: PropTypes.number,
};
SecondaryGenres.defaultProps = {
  multiple: false,
  disabled: false,
  sx: {},
  defaultValue: null,
  error: '',
  primaryGenreId: null,
};

export default SecondaryGenres;
