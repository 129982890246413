import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import { viewDataById } from '../../services/apps.service';
import ROUTE_PATH from '../../config/routes.config';
import AppMetadata from '../../components/content/app-metadata.component';
import AppSubscriptionSettings from '../../components/content/app-subscription-settings.component';
import AppGUMGUMSettings from '../../components/content/app-gumgum-settings.component';
import AppVFRSettings from '../../components/content/app-vfr-settings.component';
import AppBRDSettings from '../../components/content/app-brd-settings.component';
import AppUserOnboardMessaging from '../../components/content/app-user-onboard-msg.component';
import Loading from '../../components/table-elements/loading.component';
import SnackbarInfo from '../../components/common/snackbar-info.component';
import PageNotFoundView from '../errors/not-found.view';
import ConfirmPopup from '../../components/common/confirm-popup.component';
import AssignTags from '../../components/advertisers/assign-tags.component';
import AppBasicInfo from '../../components/content/app-basic-info.component';

const TABS_CONFIG = [
  { value: 'basicInfo', label: 'Basic Info', component: AppBasicInfo },
  {
    value: 'metadata',
    label: 'Metadata',
    component: AppMetadata,
  },
  {
    value: 'subscription',
    label: 'Subscription',
    component: AppSubscriptionSettings,
  },
  { value: 'VFR', label: 'VFR SDK', component: AppVFRSettings },
  {
    value: 'BRD',
    label: 'Bright-SDK',
    component: AppBRDSettings,
  },
  { value: 'GUMGUM', label: 'GumGum', component: AppGUMGUMSettings },
  {
    value: 'userOnboardMsg',
    label: 'User Onboard Messaging',
    component: AppUserOnboardMessaging,
  },
  {
    value: 'platformSpecific',
    label: 'Platform Specific',
    component: AssignTags,
  },
];

const AppEditView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState('basicInfo');
  const [loading, setLoading] = useState(true);
  const [reloadCounter, setReloadCounter] = useState(0);
  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [channelDetail, setChannelDetail] = useState({});
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [hasFormValueChanged, setHasFormValueChanged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const tabValueRef = useRef('');

  const formValueChangedCallback = (val) => {
    setHasFormValueChanged(val);
  };

  const handleRefreshData = () => {
    setReloadCounter(reloadCounter + 1);
  };

  const handleTabSwitchSuccess = () => {
    setTabValue(tabValueRef.current);
    setHasFormValueChanged(false);
    setShowWarning(false);
  };

  useEffect(() => {
    viewDataById(id)
      .then((res) => {
        setChannelDetail(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setShowPageNotFound(true);
          return;
        }
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [id, reloadCounter]);

  return Number.isNaN(Number(id)) || showPageNotFound ? (
    <PageNotFoundView />
  ) : (
    <AppLayout
      pageTitle={`Edit Channel Details - ${channelDetail.title}`}
      showBack
      handleBack={() => navigate(ROUTE_PATH.CONTENT_ALL_CHANNELS)}
    >
      <Box height="100%" display="flex">
        <Tabs
          value={tabValue}
          aria-label="tabs"
          orientation="vertical"
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="transparent"
          sx={{ minWidth: 150, maxWidth: 150 }}
          onChange={(_, v) => {
            if (hasFormValueChanged) {
              tabValueRef.current = v;
              setShowWarning(true);
            } else {
              setTabValue(v);
            }
          }}
        >
          {TABS_CONFIG.map((tab, index) => (
            <Tab
              key={index + 1}
              value={tab.value}
              label={tab.label}
              sx={{
                background: tabValue === tab.value && '#fff',
                borderLeft: tabValue === tab.value && '2px solid #FD0100',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}
            />
          ))}
        </Tabs>

        {loading && <Loading />}

        {!loading && (
          <>
            {TABS_CONFIG.map(({ value, component: Component }) =>
              value === 'platformSpecific'
                ? tabValue === 'platformSpecific' && (
                    <AssignTags channelId={id} fromChannel />
                  )
                : tabValue === value && (
                    <Component
                      key={value}
                      channelDetail={channelDetail}
                      type={tabValue}
                      handleRefreshData={handleRefreshData}
                      formValueChangedCallback={formValueChangedCallback}
                    />
                  )
            )}
          </>
        )}
      </Box>

      {showWarning && (
        <ConfirmPopup
          title="Tab Switch Warning"
          message="Do you want to switch tab without saving data? Before switching tab, save your changes otherwise you lost all your changes and can not undo this action!"
          onCancel={() => setShowWarning(false)}
          onSuccess={() => handleTabSwitchSuccess()}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </AppLayout>
  );
};

export default AppEditView;
