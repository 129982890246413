import React from 'react';
import AppLayout from '../../layout/app.layout';
import MigrationStatus from '../../components/reports/migration-status.component';

const MigrationStatusView = () => {
  const pageTitle = 'Analytics / Migration Status';

  return (
    <AppLayout pageTitle={pageTitle}>
      <MigrationStatus />
    </AppLayout>
  );
};

export default MigrationStatusView;
