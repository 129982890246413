import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import PLATFORMS_LIST from '../../config/platforms.config';

const Platforms = ({
  id,
  name,
  label,
  defaultValue,
  multiple,
  disabled,
  onChange,
  sx,
  error,
}) => {
  const selectedValue = defaultValue || (multiple ? [] : null);
  const options = PLATFORMS_LIST;

  const [value, setValue] = useState(selectedValue);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);

  return (
    <Autocomplete
      id={id}
      name={name}
      options={options}
      getOptionLabel={(option) => option.title}
      sx={sx}
      value={value}
      onChange={(e, v) => {
        setValue(v);
        onChange(v);
      }}
      inputValue={inputValue}
      onInputChange={(e, v) => {
        setInputValue(v);
      }}
      multiple={multiple}
      disabled={disabled}
      isOptionEqualToValue={(op, vl) => op.id === vl.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={error !== ''}
          helperText={error}
          variant="filled"
        />
      )}
    />
  );
};

Platforms.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
};
Platforms.defaultProps = {
  multiple: false,
  disabled: false,
  sx: {},
  defaultValue: null,
  error: '',
};

export default Platforms;
