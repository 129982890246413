import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Stack,
  Paper,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/apps.service';

const AppBRDSettings = ({
  channelDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      delayVastAds: channelDetail.brdDelayAdMinutesWhenOptin.toString() || '',
      showConsentDailogAfterAppOpen:
        channelDetail.brdShowConsentAfterNumberOfAppOpen.toString() || '',
      enableBRDRoku: !!(channelDetail.rokuEnableBrdSdk || 0),
      enableBRDWeb: !!(channelDetail.webEnableBrdSdk || 0),
      enableBRDFireTv: !!(channelDetail.firetvEnableBrdSdk || 0),
      enableBRDFireMobile: !!(channelDetail.fireMobileEnableBrdSdk || 0),
      enableBRDAndroidTv: !!(channelDetail.androidtvEnableBrdSdk || 0),
      enableBRDAndroidMobile: !!(channelDetail.androidMobileEnableBrdSdk || 0),
      enableBRDAppleTv: !!(channelDetail.appletvEnableBrdSdk || 0),
      enableBRDAppleMobile: !!(channelDetail.appleMobileEnableBrdSdk || 0),
      enableBRDSamsungTv: !!(channelDetail.samsungtvEnableBrdSdk || 0),
      enableBRDLgTv: !!(channelDetail.lgtvEnableBrdSdk || 0),
      disableCornerAdsWithOptIn: !!(
        channelDetail.brdDisableCornerAdsWithOptin || 0
      ),
      disableGumgumWithOptIn: !!(channelDetail.disableGumgumWhenBrdOptin || 0),
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(channelDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Bright-SDK settings updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box
      component={Paper}
      sx={{
        padding: 2,
      }}
    >
      <form id="brd-settings-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="delayVastAds"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Delay VAST Ads With Opt-in (in minutes)"
                  type="number"
                  error={!!errors.delayVastAds}
                  helperText={errors?.delayVastAds?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="showConsentDailogAfterAppOpen"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Show Consent Dialog After Number of App Open"
                  type="number"
                  error={!!errors.showConsentDailogAfterAppOpen}
                  helperText={
                    errors?.showConsentDailogAfterAppOpen?.message || null
                  }
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDRoku"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Roku"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDWeb"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Web"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDFireTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Fire TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDFireMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Fire Mobile"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDAndroidTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Android TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDAndroidMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Android Mobile"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDAppleTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Apple TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDAppleMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Apple Mobile"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDSamsungTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD Samsung TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableBRDLgTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable BRD LG TV"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="disableCornerAdsWithOptIn"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Disable Corner Ads with Opt-in"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="disableGumgumWithOptIn"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Disable GUMGUM with Opt-in"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="brd-settings-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Bright-SDK Settings
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

AppBRDSettings.propTypes = {
  channelDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default AppBRDSettings;
