import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ListItemButton,
  ListItemText,
  Collapse,
  List,
  Box,
  Checkbox,
} from '@mui/material';
import styled from '@emotion/styled';
import Iconify from '../common/iconify.component';

const StyledItem = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 36,
  position: 'relative',
  textTransform: 'capitalize',
  color: 'black',
  borderRadius: theme.shape.borderRadius,
}));

const CollapsibleSection = ({
  title,
  id,
  children,
  subSection,
  filters,
  setFilters,
  sx,
  appliedFilter,
}) => {
  const [open, setOpen] = useState(false);
  const filterItem = filters?.[id] || {};
  const checked = filterItem?.apply || false;

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [id]: {
        ...(prevFilters[id] || {}),
        apply: isChecked,
        title,
      },
    }));
  };

  return (
    <Box
      sx={{
        marginX: subSection ? 2 : 0,
        paddingX: subSection ? 1 : 0,
        borderRight: 1,
        borderTop: 1,
        borderLeft: 1,
        borderWidth: '1px',
        borderColor: 'gray',
        ...sx,
      }}
    >
      <StyledItem>
        <Box
          display="flex"
          alignItems="center"
          flexGrow={1}
          onClick={handleToggle}
        >
          {open ? (
            <Iconify icon="ic:round-expand-less" width={25} />
          ) : (
            <Iconify icon="ic:round-expand-more" width={25} />
          )}
          <ListItemText
            disableTypography
            primary={title}
            sx={{ marginLeft: 1 }}
          />
        </Box>

        {subSection && (
          <Checkbox checked={checked} onChange={handleCheckboxChange} />
        )}
        {appliedFilter && (
          <Box
            sx={{
              width: 8,
              height: 8,
              backgroundColor: 'red',
              borderRadius: '50%',
              position: 'absolute',
              right: 10,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          />
        )}
      </StyledItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>{children}</List>
      </Collapse>
    </Box>
  );
};

CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  subSection: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  sx: PropTypes.object,
  appliedFilter: PropTypes.bool,
};

CollapsibleSection.defaultProps = {
  subSection: false,
  sx: {},
  appliedFilter: false,
};

export default CollapsibleSection;
