import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Subscribers module configurations
export const LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    width: '12%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'username',
    label: 'Username',
    width: '12%',
    align: 'left',
    dataKey: 'username',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    width: '16%',
    align: 'left',
    dataKey: 'email',
    sortable: true,
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'channel',
    label: 'Channel',
    width: '15%',
    align: 'left',
    dataKey: 'channel',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'signupTime',
    label: 'Signup Date',
    width: '10%',
    align: 'left',
    dataKey: 'signupTime',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'signupFromPlatform',
    label: 'Signup From',
    width: '10%',
    align: 'center',
    dataKey: 'signupFromPlatform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'subscriptionStatus',
    label: 'Subscription',
    width: '10%',
    align: 'left',
    dataKey: 'subscriptionStatus',
    sortable: true,
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.CREATE_SUBSCRIPTION.value,
    label: ACTIONS.CREATE_SUBSCRIPTION.label,
    conditional: (val) => !val.subscriptionStatus,
  },
  {
    action: ACTIONS.EDIT_SUBSCRIPTION.value,
    label: ACTIONS.EDIT_SUBSCRIPTION.label,
    conditional: (val) => val.subscriptionStatus,
  },
  {
    action: ACTIONS.VIEW_SUBSCRIPTION.value,
    label: ACTIONS.VIEW_SUBSCRIPTION.label,
    conditional: (val) => val.subscriptionStatus,
  },
  {
    action: ACTIONS.REMOVE_SUBSCRIPTION.value,
    label: ACTIONS.REMOVE_SUBSCRIPTION.label,
    conditional: (val) => val.subscriptionStatus,
  },
  {
    action: ACTIONS.SUBSCRIPTIONS_HISTORY.value,
    label: ACTIONS.SUBSCRIPTIONS_HISTORY.label,
  },
  {
    action: ACTIONS.CHANGE_PASSWORD.value,
    label: ACTIONS.CHANGE_PASSWORD.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'email',
    label: 'Email',
    dataKey: 'email',
    type: CELL_DATA_TYPES.EMAIL,
  },
  {
    id: 'username',
    label: 'Username',
    dataKey: 'username',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channel',
    label: 'Channel',
    dataKey: 'channel',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'signupTime',
    label: 'Signup Date',
    dataKey: 'signupTime',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'signupFromPlatform',
    label: 'Signup From',
    dataKey: 'signupFromPlatform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'notes',
    label: 'Notes',
    dataKey: 'notes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriptionStatus',
    label: 'Subscription',
    dataKey: 'subscriptionStatus',
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
  {
    id: 'totalSessions',
    label: 'Total Video Sessions',
    dataKey: 'totalSessions',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'totalSpends',
    label: 'Total Spends',
    dataKey: 'totalSpends',
    type: CELL_DATA_TYPES.MONEY,
  },
];

export const VIEW_SUBSCRIPTIONS_HISTORY_LISTING_COLUMNS = [
  {
    id: 'type',
    label: 'Type',
    width: '10%',
    align: 'left',
    dataKey: 'type',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sku',
    label: 'Product',
    width: '15%',
    align: 'center',
    dataKey: 'sku',
    sortable: false,
    type: CELL_DATA_TYPES.SUBSCRIPTION_SKU,
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '10%',
    align: 'center',
    dataKey: 'platform',
    sortable: false,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'payableAmount',
    label: 'Amount',
    width: '10%',
    align: 'right',
    dataKey: 'payableAmount',
    sortable: false,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'status',
    label: 'Status',
    width: '12%',
    align: 'center',
    dataKey: 'status',
    sortable: false,
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'currentPeriodStartDate',
    label: 'From Date',
    width: '18%',
    align: 'left',
    dataKey: 'currentPeriodStartDate',
    sortable: false,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'currentPeriodEndDate',
    label: 'To Date',
    width: '18%',
    align: 'left',
    dataKey: 'currentPeriodEndDate',
    sortable: false,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
