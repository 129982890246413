import PropTypes from 'prop-types';
import React, { createContext, useState, useMemo } from 'react';

// Create a Context for filters
export const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
  const [openFilter, setOpenFilter] = useState(true);

  const handleToggleFilters = () => {
    setOpenFilter((prev) => !prev);
  };

  const value = useMemo(
    () => ({ openFilter, handleToggleFilters }),
    [openFilter]
  );

  return (
    <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>
  );
};

FiltersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
