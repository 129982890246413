import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListPlaylists from '../../components/content/list-playlists.component';

const ContentPlaylistsView = () => {
  const pageTitle = 'Analytics / Engagement / Views by Playlist';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListPlaylists />
    </AppLayout>
  );
};

export default ContentPlaylistsView;
