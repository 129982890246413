import { VALID_CONTENT_OWNER_ROLES } from './const.config';

// Define admin roles list
const CONTENT_OWNER_LIST = [
  {
    id: VALID_CONTENT_OWNER_ROLES.DISTRIBUTOR,
    title: 'Distributor',
  },
  {
    id: VALID_CONTENT_OWNER_ROLES.FILMMAKER,
    title: 'Filmmaker',
  },
  {
    id: VALID_CONTENT_OWNER_ROLES.AGGREGATOR,
    title: 'Aggregator',
  },
];

export const getContentOwnerRoleLabel = (val) => {
  let label = '';

  CONTENT_OWNER_LIST.forEach((p) => {
    if (label === '' && p.id === val) {
      label = p.title;
    }
  });

  return label;
};

export default CONTENT_OWNER_LIST;
