import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Tab,
  Tabs,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import VideoEditApprovals from './video-edit-approvals.component';
import VideoDescriptionEditApprovals from './video-description-edit-approvals.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const ManageUserEdits = ({ onCancel, title, dataId, byVideo }) => {
  const [tabValue, setTabValue] = useState('videoEdit');

  return (
    <Dialog
      open
      onClose={() => onCancel()}
      aria-labelledby="view-dialog-title"
      aria-describedby="view-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>
      <DialogContent dividers id="view-dialog-description">
        <Box component={Paper}>
          <Box>
            <Tabs
              value={tabValue}
              aria-label="tabs"
              indicatorColor="primary"
              onChange={(_, v) => {
                setTabValue(v);
              }}
            >
              <Tab value="videoEdit" label="Video Edit" />
              <Tab
                value="videoDescriptionEdit"
                label="Video Description Edit"
              />
            </Tabs>
          </Box>
          <Box>
            {tabValue === 'videoEdit' && (
              <VideoEditApprovals dataId={dataId} byVideo={byVideo} />
            )}
            {tabValue === 'videoDescriptionEdit' && (
              <VideoDescriptionEditApprovals
                dataId={dataId}
                byVideo={byVideo}
              />
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ManageUserEdits.propTypes = {
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  byVideo: PropTypes.bool,
};

ManageUserEdits.defaultProps = {
  byVideo: false,
};

export default ManageUserEdits;
