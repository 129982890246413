import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Channel Labels module configurations
export const LISTING_COLUMNS = [
  {
    id: 'label',
    label: 'Label',
    width: '25%',
    align: 'left',
    dataKey: 'label',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'value',
    label: 'Value',
    width: '40%',
    align: 'left',
    dataKey: 'value',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'label',
    label: 'Label',
    dataKey: 'label',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'value',
    label: 'Value',
    dataKey: 'value',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
