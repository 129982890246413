import { ACTIONS } from '../config/const.config';
import useAccess from './access.hook';

const useFilteredActions = (module, actions) => {
  const { hasOperationAccess, hasAdminAccess, hasSuperAdminAccess } =
    useAccess();

  return actions.filter((action) => {
    if (action.action === ACTIONS.VIEW.value) {
      return (
        hasOperationAccess(module, 'view') ||
        hasOperationAccess(module, 'create') ||
        hasOperationAccess(module, 'edit')
      );
    }
    if (action.action === ACTIONS.EDIT.value) {
      return hasOperationAccess(module, 'edit');
    }
    if (action.action === ACTIONS.DELETE.value) {
      return hasOperationAccess(module, 'delete');
    }
    if (action.action === ACTIONS.CREATE_SUBSCRIPTION.value) {
      return hasOperationAccess(module, 'create');
    }
    if (action.action === ACTIONS.CHANGE_PASSWORD.value) {
      return hasOperationAccess(module, 'edit');
    }

    if (
      action.action === ACTIONS.LOGIN_AS_THIS_USER.value ||
      action.action === ACTIONS.ENABLE_LOGIN.value
    ) {
      return hasSuperAdminAccess();
    }

    if (
      action.action === ACTIONS.MANAGE_VIDEOS.value ||
      action.action === ACTIONS.MANAGE_EPISODES.value ||
      action.action === ACTIONS.GENERATE_ROKU_FEED.value ||
      action.action === ACTIONS.ASSIGN_CHANNELS.value ||
      action.action === ACTIONS.MANAGE_CONTENT.value ||
      action.action === ACTIONS.COPY_CHANNEL.value
    ) {
      return hasAdminAccess();
    }
    return true;
  });
};

export default useFilteredActions;
