import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Videos module configurations
export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.ASSIGN_CHANNELS.value,
    label: ACTIONS.ASSIGN_CHANNELS.label,
  },
  {
    action: ACTIONS.MANAGE_EDITS.value,
    label: ACTIONS.MANAGE_EDITS.label,
    conditional: (val) => val.isAnyPendingEdits,
  },
  {
    action: ACTIONS.PLAY_VIDEO.value,
    label: ACTIONS.PLAY_VIDEO.label,
    conditionalDisabled: (val) => !val.video_url,
  },
];

export const VIEW_METADATA_COLUMNS = [
  {
    id: 'uuid',
    label: 'UUID',
    dataKey: 'uuid',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'videoType',
    label: 'Video Type',
    dataKey: 'videoType',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'aka',
    label: 'Aka',
    dataKey: 'aka',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    dataKey: 'duration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'resolution',
    label: 'Resolution',
    dataKey: 'resolution',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'year',
    label: 'Year',
    dataKey: 'year',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'decade',
    label: 'Decade',
    dataKey: 'decade',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'country',
    label: 'Country',
    dataKey: 'country',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'region',
    label: 'Region',
    dataKey: 'region',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'directors',
    label: 'Directors',
    dataKey: 'directors',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'writers',
    label: 'Writers',
    dataKey: 'writers',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'cast',
    label: 'Cast',
    dataKey: 'cast',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbId',
    label: 'IMDB Id',
    dataKey: 'imdbId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'modifiedAt',
    label: 'Modified At',
    dataKey: 'modifiedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIEW_SERIES_COLUMNS = [
  {
    id: 'seriesTitle',
    label: 'Series',
    dataKey: 'seriesTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'season',
    label: 'Season',
    dataKey: 'season',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'episodeNumber',
    label: 'Episode Number',
    dataKey: 'episodeNumber',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'categories',
    label: 'Categories',
    dataKey: 'categories',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'plotKeywords',
    label: 'Plot Keywords',
    dataKey: 'plotKeywords',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const VIEW_RATING_COLUMNS = [
  {
    id: 'trashy',
    label: 'Trashy',
    dataKey: 'trashy',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'goodBad',
    label: 'Good Bad',
    dataKey: 'goodBad',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'pnmExclusive',
    label: 'PNM Exclusive',
    dataKey: 'pnmExclusive',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'ratingAverage',
    label: 'Rating Average',
    dataKey: 'ratingAverage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingCount',
    label: 'Rating Count',
    dataKey: 'ratingCount',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingManipulated',
    label: 'Rating Manipulated',
    dataKey: 'ratingManipulated',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingOverride',
    label: 'Rating Override',
    dataKey: 'ratingOverride',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'fiveStarRating',
    label: 'Five Star Rating',
    dataKey: 'fiveStarRating',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'trashyRating',
    label: 'Trashy Rating',
    dataKey: 'trashyRating',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbRanking',
    label: 'IMDB Ranking',
    dataKey: 'imdbRanking',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'imdbRating',
    label: 'IMDB Rating',
    dataKey: 'imdbRating',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const VIEW_STATUS_COLUMNS = [
  {
    id: 'offline',
    label: 'Offline',
    dataKey: 'offline',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'offlineReason',
    label: 'Offline Reason',
    dataKey: 'offlineReason',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'mpaaRating',
    label: 'MPAA Rating',
    dataKey: 'mpaaRating',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'dateLicenseExpiration',
    label: 'License Expiration Date',
    dataKey: 'dateLicenseExpiration',
    type: CELL_DATA_TYPES.DATE,
  },
  {
    id: 'restrictedContent',
    label: 'Restricted Content',
    dataKey: 'restrictedContent',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'restrictedContentReason',
    label: 'Restricted Content Reason',
    dataKey: 'restrictedContentReason',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'excludeFromDB',
    label: 'Exclude from DB',
    dataKey: 'excludeFromDB',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'excludeFromDBReason',
    label: 'Exclude from DB Reason',
    dataKey: 'excludeFromDBReason',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'adTimingStatus',
    label: 'Ad Timing Status',
    dataKey: 'adTimingStatus',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'genreStatus',
    label: 'Genre Status',
    dataKey: 'genreStatus',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
];

export const VIEW_DISTRIBUTOR_COLUMNS = [
  {
    id: 'avod',
    label: 'AVOD',
    dataKey: 'avod',
    type: CELL_DATA_TYPES.DATE,
  },
  {
    id: 'svod',
    label: 'SVOD',
    dataKey: 'svod',
    type: CELL_DATA_TYPES.DATE,
  },
  {
    id: 'tvod',
    label: 'TVOD',
    dataKey: 'tvod',
    type: CELL_DATA_TYPES.DATE,
  },
  {
    id: 'distributor',
    label: 'Distributor',
    dataKey: 'distributor',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'distributorSku',
    label: 'Distributor SKU',
    dataKey: 'distributorSku',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'distributorSku2',
    label: 'Distributor SKU 2',
    dataKey: 'distributorSku2',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'distributorSkuSeries',
    label: 'Distributor SKU Series',
    dataKey: 'distributorSkuSeries',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'adTiming',
    label: 'Ad Timing',
    dataKey: 'adTiming',
    type: CELL_DATA_TYPES.AD_TIMING,
  },
  {
    id: 'adTimingDetected',
    label: 'Ad Timing Detected',
    dataKey: 'adTimingDetected',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const VIEW_SCHEDULING_COLUMNS = [
  {
    id: 'schedulePriority',
    label: 'Schedule Priority',
    dataKey: 'schedulePriority',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'featuredPlaylist',
    label: 'Featured Playlist',
    dataKey: 'featuredPlaylist',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'geofencingAllow',
    label: 'Geofencing Allow',
    dataKey: 'geofencingAllow',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'geofencingDeny',
    label: 'Geofencing Deny',
    dataKey: 'geofencingDeny',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const VIEW_SYSTEM_ADMIN_COLUMNS = [
  {
    id: 'trailerUrl',
    label: 'Trailer URL',
    dataKey: 'trailerUrl',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'videoUrl',
    label: 'Video URL',
    dataKey: 'videoUrl',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'poster16x9Url',
    label: 'Poster 16x9 URL',
    dataKey: 'poster16x9Url',
    type: CELL_DATA_TYPES.VIEW_IMAGE,
  },
  {
    id: 'poster3x4Url',
    label: 'Poster 3x4 URL',
    dataKey: 'poster3x4Url',
    type: CELL_DATA_TYPES.VIEW_IMAGE,
  },
  {
    id: 'subtitleEnUrl',
    label: 'Subtitle (EN) URL',
    dataKey: 'subtitleEnUrl',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'msNotes',
    label: 'MS Notes',
    dataKey: 'msNotes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'mmNotes',
    label: 'MM Notes',
    dataKey: 'mmNotes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'mmNotes2Trailer',
    label: 'MM Notes 2 Trailer',
    dataKey: 'mmNotes2Trailer',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualityMaxResolution',
    label: 'Max Resolution Quality',
    dataKey: 'qualityMaxResolution',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
];

export const VIEW_IMDB_COLUMNS = [
  {
    id: 'imdbDuration',
    label: 'IMDB Duration',
    dataKey: 'imdbDuration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'imdbYear',
    label: 'IMDB Year',
    dataKey: 'imdbYear',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbAka',
    label: 'IMDB AKA',
    dataKey: 'imdbAka',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbPlotKeywords',
    label: 'IMDB Plot Keywords',
    dataKey: 'imdbPlotKeywords',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbDirectors',
    label: 'IMDB Directors',
    dataKey: 'imdbDirectors',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbWriters',
    label: 'IMDB Writers',
    dataKey: 'imdbWriters',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbCast',
    label: 'IMDB Cast',
    dataKey: 'imdbCast',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbTagline',
    label: 'IMDB Tagline',
    dataKey: 'imdbTagline',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbLanguage',
    label: 'IMDB Language',
    dataKey: 'imdbLanguage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbBudget',
    label: 'IMDB Budget',
    dataKey: 'imdbBudget',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbCountry',
    label: 'IMDB Country',
    dataKey: 'imdbCountry',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'imdbDescription',
    label: 'IMDB Description',
    dataKey: 'imdbDescription',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const VIEW_OTHERS_COLUMNS = [
  {
    id: 'descriptionStatus',
    label: 'Description Status',
    dataKey: 'descriptionStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'restrictedContentSource',
    label: 'Restricted Content Source',
    dataKey: 'restrictedContentSource',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'svodEnabled',
    label: 'SVOD Enabled',
    dataKey: 'svodEnabled',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'svodWatched',
    label: 'SVOD Watched (In Minutes)',
    dataKey: 'svodWatched',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'avodValidated',
    label: 'AVOD License',
    dataKey: 'avodValidated',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'avodWatched',
    label: 'AVOD Watched (In Minutes)',
    dataKey: 'avodWatched',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'avodWatched7',
    label: 'AVOD Weekly Watched (In Minutes)',
    dataKey: 'avodWatched7',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'tvodEnabled',
    label: 'TVOD Enabled',
    dataKey: 'tvodEnabled',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'tvodIap',
    label: 'TVOD Price',
    dataKey: 'tvodIap',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'tagline',
    label: 'Tagline',
    dataKey: 'tagline',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'language',
    label: 'Language',
    dataKey: 'language',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'budget',
    label: 'Budget',
    dataKey: 'budget',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'contractNo',
    label: 'Contract No',
    dataKey: 'contractNo',
    type: CELL_DATA_TYPES.TEXT,
  },
  { id: 'mg', label: 'MG', dataKey: 'mg', type: CELL_DATA_TYPES.TEXT },
  {
    id: 'publicDomain',
    label: 'Public Domain',
    dataKey: 'publicDomain',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'customRoyality',
    label: 'Custom Royalty',
    dataKey: 'customRoyality',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'poster16x9Status',
    label: 'Poster 16x9 Status',
    dataKey: 'poster16x9Status',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'videoStatus',
    label: 'Video Status',
    dataKey: 'videoStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'videoStatusReason',
    label: 'Video Status Reason',
    dataKey: 'videoStatusReason',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'videoStatusReasonDetails',
    label: 'Video Status Reason Details',
    dataKey: 'videoStatusReasonDetails',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'trailerStatus',
    label: 'Trailer Status',
    dataKey: 'trailerStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'trailerStatusReason',
    label: 'Trailer Status Reason',
    dataKey: 'trailerStatusReason',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualityVideo',
    label: 'Quality Video',
    dataKey: 'qualityVideo',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualityPoster3x4',
    label: 'Quality Poster 3x4',
    dataKey: 'qualityPoster3x4',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualitySubtitle',
    label: 'Quality Subtitle',
    dataKey: 'qualitySubtitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualityDescription',
    label: 'Quality Description',
    dataKey: 'qualityDescription',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualityPoster16x9',
    label: 'Quality Poster 16x9',
    dataKey: 'qualityPoster16x9',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualityTrailer',
    label: 'Quality Trailer',
    dataKey: 'qualityTrailer',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualityGenres',
    label: 'Quality Genres',
    dataKey: 'qualityGenres',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualityCategories',
    label: 'Quality Categories',
    dataKey: 'qualityCategories',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'qualitySourceUrl',
    label: 'Quality Source URL',
    dataKey: 'qualitySourceUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'minutesWatchedWeek',
    label: 'Minutes Watched (Week)',
    dataKey: 'minutesWatchedWeek',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'minutesWatchedMonth',
    label: 'Minutes Watched (Month)',
    dataKey: 'minutesWatchedMonth',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'awardWins',
    label: 'Award Wins',
    dataKey: 'awardWins',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'awardNominations',
    label: 'Award Nominations',
    dataKey: 'awardNominations',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'reviewsUser',
    label: 'User Reviews',
    dataKey: 'reviewsUser',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'reviewsCritic',
    label: 'Critic Reviews',
    dataKey: 'reviewsCritic',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'aspectRatio',
    label: 'Aspect Ratio',
    dataKey: 'aspectRatio',
    type: CELL_DATA_TYPES.TEXT,
  },
  { id: 'color', label: 'Color', dataKey: 'color', type: CELL_DATA_TYPES.TEXT },
  {
    id: 'parentalSummarySexNudity',
    label: 'Parental Summary (Sex & Nudity)',
    dataKey: 'parentalSummarySexNudity',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'parentalSummaryViolenceGore',
    label: 'Parental Summary (Violence & Gore)',
    dataKey: 'parentalSummaryViolenceGore',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'parentalSummaryProfanity',
    label: 'Parental Summary (Profanity)',
    dataKey: 'parentalSummaryProfanity',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'parentalSummaryAlcoholDrugsSmoking',
    label: 'Parental Summary (Alcohol, Drugs & Smoking)',
    dataKey: 'parentalSummaryAlcoholDrugsSmoking',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'parentalSummaryFrighteningScenes',
    label: 'Parental Summary (Frightening Scenes)',
    dataKey: 'parentalSummaryFrighteningScenes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'parentalCertification',
    label: 'Parental Certification',
    dataKey: 'parentalCertification',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'rating10',
    label: 'Rating 10',
    dataKey: 'rating10',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating9',
    label: 'Rating 9',
    dataKey: 'rating9',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating8',
    label: 'Rating 8',
    dataKey: 'rating8',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating7',
    label: 'Rating 7',
    dataKey: 'rating7',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating6',
    label: 'Rating 6',
    dataKey: 'rating6',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating5',
    label: 'Rating 5',
    dataKey: 'rating5',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating4',
    label: 'Rating 4',
    dataKey: 'rating4',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating3',
    label: 'Rating 3',
    dataKey: 'rating3',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating2',
    label: 'Rating 2',
    dataKey: 'rating2',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rating1',
    label: 'Rating 1',
    dataKey: 'rating1',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'ratingDemogAllAgeMF',
    label: 'Rating Demographics All Age MF',
    dataKey: 'ratingDemogAllAgeMF',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemogAllAgeM',
    label: 'Rating Demographics All Age Male',
    dataKey: 'ratingDemogAllAgeM',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemogAllAgeF',
    label: 'Rating Demographics All Age Female',
    dataKey: 'ratingDemogAllAgeF',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog18MF',
    label: 'Rating Demographics 18+ MF',
    dataKey: 'ratingDemog18MF',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog18M',
    label: 'Rating Demographics 18+ Male',
    dataKey: 'ratingDemog18M',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog18F',
    label: 'Rating Demographics 18+ Female',
    dataKey: 'ratingDemog18F',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog1829MF',
    label: 'Rating Demographics 18-29 MF',
    dataKey: 'ratingDemog1829MF',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog1829M',
    label: 'Rating Demographics 18-29 Male',
    dataKey: 'ratingDemog1829M',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog1829F',
    label: 'Rating Demographics 18-29 Female',
    dataKey: 'ratingDemog1829F',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog3040MF',
    label: 'Rating Demographics 30-40 MF',
    dataKey: 'ratingDemog3040MF',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog3040M',
    label: 'Rating Demographics 30-40 Male',
    dataKey: 'ratingDemog3040M',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog3040F',
    label: 'Rating Demographics 30-40 Female',
    dataKey: 'ratingDemog3040F',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog45MF',
    label: 'Rating Demographics 45+ MF',
    dataKey: 'ratingDemog45MF',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog45M',
    label: 'Rating Demographics 45+ Male',
    dataKey: 'ratingDemog45M',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemog45F',
    label: 'Rating Demographics 45+ Female',
    dataKey: 'ratingDemog45F',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemogTop1000Votes',
    label: 'Rating Demographics Top 1000 Votes',
    dataKey: 'ratingDemogTop1000Votes',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemogUS',
    label: 'Rating Demographics US',
    dataKey: 'ratingDemogUS',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ratingDemogNonUS',
    label: 'Rating Demographics Non-US',
    dataKey: 'ratingDemogNonUS',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const VIEW_ASSIGNMENTS_COLUMNS = [
  {
    id: 'collections',
    label: 'Collections',
    dataKey: 'collections',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'playlistsName',
    label: 'Playlists',
    dataKey: 'playlistsName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channelsName',
    label: 'Channels',
    dataKey: 'channelsName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'genresName',
    label: 'Genres',
    dataKey: 'genresName',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const DEFAULT_LIST_COLUMNS = [
  {
    id: 'UUID',
    label: 'UUID',
    align: 'left',
    dataKey: 'UUID',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
    editable: false,
  },
  {
    id: 'title',
    label: 'Title',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TOOLTIP_TEXT,
    editable: false,
  },
  {
    id: 'created_at',
    label: 'Created At',
    align: 'right',
    dataKey: 'created_at',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
    editable: false,
  },
];

export const VIEW_TOOLTIP_DATA = [
  {
    id: 'UUID',
    label: 'UUID',
    dataKey: 'UUID',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'date_license_expiration',
    label: 'License Expiration',
    dataKey: 'date_license_expiration',
    type: CELL_DATA_TYPES.DATE,
  },
  {
    id: 'offline',
    label: 'Offline',
    dataKey: 'offline',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'restricted_content',
    label: 'Restricted Content',
    dataKey: 'restricted_content',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'exclude_from_db',
    label: 'Remove from Database',
    dataKey: 'exclude_from_db',
    type: CELL_DATA_TYPES.BOOL,
  },
];

export const DYNAMIC_COLUMNS_CONFIG = {
  UUID: { cellType: CELL_DATA_TYPES.TEXT, editable: false, editCellType: '' },
  video_type: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: false,
    editCellType: '',
  },
  title_name_only: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  aka: { cellType: CELL_DATA_TYPES.TEXT, editable: false, editCellType: '' },
  description: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'textarea',
  },
  duration_minutes: {
    cellType: CELL_DATA_TYPES.DURATION,
    editable: false,
    editCellType: '',
  },
  year: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'number',
  },
  decade: { cellType: CELL_DATA_TYPES.TEXT, editable: false, editCellType: '' },
  country: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  region: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: false,
    editCellType: '',
  },
  directors: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  writers: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  cast: { cellType: CELL_DATA_TYPES.TEXT, editable: false, editCellType: '' },
  imdb_id: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'text',
  },
  resolution: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  series_id: {
    cellType: CELL_DATA_TYPES.NUMBER,
    editable: false,
    editCellType: '',
  },
  title: { cellType: CELL_DATA_TYPES.TEXT, editable: false, editCellType: '' },
  season: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'number',
  },
  episode_num: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'number',
  },
  categories: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  plot_keywords: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  trashy: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  good_bad: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  pnm_exclusives: {
    cellType: CELL_DATA_TYPES.BOOL,
    editable: false,
    editCellType: '',
  },
  rating_average: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_count: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_manipulated: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_override: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  five_star_rating: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'number',
  },
  trashy_rating: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_ranking: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: false,
    editCellType: '',
  },
  imdb_rating: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  offline: {
    cellType: CELL_DATA_TYPES.BOOL,
    editable: true,
    editCellType: 'switch',
  },
  offline_reason: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'textarea',
  },
  mpaa_rating: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: false,
    editCellType: '',
  },
  restricted_content: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  restricted_content_reason: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  exclude_from_db: {
    cellType: CELL_DATA_TYPES.BOOL,
    editable: false,
    editCellType: '',
  },
  exclude_from_db_reason: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: false,
    editCellType: '',
  },
  date_license_expiration: {
    cellType: CELL_DATA_TYPES.DATE,
    editable: true,
    editCellType: 'date',
  },
  ad_timing_status: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  genre_status: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: false,
    editCellType: '',
  },
  avod: {
    cellType: CELL_DATA_TYPES.DATE,
    editable: true,
    editCellType: 'date',
  },
  svod: {
    cellType: CELL_DATA_TYPES.DATE,
    editable: true,
    editCellType: 'date',
  },
  tvod: {
    cellType: CELL_DATA_TYPES.DATE,
    editable: true,
    editCellType: 'date',
  },
  ad_timing: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'timing',
  },
  ad_timing_detected: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'textarea',
  },
  distributor_sku: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'textarea',
  },
  distributor_sku2: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  distributor_sku_series: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  distributor_id: {
    cellType: CELL_DATA_TYPES.NUMBER,
    editable: false,
    editCellType: '',
  },
  schedule_priority: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  featured_playlist: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  geofencing_allow: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'text',
  },
  geofencing_deny: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'text',
  },
  trailer_url: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  video_url: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  poster_16x9_url: {
    cellType: CELL_DATA_TYPES.LIST_IMAGE,
    editable: true,
    editCellType: 'textarea',
  },
  poster_3x4_url: {
    cellType: CELL_DATA_TYPES.LIST_IMAGE,
    editable: false,
    editCellType: '',
  },
  subtitle_en_url: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_max_resolution: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: false,
    editCellType: '',
  },
  ms_notes: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  mm_notes: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  mm_notes2trailer: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  crawl_imdb: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  duplicate_imdb: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_duration_minutes: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_year: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_aka: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_plot_keywords: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_directors: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_writers: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_cast: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_tagline: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_language: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_budget: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_country: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  imdb_description: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  description_status: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  restricted_content_source: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'text',
  },
  svod_enabled: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  svod_watched: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  avod_validated: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  avod_watched: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'number',
  },
  avod_watched_7: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'number',
  },
  tvod_enabled: {
    cellType: CELL_DATA_TYPES.BOOL,
    editable: true,
    editCellType: 'switch',
  },
  tvod_iap: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  tagline: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  language: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  budget: { cellType: CELL_DATA_TYPES.TEXT, editable: false, editCellType: '' },
  contract_no: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  mg: { cellType: CELL_DATA_TYPES.TEXT, editable: false, editCellType: '' },
  public_domain: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  custom_royality: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'number',
  },
  poster_16x9_status: {
    cellType: CELL_DATA_TYPES.PNM_CELL,
    editable: true,
    editCellType: 'selection',
  },
  video_status: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  video_status_reason: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  video_status_reason_details: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  trailer_status: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  trailer_status_reason: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_video: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_poster_3x4: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_subtitle: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_description: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_poster_16x9: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_trailer: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_genres: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_categories: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  quality_source_url: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  minutes_watched_week: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  minutes_watched_month: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  award_wins: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  award_nominations: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  reviews_user: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  reviews_critic: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  aspect_ratio: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  color: { cellType: CELL_DATA_TYPES.TEXT, editable: false, editCellType: '' },
  parental_sum_sex_nudity: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_sum_violence_gore: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_sum_profanity: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_sum_alcohol_drugs_smoking: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_sum_frightening_intense_scenes: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_certification: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_sex_nudity: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_violence_gore: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_profanity: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_alcohol_drugs_smoking: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  parental_frightening_intense_scenes: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_10: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_9: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_8: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_7: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_6: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_5: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_4: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_3: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_2: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_1: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_all_age_mf: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_all_age_m: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_all_age_f: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_18_mf: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_18_m: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_18_f: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_18_29_mf: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_18_29_m: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_18_29_f: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_30_40_mf: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_30_40_m: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_30_40_f: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_45_mf: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_45_m: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_45_f: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_top_1000_votes: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_us: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  rating_demog_non_us: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: false,
    editCellType: '',
  },
  date_creation: {
    cellType: CELL_DATA_TYPES.DATE,
    editable: false,
    editCellType: '',
  },
  status: {
    cellType: CELL_DATA_TYPES.STATUS,
    editable: false,
    editCellType: '',
  },
  created_at: {
    cellType: CELL_DATA_TYPES.TIMESTAMP,
    editable: false,
    editCellType: '',
  },
  modified_at: {
    cellType: CELL_DATA_TYPES.TIMESTAMP,
    editable: false,
    editCellType: '',
  },
  genres: {
    cellType: CELL_DATA_TYPES.TEXT,
    editable: true,
    editCellType: 'selection',
  },
};
