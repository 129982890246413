import PropTypes from 'prop-types';
import {
  AD_TIMING_STATUS,
  COUNTRIES,
  EXCLUDE_FROM_DB_REASON,
  GENRE_STATUS,
  GOOD_BAD,
  IAP_SETTINGS,
  IMDB_RANKING,
  MPAA_RATING,
  POSTER_16X9_STATUS,
  PUBLIC_DOMAIN,
  QUALITY_MAX_RESOLUTION,
  REGIONS,
  RESTRICTED_CONTENT,
  RESTRICTED_CONTENT_REASON,
  TRASHY,
  VIDEO_RESOLUTION,
  VIDEO_TYPES,
} from '../../config/const.config';

const PNMCellLabel = ({ dataKey, value }) => {
  let constObj = '';

  if (dataKey === 'region') {
    const regionName = REGIONS.filter((item) => item.value === value)[0]?.label;
    return regionName;
  }
  if (dataKey === 'country') {
    const countryName = COUNTRIES.filter((item) => item.value === value)[0]
      ?.label;
    return countryName;
  }

  if (dataKey === 'video_type' || dataKey === 'videoType') {
    constObj = VIDEO_TYPES;
  } else if (dataKey === 'resolution') {
    constObj = VIDEO_RESOLUTION;
  } else if (dataKey === 'trashy') {
    constObj = TRASHY;
  } else if (dataKey === 'good_bad' || dataKey === 'goodBad') {
    constObj = GOOD_BAD;
  } else if (dataKey === 'imdb_ranking' || dataKey === 'imdbRanking') {
    constObj = IMDB_RANKING;
  } else if (dataKey === 'mpaa_rating' || dataKey === 'mpaaRating') {
    constObj = MPAA_RATING;
  } else if (
    dataKey === 'restricted_content' ||
    dataKey === 'avod_validated' ||
    dataKey === 'restrictedContent' ||
    dataKey === 'avodValidated'
  ) {
    constObj = RESTRICTED_CONTENT;
  } else if (
    dataKey === 'restricted_content_reason' ||
    dataKey === 'restrictedContentReason'
  ) {
    constObj = RESTRICTED_CONTENT_REASON;
  } else if (
    dataKey === 'exclude_from_db_reason' ||
    dataKey === 'excludeFromDBReason'
  ) {
    constObj = EXCLUDE_FROM_DB_REASON;
  } else if (dataKey === 'ad_timing_status' || dataKey === 'adTimingStatus') {
    constObj = AD_TIMING_STATUS;
  } else if (dataKey === 'genre_status' || dataKey === 'genreStatus') {
    constObj = GENRE_STATUS;
  } else if (
    dataKey === 'quality_max_resolution' ||
    dataKey === 'qualityMaxResolution'
  ) {
    constObj = QUALITY_MAX_RESOLUTION;
  } else if (dataKey === 'public_domain' || dataKey === 'publicDomain') {
    constObj = PUBLIC_DOMAIN;
  } else if (
    dataKey === 'poster_16x9_status' ||
    dataKey === 'poster16x9Status'
  ) {
    constObj = POSTER_16X9_STATUS;
  } else if (
    dataKey === 'rokuIapSetting' ||
    dataKey === 'webIapSetting' ||
    dataKey === 'firetvIapSetting' ||
    dataKey === 'androidIapSetting' ||
    dataKey === 'appleIapSetting' ||
    dataKey === 'samsungTvIapSetting' ||
    dataKey === 'lgTvIapSetting'
  ) {
    constObj = IAP_SETTINGS;
  }

  return constObj[value];
};

PNMCellLabel.propTypes = {
  dataKey: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

export default PNMCellLabel;
