import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Sidebar from '../components/layout/sidebar.component';
import ROUTE_PATH from '../config/routes.config';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 12,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 12,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

// App root layout
const RootLayout = ({ children, isLoggedIn }) => {
  const isValidRoute = Object.values(ROUTE_PATH).some(
    (route) =>
      !window.location.pathname?.includes('popup-view') &&
      matchPath(route, window.location.pathname)
  );
  const isLoginPage = window.location.pathname === ROUTE_PATH.LOGIN;

  const [open, setOpen] = useState(false);

  return (
    <StyledRoot>
      {isLoggedIn && !isLoginPage && isValidRoute && (
        <Sidebar
          openNav={open}
          onOpenNav={() => setOpen(true)}
          onCloseNav={() => setOpen(false)}
        />
      )}

      {isLoggedIn && !isLoginPage && isValidRoute ? (
        <Main>
          <Container
            sx={{
              height: '100%',
            }}
            maxWidth={false}
          >
            {children}
          </Container>
        </Main>
      ) : (
        <Container maxWidth={false} style={{ padding: 0 }}>
          {children}
        </Container>
      )}
    </StyledRoot>
  );
};

RootLayout.propTypes = {
  children: PropTypes.any.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

export default RootLayout;
