import React from 'react';
import ReactDOM from 'react-dom/client';
import AppView from './views/app.view';
import { AccessProvider } from './context/access.context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AccessProvider>
      <AppView />
    </AccessProvider>
  </React.StrictMode>
);
