import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Iconify from '../common/iconify.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  updatePreviewWebChannel,
  getPreviewWebChannel,
} from '../../services/apps.service';
import Channels from '../form-elements/channels.component';
import Loading from '../table-elements/loading.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const PreviewWebApp = ({ title, onCancel }) => {
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      channel: null,
    },
  });

  const [loading, setLoading] = useState(true);

  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    updatePreviewWebChannel({ id: Number(data?.id) || 0 })
      .then(() => {
        const message = data?.title
          ? `${data.title} is assign for Web Preview.`
          : 'Preview Web Channel updated successfully';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message,
        });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  useEffect(() => {
    getPreviewWebChannel()
      .then((res) => {
        setValue(
          'channel',
          res.data
            ? {
                id: res?.data?.channelId,
                title: res?.data?.channel,
                code: res?.data?.channelCode,
              }
            : null
        );

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, []);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="channel"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Channels
                      id="user_channels"
                      name="user_channels"
                      label="Select Channel"
                      defaultValue={value}
                      multiple={false}
                      onChange={(e) => {
                        onChange(e);
                        onFormSubmit(e);
                      }}
                      sx={{ width: '100%' }}
                      error={errors?.channel?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2" color="secondary">
                  Once you select the channel, you will be able to see the
                  content of the channel on{' '}
                  <Link
                    to={`${process.env.REACT_APP_CHANNEL_PREVIEW_ENDPOINT}/?cache=false`}
                    target="_blank"
                  >
                    {process.env.REACT_APP_CHANNEL_PREVIEW_ENDPOINT}
                  </Link>{' '}
                  url.
                </Typography>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

PreviewWebApp.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PreviewWebApp;
