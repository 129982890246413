import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useContext,
} from 'react';
import {
  Card,
  Stack,
  Button,
  Drawer,
  Box,
  Chip,
  Typography,
  Slide,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Tabs,
  Tab,
} from '@mui/material';
import PropTypes from 'prop-types';
import { LISTING_COLUMNS } from '../../config/module-configs/manage-videos.config';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import { getListData } from '../../services/videos.service';
import { ACTIONS } from '../../config/const.config';
import SnackbarInfo from '../common/snackbar-info.component';
import FilterVideos from '../filter-videos/filter-video.component';
import ConfirmPopup from '../common/confirm-popup.component';
import { FiltersContext } from '../../context/filter.context';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const VideoAssignment = ({
  dataId,
  title,
  onCancel,
  onAddData,
  onRemoveData,
  from,
  secondaryGenres,
}) => {
  const columns = LISTING_COLUMNS;
  const defaultValueRef = useRef([]);
  const { handleToggleFilters } = useContext(FiltersContext);
  const [isActionTriggered, setIsActionTriggered] = useState(false);

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {},
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [activeAction, setActiveAction] = useState('view');
  const [selectedSecondaryGenre, setSelectedSecondaryGenre] = useState('none');

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleApplyFilters = (selectedFilters) => {
    const counter =
      Object.values(selectedFilters).filter((filter) => filter.apply).length ||
      0;

    setOptions({
      ...options,
      appliedFilters: { ...selectedFilters },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: [],
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleDeleteFilter = (field) => {
    if (field === 'clear-all') {
      handleClearFilters();
      return;
    }

    const updatedFilters = { ...options.appliedFilters };
    delete updatedFilters[field];

    updatedFilters[field] = {
      ...updatedFilters[field],
      value: Array.isArray(updatedFilters[field]?.value) ? [] : null || '',
    };

    setOptions({
      ...options,
      appliedFilters: updatedFilters,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleGlobalSelection = () => {
    setSelectAllPages(!selectAllPages);
    setSelectedRows([]);
  };

  const handleDeleteData = () => {
    setIsActionTriggered(true);
    setOptions({
      ...options,
      loading: true,
    });

    const filters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));

    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const removePayload = {
      videos: selectedRows,
      isAllSelected: selectAllPages,
      id: selectedSecondaryGenre === 'none' ? dataId : selectedSecondaryGenre,
      filter: filters,
    };

    onRemoveData(removePayload)
      .then(() => {
        setIsActionTriggered(false);
        handleRefreshData();
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `Selected ${
            from === 'seriesEpisodesAssignment' ? 'episodes' : 'videos'
          } removed successfully.`,
        });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setIsActionTriggered(false);
      });
    handleActionCancel();
  };

  const handleAddData = () => {
    setIsActionTriggered(true);
    setOptions({
      ...options,
      loading: true,
    });
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const filters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));

    const addPayload = {
      videos: selectedRows,
      isAllSelected: selectAllPages,
      id: selectedSecondaryGenre === 'none' ? dataId : selectedSecondaryGenre,
      filter: filters,
    };

    onAddData(addPayload)
      .then(() => {
        setIsActionTriggered(false);
        handleRefreshData();

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `Selected  ${
            from === 'seriesEpisodesAssignment' ? 'episodes' : 'videos'
          } added successfully.`,
        });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setIsActionTriggered(false);
      });
    handleActionCancel();
  };

  useEffect(() => {
    setSelectAllPages(false);
    setSelectedRows([]);
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const filters = Object.entries(options.appliedFilters)
      .filter(([, filter]) => filter.apply)
      .map(([field, filter]) => ({
        field,
        ...filter,
      }));

    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }

    const uri = '';
    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(uri + paramsQuery, {
      id: selectedSecondaryGenre === 'none' ? dataId : selectedSecondaryGenre,
      videoIds: defaultValueRef.current,
      from,
      action: activeAction,
      filter: filters,
    })
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [selectedSecondaryGenre, activeAction, options.reloadCounter]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullScreen
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="calc(100% - 300px)"
      >
        <DialogTitle id="add-dialog-title">{title}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          size="small"
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="ic:outline-close" />
        </IconButton>
      </Box>
      <DialogContent dividers id="add-dialog-description">
        <Box
          sx={{
            transition: 'margin 0.3s ease, width 0.3s ease',
            width: '100%',
          }}
        >
          <Box display="flex" width="calc(100% - 300px)">
            <Box flex={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ px: 0, py: 2 }}
              >
                <Box display="flex" gap={2} alignItems="center">
                  <Tabs
                    value={activeAction}
                    aria-label="tabs"
                    indicatorColor="primary"
                    onChange={(_, newValue) => {
                      handlePageChange(0);
                      setActiveAction(newValue);
                    }}
                  >
                    <Tab value="view" label="Existing Assignment" />
                    <Tab value="add" label="Assign More Videos" />
                    <Tab value="remove" label="Remove Videos from Assignment" />
                  </Tabs>
                  {activeAction !== 'view' && (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={
                          !(selectedRows.length || selectAllPages) ||
                          options.loading ||
                          isActionTriggered
                        }
                        startIcon={
                          <Iconify
                            icon={
                              activeAction === 'add'
                                ? 'ic:round-add'
                                : 'ic:round-remove'
                            }
                          />
                        }
                        onClick={
                          activeAction === 'add'
                            ? handleAddData
                            : () => handleActionSelect(ACTIONS.DELETE.value)
                        }
                      >
                        {activeAction === 'add'
                          ? `Add ${
                              from === 'seriesEpisodesAssignment'
                                ? 'Episodes'
                                : 'Videos'
                            }`
                          : `Remove ${
                              from === 'seriesEpisodesAssignment'
                                ? 'Episodes'
                                : 'Videos'
                            }`}
                      </Button>
                      <Stack
                        direction="row"
                        alignItems="center"
                        marginRight={1}
                      >
                        <Checkbox
                          sx={{
                            padding: 1,
                          }}
                          checked={selectAllPages}
                          onChange={handleGlobalSelection}
                          disabled={options.loading || !options.rows.length}
                        />
                        <Typography variant="body2" color="text.secondary">
                          Select All
                        </Typography>
                      </Stack>
                    </>
                  )}

                  {from === 'genreVideoAssignment' && (
                    <FormControl variant="filled" sx={{ minWidth: 170 }}>
                      <InputLabel id="select-secondary-genre">
                        Select Secondary Genre
                      </InputLabel>

                      <Select
                        value={selectedSecondaryGenre}
                        onChange={(e) => {
                          setOptions({
                            ...options,
                            loading: true,
                          });
                          setSelectedSecondaryGenre(e.target.value);
                        }}
                        labelId="select-secondary-genre"
                        label="Select Secondary Genre"
                        fullWidth
                        sx={{ minWidth: 100 }}
                      >
                        <MenuItem value="none">None</MenuItem>
                        {secondaryGenres.map((genre) => (
                          <MenuItem
                            key={Number(genre.id)}
                            value={Number(genre.id)}
                          >
                            {genre.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Box>
                <Box display="flex" gap={2} alignItems="center">
                  <div>
                    <Button
                      color="inherit"
                      variant="contained"
                      startIcon={<Iconify icon="ic:twotone-refresh" />}
                      onClick={handleRefreshData}
                      disabled={options.loading}
                    >
                      Refresh
                    </Button>
                  </div>
                </Box>
              </Stack>

              {options.appliedFilters &&
                Object.values(options.appliedFilters).some(
                  (filter) => filter.apply
                ) && (
                  <Box mb={2}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      display="inline"
                    >
                      Filters Applied:{' '}
                      <Chip
                        key="all"
                        label="Clear all"
                        onDelete={() => handleDeleteFilter('clear-all')}
                        sx={{ ml: 1, color: 'red' }}
                      />
                    </Typography>
                    {Object.entries(options.appliedFilters)
                      .filter(([, filter]) => filter.apply)
                      .map(([field, filter]) => (
                        <Chip
                          key={field}
                          label={filter.title || 'Active'}
                          onDelete={() => handleDeleteFilter(field)}
                          sx={{ ml: 1 }}
                        />
                      ))}
                  </Box>
                )}

              <Card>
                <ListData
                  columns={columns}
                  rows={options.rows}
                  page={options.page}
                  rowsPerPage={options.rowsPerPage}
                  totalRows={options.totalRows}
                  loading={options.loading}
                  actions={[]}
                  error={options.error}
                  sortBy={options.sortBy}
                  sortOrder={options.sortOrder}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  onSortChange={handleSortingChange}
                  onAction={handleActionSelect}
                  from={activeAction !== 'view' && 'video-assignment'}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  selectAllPages={selectAllPages}
                />
              </Card>
            </Box>

            <Drawer
              anchor="right"
              open
              variant="persistent"
              PaperProps={{
                sx: {
                  width: 300,
                  overflow: 'auto',
                },
              }}
            >
              <FilterVideos
                values={options.appliedFilters}
                onCancel={handleToggleFilters}
                onSuccess={handleApplyFilters}
                onClear={handleClearFilters}
                from="video-assignment"
              />
            </Drawer>
          </Box>

          {doAction.action === ACTIONS.DELETE.value && (
            <ConfirmPopup
              title={`Remove Selected  ${
                from === 'seriesEpisodesAssignment' ? 'episodes' : 'videos'
              }`}
              message={`Are you sure you want to remove ${
                selectAllPages ? options.totalRows : selectedRows.length
              } ${
                from === 'seriesEpisodesAssignment' ? 'episodes' : 'videos'
              }? You can not undo this action!`}
              onCancel={handleActionCancel}
              onSuccess={handleDeleteData}
            />
          )}

          {snackbarInfo.show && (
            <SnackbarInfo
              type={snackbarInfo.type}
              message={snackbarInfo.message}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

VideoAssignment.propTypes = {
  dataId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemoveData: PropTypes.func.isRequired,
  onAddData: PropTypes.func.isRequired,
  from: PropTypes.string,
  secondaryGenres: PropTypes.arrayOf(PropTypes.object),
};

VideoAssignment.defaultProps = {
  from: '',
  secondaryGenres: [],
};
export default VideoAssignment;
