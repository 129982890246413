import React, { useEffect, useState } from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Typography,
  Button,
  Checkbox,
  Collapse,
} from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../common/iconify.component';
import Sortable from './sortable.component';
import NoData from './no-data.component';
import Error from './error.component';
import Loading from './loading.component';
import CellValue from './cell-value.component';
import CELL_DATA_TYPES from '../../config/cell-types.config';

const ListData = ({
  columns,
  rows,
  page,
  rowsPerPage,
  totalRows,
  loading,
  actions,
  error,
  sortBy,
  sortOrder,
  onPageChange,
  onRowsPerPageChange,
  onSortChange,
  onAction,
  forReports,
  handleToggleHeaderFooter,
  handleSecondaryGenreClick,
  from,
  selectedRows,
  setSelectedRows,
  selectAllPages,
  subRowColumns,
  handleShowSubRow,
  handleMigratedClick,
}) => {
  const colSpan = columns.length + (actions.length ? 1 : 0);

  const [anchorActionEl, setAnchorActionEl] = useState(null);
  const [openActions, setOpenActions] = useState({
    show: false,
    data: null,
  });
  const [clickedRowData, setClickedRowData] = useState({});

  const handleActionOpen = (e, selectedRow) => {
    setAnchorActionEl(e.currentTarget);
    setOpenActions({
      ...openActions,
      show: true,
      data: selectedRow,
    });
  };
  const handleActionClose = () => {
    setAnchorActionEl(null);
    setOpenActions({
      ...openActions,
      show: false,
      data: null,
    });
  };
  const handleActionSelect = (selectedAction) => {
    onAction(selectedAction, openActions.data);
    handleActionClose();
  };
  const handleSingleActionSelect = (data) => {
    onAction(actions[0].action, data);
    handleActionClose();
  };

  const isPageSelected =
    rows.length > 0 && rows.every((row) => selectedRows.includes(row.id));

  const handleRowSelection = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  const handlePageSelection = () => {
    const pageRowIds = rows.map((row) => row.id);
    if (isPageSelected) {
      setSelectedRows((prevSelected) =>
        prevSelected.filter((id) => !pageRowIds.includes(id))
      );
    } else {
      setSelectedRows((prevSelected) => [
        ...new Set([...prevSelected, ...pageRowIds]),
      ]);
    }
  };

  useEffect(() => {
    if (from === 'video-assignment') {
      if (selectAllPages) {
        setSelectedRows(rows.map((row) => row.id));
      }
    }
  }, [selectAllPages, rows]);

  return (
    <>
      {totalRows > 0 && (
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={(e, newPage) => onPageChange(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(Number(e.target.value));
          }}
        />
      )}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {from === 'video-assignment' &&
                !loading &&
                !error &&
                rows.length !== 0 && (
                  <TableCell align="center" width="2%">
                    <Checkbox
                      checked={isPageSelected}
                      onChange={handlePageSelection}
                    />
                  </TableCell>
                )}

              {actions.length > 0 && (
                <TableCell align="center" width="5%">
                  Action
                </TableCell>
              )}
              {columns.map((c) => (
                <TableCell
                  key={`col-${c.id}`}
                  align={c.align}
                  width={c.width}
                  sx={{
                    cursor: c.sortable ? 'pointer' : 'inherit',
                    padding: forReports ? '5px !important' : '16px',
                    fontSize: forReports
                      ? '0.750rem !important'
                      : '0.875rem !important',
                  }}
                  onClick={() => {
                    if (c.sortable) {
                      if (c.dataKey === sortBy) {
                        if (sortOrder === '') {
                          onSortChange(c.dataKey, 'asc');
                        } else if (sortOrder === 'asc') {
                          onSortChange(c.dataKey, 'desc');
                        } else if (sortOrder === 'desc') {
                          onSortChange('', '');
                        }
                      } else {
                        onSortChange(c.dataKey, 'asc');
                      }
                    }
                  }}
                >
                  <Stack direction="row" justifyContent={c.align}>
                    {c.sortable && (
                      <Sortable
                        dataKey={c.dataKey}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                      />
                    )}
                    {c.label}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading && !error && (
              <>
                {rows.map((r, index) => (
                  <>
                    <TableRow key={`row-${r[columns[0].dataKey]}-${index}`}>
                      {from === 'video-assignment' && !loading && !error && (
                        <TableCell align="center" width="2%">
                          <Checkbox
                            checked={selectedRows.includes(r.id)}
                            onChange={() => {
                              handleRowSelection(r.id);
                            }}
                          />
                        </TableCell>
                      )}

                      {actions.length > 1 && (
                        <TableCell align="center" width="5%">
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={(e) => handleActionOpen(e, r)}
                          >
                            <Iconify icon="eva:more-vertical-fill" />
                          </IconButton>
                        </TableCell>
                      )}

                      {actions.length === 1 && (
                        <TableCell align="right" width="5%">
                          <Button
                            size="small"
                            color="info"
                            variant="contained"
                            onClick={() => handleSingleActionSelect(r)}
                          >
                            {actions[0].label}
                          </Button>
                        </TableCell>
                      )}
                      {columns.map((c) => (
                        <TableCell
                          key={`data-col-${c.id}-${r[c.dataKey]}`}
                          align={c.align}
                          width={c.width}
                          sx={{
                            padding: forReports ? '5px !important' : '16px',
                            fontSize: forReports
                              ? '0.700rem !important'
                              : '0.875rem !important',
                          }}
                          variant={
                            forReports && index === 0 ? 'head' : 'string'
                          }
                        >
                          <CellValue
                            type={c.type}
                            dataKey={c.dataKey}
                            value={r[c.dataKey]}
                            fallbackValue={(e) => {
                              if (
                                (c.dataKey === 'showInHeader' ||
                                  c.dataKey === 'showInFooter') &&
                                c.type === CELL_DATA_TYPES.SWITCH
                              ) {
                                handleToggleHeaderFooter(
                                  r,
                                  e.target.checked,
                                  c.dataKey === 'showInHeader'
                                );
                              } else if (
                                c.dataKey === 'secondaryGenres' &&
                                c.type === CELL_DATA_TYPES.SECONDARY_GENRES
                              ) {
                                handleSecondaryGenreClick({
                                  ...e,
                                  parentId: r.id,
                                });
                              } else if (
                                c.dataKey === 'migrated' &&
                                c.type === CELL_DATA_TYPES.SWITCH
                              ) {
                                handleMigratedClick(r, e.target.checked);
                              }
                            }}
                            expandSubRow={r.subRows?.length > 0}
                            handleShowSubRow={() => handleShowSubRow(r)}
                            isWebsiteLink={
                              c.dataKey === 'websiteUrl' &&
                              c.type === CELL_DATA_TYPES.LINK
                            }
                            rowData={r}
                            clickedRowData={clickedRowData}
                            handleShowTooltip={() => {
                              if (r?.id === clickedRowData?.id) {
                                setClickedRowData({});
                              } else {
                                setClickedRowData(r);
                              }
                            }}
                          />

                          {c.secondaryFields && (
                            <>
                              {c.secondaryFields.map((sf, sfIdx) => (
                                <React.Fragment
                                  key={`sf-data-col-${c.id}-${sfIdx}`}
                                >
                                  <br />
                                  <CellValue
                                    type={sf.type}
                                    dataKey={sf.dataKey}
                                    value={r[sf.dataKey]}
                                    color="secondary"
                                  />
                                </React.Fragment>
                              ))}
                            </>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>

                    {!r?.subRowsLoading &&
                      r?.subRows?.length > 0 &&
                      r.subRows.map((sr) => (
                        <TableRow
                          sx={{
                            backgroundColor: '#ebebeb',
                            boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.2)',
                          }}
                        >
                          <TableCell />
                          {subRowColumns.map((src) => (
                            <TableCell
                              key={`data-col-${sr.id}-${src.id}`}
                              align={src.align}
                              width={src.width}
                              sx={{ paddingY: '2px' }}
                              colSpan={columns.length - 2}
                            >
                              <Collapse in timeout="auto" unmountOnExit>
                                <CellValue
                                  type={src.type}
                                  dataKey={src.dataKey}
                                  value={sr[src.dataKey]}
                                />
                              </Collapse>
                            </TableCell>
                          ))}
                          <TableCell />
                        </TableRow>
                      ))}
                  </>
                ))}
              </>
            )}

            {!loading && !error && totalRows === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                  <NoData />
                </TableCell>
              </TableRow>
            )}

            {!loading && error && totalRows === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                  <Error />
                </TableCell>
              </TableRow>
            )}

            {loading && (
              <TableRow>
                <TableCell align="center" colSpan={colSpan}>
                  <Loading />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalRows > 0 && (
        <TablePagination
          component="div"
          count={totalRows}
          page={page}
          onPageChange={(e, newPage) => onPageChange(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(Number(e.target.value));
          }}
        />
      )}

      {openActions.show && (
        <Menu
          id="actions-menu"
          anchorEl={anchorActionEl}
          open
          onClose={() => handleActionClose()}
          size="small"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {actions.map((a, index) => {
            let render = true;
            if (a.conditional) {
              render = a.conditional(openActions.data);
            }

            if (render) {
              return (
                <MenuItem
                  key={`mia-${a.action}-${index}`}
                  onClick={() => handleActionSelect(a.action)}
                  disabled={
                    a.conditionalDisabled &&
                    a.conditionalDisabled(openActions.data)
                  }
                >
                  <Typography variant="body2">{a.label}</Typography>
                </MenuItem>
              );
            }

            return null;
          })}
        </Menu>
      )}
    </>
  );
};

ListData.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  error: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  forReports: PropTypes.bool,
  handleToggleHeaderFooter: PropTypes.func,
  handleSecondaryGenreClick: PropTypes.func,
  from: PropTypes.string,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  selectAllPages: PropTypes.bool,
  subRowColumns: PropTypes.arrayOf(PropTypes.shape()),
  handleShowSubRow: PropTypes.func,
  handleMigratedClick: PropTypes.func,
};
ListData.defaultProps = {
  onAction: () => {},
  forReports: false,
  handleToggleHeaderFooter: () => {},
  handleSecondaryGenreClick: () => {},
  from: '',
  selectedRows: [],
  setSelectedRows: () => {},
  selectAllPages: false,
  subRowColumns: [],
  handleShowSubRow: () => {},
  handleMigratedClick: () => {},
};

export default ListData;
