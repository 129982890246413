import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Stack,
  Button,
  Drawer,
  Typography,
  Box,
  Chip,
} from '@mui/material';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/app-sessions.config';
import { formatDate } from '../../utils/datetime.util';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ViewAppSession from './view-app-session.component';
import FilterAppSessions from './filter-app-sessions.component';
import { getReportsData } from '../../services/reports.service';
import { FiltersContext } from '../../context/filter.context';

const ListAppSessions = () => {
  const columns = LISTING_COLUMNS;
  const actions = LISTING_ACTIONS;

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      startDate: { name: 'Sessions From Date', value: null },
      endDate: { name: 'Sessions To Date', value: null },
      channels: { name: 'Channels', value: [] },
      platforms: { name: 'Platforms', value: [] },
      ip: { name: 'IP Address', value: '' },
      deviceId: { name: 'Device ID', value: '' },
    },
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const { openFilter, handleToggleFilters } = useContext(FiltersContext);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };

  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.startDate.value) counter += 1;
    if (selectedFilters.endDate.value) counter += 1;
    if (selectedFilters.channels.value.length > 0) counter += 1;
    if (selectedFilters.platforms.value.length > 0) counter += 1;
    if (selectedFilters.ip.value.trim()) counter += 1;
    if (selectedFilters.deviceId.value.trim()) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {
        startDate: { name: 'Sessions From Date', value: null },
        endDate: { name: 'Sessions To Date', value: null },
        channels: { name: 'Channels', value: [] },
        platforms: { name: 'Platforms', value: [] },
        ip: { name: 'IP Address', value: '' },
        deviceId: { name: 'Device ID', value: '' },
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleDeleteFilter = (field) => {
    if (field === 'clear-all') {
      handleClearFilters();
      return;
    }
    const updatedFilters = { ...options.appliedFilters };
    updatedFilters[field] = {
      ...updatedFilters[field],
      value: Array.isArray(updatedFilters[field]?.value) ? [] : null || '',
    };

    setOptions({
      ...options,
      appliedFilters: updatedFilters,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const payload = {
      reportType: 'app_sessions',
      page: options.page + 1,
      perPage: options.rowsPerPage,
    };
    if (options.sortBy && options.sortOrder) {
      payload.sortBy = options.sortBy;
      payload.sortOrder = options.sortOrder;
    }
    const filters = Object.keys(options.appliedFilters).reduce((acc, key) => {
      acc[key] = options.appliedFilters[key].value;
      return acc;
    }, {});
    if (filters.startDate) {
      const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
      payload.startDate = startDate;
    }
    if (filters.endDate) {
      const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
      payload.endDate = endDate;
    }
    if (filters.channels.length > 0) {
      const channels = [];
      filters.channels.forEach((c) => channels.push(c.id));
      payload.channels = channels;
    }
    if (filters.platforms.length > 0) {
      const platforms = [];
      filters.platforms.forEach((p) => platforms.push(p.id));
      payload.platforms = platforms;
    }
    if (filters.ip) {
      payload.ip = [filters.ip];
    }
    if (filters.deviceId) {
      payload.devices = [filters.deviceId];
    }

    getReportsData(payload)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: openFilter ? 'calc(100% - 300px)' : '100%',
      }}
    >
      <Stack direction="row" justifyContent="right" sx={{ px: 0, py: 2 }}>
        <Button
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="ic:twotone-refresh" />}
          onClick={handleRefreshData}
          disabled={options.loading}
        >
          Refresh
        </Button>
      </Stack>

      {options.appliedFilters &&
        Object.values(options.appliedFilters).some(
          (filter) =>
            (Array.isArray(filter.value) && filter.value.length > 0) ||
            (!Array.isArray(filter.value) && filter.value)
        ) && (
          <Box mb={2}>
            <Typography variant="body1" fontWeight="bold" display="inline">
              Filters Applied:{' '}
              <Chip
                key="all"
                label="Clear all"
                onDelete={() => handleDeleteFilter('clear-all')}
                sx={{ ml: 1, color: 'red' }}
              />
            </Typography>
            {Object.entries(options.appliedFilters)
              .filter(
                ([, filter]) =>
                  (Array.isArray(filter.value) && filter.value.length > 0) ||
                  (!Array.isArray(filter.value) && filter.value)
              )
              .map(([field, filter]) => (
                <Chip
                  key={field}
                  label={filter.name || 'Active'}
                  onDelete={() => handleDeleteFilter(field)}
                  sx={{ ml: 1 }}
                />
              ))}
          </Box>
        )}

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilter}
        variant="persistent"
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterAppSessions
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewAppSession
          title="App Session Details"
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}
    </Box>
  );
};

export default ListAppSessions;
