import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';

const NumberBetweenInput = ({ id, name, filters, setFilters }) => {
  const filterObject = filters[id] || {
    value: ['', ''],
    condition: 'BETWEEN',
    type: 'inputNumber',
    apply: false,
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <TextField
          id={`from${id}`}
          name={`from${name}`}
          type="number"
          label="From"
          variant="filled"
          value={filterObject.value?.[0]}
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              [id]: {
                ...(prev[id] || {}),
                value: [e.target.value, prev[id]?.value?.[1] || ''],
                condition: prev[id]?.condition || 'BETWEEN',
                type: 'inputNumber',
                apply: prev[id]?.apply || false,
              },
            }))
          }
          sx={{ width: '45%', my: 1 }}
        />
        <Typography>-</Typography>
        <TextField
          id={`to${id}`}
          name={`to${name}`}
          type="number"
          label="To"
          variant="filled"
          value={filterObject.value?.[1]}
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              [id]: {
                ...(prev[id] || {}),
                value: [prev[id]?.value?.[0] || '', e.target.value],
                condition: prev[id]?.condition || 'BETWEEN',
                type: 'inputNumber',
                apply: prev[id]?.apply || false,
              },
            }))
          }
          sx={{ width: '45%', my: 1 }}
        />
      </Box>

      <RadioGroup
        style={{ flexDirection: 'row' }}
        aria-label={`${name}ConditionType`}
        name={`${name}ConditionType`}
        value={filterObject.condition || 'BETWEEN'}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            [id]: {
              ...(prev[id] || {}),
              condition: e.target.value,
              type: 'inputNumber',
              value: prev[id]?.value || ['', ''],
              apply: prev[id]?.apply || false,
            },
          }))
        }
      >
        <FormControlLabel value="BETWEEN" control={<Radio />} label="Between" />
        <FormControlLabel value="OUTSIDE" control={<Radio />} label="Outside" />
      </RadioGroup>
    </>
  );
};

NumberBetweenInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default NumberBetweenInput;
