import React from 'react';
import PropTypes from 'prop-types';
import {
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

const MultiValueInputCondition = ({ id, name, filters, setFilters }) => {
  const filterObject = filters[id]
    ? { ...filters[id], value: filters[id].value || [] }
    : {
        value: [],
        condition: 'CONTAINS',
        type: 'multiValueText',
        apply: false,
      };

  const handleAddValue = (e) => {
    if (e.key === 'Enter' && e.target.value.trim() !== '') {
      e.preventDefault();

      setFilters((prev) => ({
        ...prev,
        [id]: {
          ...filterObject,
          type: 'multiValueText',
          value: [...new Set([...filterObject.value, e.target.value.trim()])],
        },
      }));

      e.target.value = '';
    }
  };

  const handleRemoveValue = (val) => {
    setFilters((prev) => ({
      ...prev,
      [id]: {
        ...filterObject,
        value: filterObject.value.filter((item) => item !== val),
      },
    }));
  };

  return (
    <>
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', mb: 1 }}>
        {filterObject.value?.length > 0 ? (
          filterObject.value.map((val) => (
            <Chip
              key={val}
              label={val}
              onDelete={() => handleRemoveValue(val)}
              sx={{ cursor: 'pointer' }}
            />
          ))
        ) : (
          <Typography variant="body2" color="error">
            No added values to filter
          </Typography>
        )}
      </Stack>

      <TextField
        id={id}
        name={name}
        label="Enter value and press Enter"
        variant="filled"
        onKeyDown={handleAddValue}
        sx={{ width: '100%', my: 1 }}
      />

      <RadioGroup
        aria-label={`${name}ConditionType`}
        name={`${name}ConditionType`}
        value={filterObject.condition || 'CONTAINS'}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            [id]: {
              ...filterObject,
              condition: e.target.value,
            },
          }))
        }
      >
        <FormControlLabel
          value="CONTAINS"
          control={<Radio />}
          label="Contains"
        />
        <FormControlLabel
          value="DOES_NOT_CONTAIN"
          control={<Radio />}
          label="Does not contain"
        />
        <FormControlLabel
          value="OR"
          control={<Radio />}
          label="OR (one or more)"
        />
      </RadioGroup>
    </>
  );
};

MultiValueInputCondition.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default MultiValueInputCondition;
