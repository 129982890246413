import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  TextField,
  Grid,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import {
  formatDateToUtc,
  formatTimestampToUtc,
} from '../../utils/datetime.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Subscribers from '../form-elements/subscribers.component';
import SubscriptionTypes from '../form-elements/subscription-types.component';
import Channels from '../form-elements/channels.component';
import Videos from '../form-elements/videos.component';
import Datetimepicker from '../form-elements/datetimepicker.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/subscriptions.service';
import StyledDialogActions from '../../theme/dialogActionStyles';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditSubscription = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  selectedSubscriberData,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      channel: null,
      subscriber: null,
      type: null,
      content: null,
      startTime: null,
      endTime: null,
      notes: '',
    },
  });
  const channelFieldValue = watch('channel');
  const typeFieldValue = watch('type');

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New subscription created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('Subscription details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      subscriberId: Number(data.subscriber.id),
      type: data.type.id,
      contentId: data.content?.cmsId || '',
      startTime: formatDateToUtc(data.startTime),
      endTime: formatDateToUtc(data.endTime),
      notes: data.notes || '',
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      if (Object.keys(selectedSubscriberData).length) {
        setValue('subscriber', {
          id: selectedSubscriberData.id || null,
          name: selectedSubscriberData.name || '',
          username: selectedSubscriberData.username || '',
        });
        setValue('channel', {
          id: selectedSubscriberData.channelId || null,
          code: selectedSubscriberData.channelCode || '',
          title: selectedSubscriberData.channelName || '',
        });
      }
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        setValue('subscriber', {
          id: res.data?.subscriberId || null,
          name: res.data?.name || '',
          username: res.data?.username || '',
        });
        setValue('channel', {
          id: res?.data?.channelId || null,
          code: res?.data?.channelCode || '',
          title: res?.data?.channelTitle || '',
        });
        setValue('type', {
          id: res.data?.type || '',
          title: res.data?.type || '',
        });
        if (res.data?.type === 'TVOD') {
          setValue('content', {
            id: res.data?.videoId || null,
            title: res.data?.videoTitle || '',
            cmsId: res.data?.videoCmsId || '',
          });
        }
        if (res.data?.startDate) {
          setValue('startTime', formatTimestampToUtc(res.data.startDate));
        }
        if (res.data?.endDate) {
          setValue('endTime', formatTimestampToUtc(res.data.endDate));
        }
        setValue('notes', res.data?.notes || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="channel"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Channels
                      id="subscription_channel"
                      name="subscription_channel"
                      label="Channel *"
                      defaultValue={value}
                      multiple={false}
                      onChange={onChange}
                      disabled={
                        dataId !== 0 ||
                        Object.keys(selectedSubscriberData).length
                      }
                      sx={{ width: '100%' }}
                      error={errors?.channel?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="subscriber"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Subscribers
                      id="subscription_subscriber"
                      name="subscription_subscriber"
                      label="User *"
                      defaultValue={value}
                      multiple={false}
                      disabled={
                        !channelFieldValue?.id ||
                        dataId !== 0 ||
                        Object.keys(selectedSubscriberData).length
                      }
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.subscriber?.message || ''}
                      filterByChannel={channelFieldValue?.id || 0}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="type"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <SubscriptionTypes
                      id="subscription_type"
                      name="subscription_type"
                      label="Type *"
                      defaultValue={value}
                      multiple={false}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.type?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="content"
                  control={control}
                  rules={{
                    validate: (value) =>
                      (typeFieldValue?.id || '') === 'TVOD' && !value
                        ? 'Please select the video to provide access to.'
                        : true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Videos
                      id="subscription_content"
                      name="subscription_content"
                      label={`Video${
                        typeFieldValue?.id === 'TVOD' ? ' *' : ''
                      }`}
                      defaultValue={value}
                      multiple={false}
                      disabled={(typeFieldValue?.id || '') !== 'TVOD'}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.content?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="startTime"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="subscription_start"
                      name="subscription_start"
                      label="Access Start *"
                      defaultValue={value}
                      clearable
                      onChange={onChange}
                      error={errors?.startTime?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="endTime"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="subscription_end"
                      name="subscription_end"
                      label="Access End *"
                      defaultValue={value}
                      clearable
                      onChange={onChange}
                      error={errors?.endTime?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="notes"
                  control={control}
                  rules={{ ...maxLengthValidation(200) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes"
                      type="text"
                      error={!!errors.notes}
                      helperText={errors?.notes?.message || null}
                      fullWidth
                      variant="filled"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditSubscription.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  selectedSubscriberData: PropTypes.object,
};
AddEditSubscription.defaultProps = {
  selectedSubscriberData: {},
};

export default AddEditSubscription;
