import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/apps.service';

const AppUserOnboardMessaging = ({
  channelDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      landingPage: channelDetail.landingPageHintMessage || '',
      loginSignupPage: channelDetail.authPageHintMessage || '',
      paidUserNotLoggedIn: channelDetail.paidUserNotLoggedinHintMsg || '',
      paidUserLoggedIn: channelDetail.paidUserLoggedinHintMsg || '',
      freeUserNotLoggedIn: channelDetail.freeUserNotLoggedinHintMsg || '',
      freeUserLoggedIn: channelDetail.freeUserLoggedinHintMsg || '',
      freeUserSubscription: channelDetail.freeUserSubscriptionHintMsg || '',
      onHoldSubscription: channelDetail.onHoldSubscriptionHintMsg || '',
      onGraceSubscription: channelDetail.onGraceSubscriptionHintMsg || '',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(channelDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'User onboard messages updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box
      component={Paper}
      sx={{
        padding: 2,
      }}
    >
      <form
        id="user-onboard-messages-form"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="landingPage"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Landing Page"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.landingPage}
                  helperText={errors?.landingPage?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="loginSignupPage"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Login/Signup Page"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.loginSignupPage}
                  helperText={errors?.loginSignupPage?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="paidUserNotLoggedIn"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Paid User Not Logged In"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.paidUserNotLoggedIn}
                  helperText={errors?.paidUserNotLoggedIn?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="paidUserLoggedIn"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Paid User Logged In"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.paidUserLoggedIn}
                  helperText={errors?.paidUserLoggedIn?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="freeUserNotLoggedIn"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Free User Not Logged In"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.freeUserNotLoggedIn}
                  helperText={errors?.freeUserNotLoggedIn?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="freeUserLoggedIn"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Free User Logged In"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.freeUserLoggedIn}
                  helperText={errors?.freeUserLoggedIn?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="freeUserSubscription"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Free User Subscription"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.freeUserSubscription}
                  helperText={errors?.freeUserSubscription?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="onHoldSubscription"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="On Hold Subscription"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.onHoldSubscription}
                  helperText={errors?.onHoldSubscription?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="onGraceSubscription"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="On Grace Subscription"
                  type="text"
                  multiline
                  minRows={7}
                  maxRows={7}
                  error={!!errors.onGraceSubscription}
                  helperText={errors?.onGraceSubscription?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="user-onboard-messages-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save User Onboard Msg
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

AppUserOnboardMessaging.propTypes = {
  channelDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default AppUserOnboardMessaging;
