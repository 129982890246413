import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Stack,
  Button,
  Divider,
  Typography,
  TextField,
  alpha,
} from '@mui/material';
import { STATUS_ALL_VALUE } from '../../config/const.config';
import Iconify from '../common/iconify.component';
import Scrollbar from '../common/scrollbar.component';
import Channels from '../form-elements/channels.component';
import Platforms from '../form-elements/platforms.component';
import Status from '../form-elements/status.component';
import Datepicker from '../form-elements/datepicker.component';
import useResponsive from '../../hooks/responsive.hook';

const FilterSubscribers = ({ values, onCancel, onSuccess, onClear }) => {
  const isDesktop = useResponsive('up', 'lg');
  const [filters, setFilters] = useState(values);

  const handleApplyFilters = () => {
    setTimeout(() => {
      onSuccess(filters);
    }, 250);
  };

  useEffect(() => {
    setFilters({ ...values });
  }, [values]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ px: 1, py: 2, minHeight: isDesktop ? '92px' : 76 }}
      >
        <Typography variant="subtitle1" sx={{ ml: 1, textAlign: 'center' }}>
          Filters
        </Typography>
        <IconButton
          color="inherit"
          variant="contained"
          sx={{
            marginLeft: 2,
            borderRadius: '100%',
            width: 'auto',
            padding: '8px',
            bgcolor: (theme) => alpha(theme.palette.grey[400], 1),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[400], 1),
              boxShadow: 'none',
            },
          }}
          onClick={onCancel}
        >
          <Iconify icon="mdi:menu-close" />
        </IconButton>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <form id="list-data-filters" onSubmit={(e) => e.preventDefault()}>
          <Stack sx={{ p: 2 }}>
            <TextField
              id="fl_search"
              name="fl_search"
              label="Search"
              variant="filled"
              value={filters.search?.value}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  search: { ...filters.search, value: e.target.value },
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <Stack spacing={1} sx={{ marginBottom: 1 }}>
              <Datepicker
                id="fl_start_date"
                name="fl_start_date"
                label="Sign Up From Date"
                defaultValue={filters.startDate?.value}
                clearable
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    startDate: { ...filters.startDate, value: v },
                  });
                }}
                sx={{ width: '100%' }}
              />

              <Datepicker
                id="fl_end_date"
                name="fl_end_date"
                label="Sign Up To Date"
                defaultValue={filters.endDate?.value}
                clearable
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    endDate: { ...filters.endDate, value: v },
                  });
                }}
                sx={{ width: '100%' }}
              />
            </Stack>

            <Channels
              id="fl_channels"
              name="fl_channels"
              label="Channels"
              defaultValue={filters.channels?.value}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  channels: { ...filters.channels, value: v },
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <Platforms
              id="fl_platforms"
              name="fl_platforms"
              label="Signup From"
              defaultValue={filters.platforms?.value}
              multiple
              onChange={(v) => {
                setFilters({
                  ...filters,
                  platforms: { ...filters.platforms, value: v },
                });
              }}
              sx={{ width: '100%', my: 1 }}
            />

            <Status
              id="fl_status"
              name="fl_status"
              label="Status"
              defaultValue={
                filters.status?.value === null || filters.status?.value === ''
                  ? STATUS_ALL_VALUE
                  : filters.status?.value
              }
              multiple={false}
              onChange={(e) => {
                setFilters({
                  ...filters,
                  status: {
                    ...filters.status,
                    value:
                      e.target.value === STATUS_ALL_VALUE
                        ? null
                        : e.target.value,
                  },
                });
              }}
              validOptions={[0, 1, 5]}
              sx={{ width: '100%', my: 1 }}
              allOption
            />
          </Stack>
        </form>
      </Scrollbar>

      <Divider />

      <Stack spacing={1} sx={{ p: 2 }}>
        <Button
          fullWidth
          form="list-data-filters"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          fullWidth
          type="button"
          color="secondary"
          variant="contained"
          startIcon={<Iconify icon="ic:round-clear-all" />}
          onClick={onClear}
        >
          Clear All
        </Button>
      </Stack>
    </>
  );
};

FilterSubscribers.propTypes = {
  values: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default FilterSubscribers;
