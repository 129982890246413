import axios from 'axios';
import prepareRequestOptions, {
  prepareS3UploadRequestOptions,
} from '../utils/admin-apis.util';

export const getListData = async (params = '', filters = {}) => {
  const options = prepareRequestOptions(
    `/admin/content/videos/list${params}`,
    'POST',
    filters,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/video/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/content/video/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/content/video/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getVideoDescriptionList = async (params = '', filters = {}) => {
  const options = prepareRequestOptions(
    `/admin/content/video/descriptions/list${params}`,
    'POST',
    filters,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateVideoDescription = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/video/descriptions/update',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const bulkUpdateDataByIds = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/videos/bulk-update',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getChannelVideoScheduleList = async (
  params = '',
  filters = {}
) => {
  const options = prepareRequestOptions(
    `/admin/content/video/schedule/list${params}`,
    'POST',
    filters,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const channelVideoSchedule = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/content/video/schedule/manage`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateVideoSchedule = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/video/schedule/update',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateLicenseExpirationDate = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/videos/update-license-expiration',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const inlineUpdateDataByIds = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/videos/inline-update',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const assignChannelsToVideo = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/content/video/${id}/assign/channels`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSignedUrl = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/generate-signed-url',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const uploadFileToS3 = async (payload, url, onUploadProgress = null) => {
  const options = prepareS3UploadRequestOptions(
    url,
    'PUT',
    payload,
    false,
    onUploadProgress
  );

  const response = await axios(options);
  return response.data;
};
