import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (id, params = '') => {
  const options = prepareRequestOptions(
    `/admin/channel/${id}/faqs/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/channel/faqs/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/channel/faqs/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/channel/faqs/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/channel/faqs/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
