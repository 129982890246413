import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { STATUS_COLORS, BRD_STATUS_LABELS } from '../../config/const.config';

const BrdStatusLabel = ({ value }) => {
  const label = BRD_STATUS_LABELS[value] || 'Unknown';
  const color = STATUS_COLORS[value] || 'primary';

  return (
    <Chip
      label={label}
      color={color}
      variant="filled"
      size="small"
      sx={{ fontWeight: 700 }}
    />
  );
};

BrdStatusLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default BrdStatusLabel;
