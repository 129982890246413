import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import VideoFilterCondition from '../form-elements/video-filter-condition.component';
import { FILTER_CONDITION_TYPE } from '../../config/const.config';

const FilterInputCondition = ({ id, name, filters, setFilters }) => {
  const filter = filters[id] || { value: '', condition: 'CONTAINS' };

  return (
    <>
      <TextField
        id={id}
        name={name}
        label="Search"
        variant="filled"
        value={filter.value}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            [id]: {
              ...prev[id],
              value: e.target.value,
              type: 'inputText',
              condition: prev[id]?.condition || 'CONTAINS',
              apply: prev[id]?.apply || false,
            },
          }))
        }
        sx={{ width: '100%', my: 1 }}
      />
      <VideoFilterCondition
        id={`${id}ConditionType`}
        name={`${name}ConditionType`}
        label="Condition"
        defaultValue={filter.condition || 'CONTAINS'}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            [id]: {
              ...prev[id],
              condition: e.target.value,
              type: 'inputText',
              value: prev[id]?.value || '',
              apply: prev[id]?.apply || false,
            },
          }))
        }
        validOptions={Object.keys(FILTER_CONDITION_TYPE)}
        sx={{ width: '100%', my: 1 }}
      />
    </>
  );
};

FilterInputCondition.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default FilterInputCondition;
