import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Grid,
  TextField,
  Checkbox,
  Typography,
  Tooltip,
} from '@mui/material';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/labels.service';
import StyledDialogActions from '../../theme/dialogActionStyles';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import ConfirmPopup from '../common/confirm-popup.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditContentAppLabel = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  channelId,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      labels: [
        {
          label: '',
          value: '',
          createForAllChannel: false,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'labels',
  });

  const [showConfirmDailog, setShowConfirmDailog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New labels data created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('Label details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const handleSaveData = () => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const data = getValues();
    if (dataId) {
      const payload = {
        label: data.labels[0]?.label,
        value: data.labels[0]?.value,
        createForAllChannel: data.labels[0]?.createForAllChannel || false,
      };
      updateExistingData(payload);
    } else {
      const payload = {
        channelId,
        labels: data.labels,
      };
      saveNewData(payload);
    }
  };

  const onFormSubmit = (data) => {
    if (data.labels?.some?.((item) => item.createForAllChannel)) {
      setShowConfirmDailog(true);
    } else {
      handleSaveData();
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        setValue('labels.0.label', res.data.label);
        setValue('labels.0.value', res.data.value);
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId, reset]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="add-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              {fields.length > 0 && (
                <>
                  <Grid item xs={10} />
                  {!!channelId && (
                    <Grid item xs={1.5}>
                      <Typography
                        variant="subtitle1"
                        sx={{ textAlign: 'center' }}
                      >
                        {dataId ? 'Update' : 'Create'} for all channels
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={0.5} />
                </>
              )}

              {fields.map((field1, index) => (
                <React.Fragment key={field1.id}>
                  <Grid item xs={4}>
                    <Controller
                      name={`labels.${index}.label`}
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...validationRules.NO_SPACES,
                        ...maxLengthValidation(100),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled={dataId}
                          label="Label *"
                          type="text"
                          fullWidth
                          variant="filled"
                          error={!!errors?.labels?.[index]?.label}
                          helperText={
                            errors?.labels?.[index]?.label?.message || null
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={channelId ? 6 : 7.5}>
                    <Controller
                      name={`labels.${index}.value`}
                      control={control}
                      rules={{
                        ...validationRules.REQUIRED,
                        ...maxLengthValidation(255),
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="Value *"
                          type="text"
                          fullWidth
                          variant="filled"
                          error={!!errors?.labels?.[index]?.value}
                          helperText={
                            errors?.labels?.[index]?.value?.message || null
                          }
                        />
                      )}
                    />
                  </Grid>
                  {!!channelId && (
                    <Grid
                      item
                      xs={1.5}
                      sm={1.5}
                      md={1.5}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Controller
                        name={`labels.${index}.createForAllChannel`}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Tooltip
                            title={`${
                              dataId ? 'update' : 'create'
                            } for all channels`}
                            arrow
                          >
                            <Checkbox
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              onChange={onChange}
                              checked={value || false}
                              inputProps={{
                                'aria-label': `Create for all channels for ${field1.label}`,
                              }}
                            />
                          </Tooltip>
                        )}
                      />
                    </Grid>
                  )}
                  {!dataId && fields.length > 1 && (
                    <Grid
                      item
                      xs={0.5}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <IconButton
                        aria-label="close"
                        onClick={() => remove(index)}
                        size="small"
                      >
                        <Iconify icon="material-symbols:delete-outline" />
                      </IconButton>
                    </Grid>
                  )}
                </React.Fragment>
              ))}

              {!dataId && (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      append({
                        label: '',
                        value: '',
                        createForAllChannel: false,
                      })
                    }
                  >
                    Add Label
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </DialogContent>

      <StyledDialogActions>
        <Grid>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 2 }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
          >
            Cancel
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2" color="secondary">
            * indicates a required field
          </Typography>
        </Grid>
      </StyledDialogActions>

      {showConfirmDailog && (
        <ConfirmPopup
          title={`${dataId ? 'Update' : 'Create'} for all channel confirmation`}
          message={`You have selected '${
            dataId ? 'update' : 'create'
          } for all channel'. Do you want to ${
            dataId ? 'update' : 'create'
          } labels for all channels? You can not undo this action!`}
          onCancel={() => setShowConfirmDailog(false)}
          onSuccess={handleSaveData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditContentAppLabel.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  channelId: PropTypes.number.isRequired,
};

export default AddEditContentAppLabel;
