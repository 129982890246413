import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/content/channels/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/channels/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/content/channels/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/content/channels/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getChannelAssetData = async (id) => {
  const options = prepareRequestOptions(
    `/admin/channel/${id}/assets`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateChannelAssetData = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/channel/${id}/assets`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const generateRokuFeed = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/channel/${id}/generate-roku-feed`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const addVideosById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/content/channels/${id}/add-video`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
export const removeVideosById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/content/channels/${id}/remove-video`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/content/channels/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const toggleMigratedStatus = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/content/channels/migrated-status/toggle`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updatePreviewWebChannel = async (payload) => {
  const options = prepareRequestOptions(
    `/admin/content/channels/preview-channel`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getPreviewWebChannel = async () => {
  const options = prepareRequestOptions(
    `/admin/content/channels/preview-channel`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
