import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
  Paper,
} from '@mui/material';
import { accessModule, moduleAccessConfig } from '../../config/const.config';

const ModuleAccess = ({ defaultValue, onChange }) => {
  const [modules, setModules] = useState({});

  useEffect(() => {
    const initialModules = Object.keys(accessModule).reduce(
      (acc, moduleName) => {
        acc[moduleName] = [];
        return acc;
      },
      {}
    );

    if (defaultValue) {
      setModules({ ...initialModules, ...defaultValue });
    } else {
      setModules(initialModules);
    }
  }, [defaultValue]);

  const handleCheckboxChange = (moduleName, accessType) => (event) => {
    let updatedModules = {
      ...modules,
      [moduleName]: event.target.checked
        ? [...modules[moduleName], accessType]
        : modules[moduleName].filter((type) => type !== accessType),
    };

    if (moduleName === 'license_management') {
      const videoLibraryView = updatedModules.video_library || [];
      if (!videoLibraryView.includes('view')) {
        updatedModules = {
          ...updatedModules,
          video_library: [...videoLibraryView, 'view'],
        };
      }
    }

    setModules(updatedModules);
    onChange(updatedModules);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <Typography variant="h6" gutterBottom>
        Manage Access
      </Typography>
      <Grid container spacing={2}>
        {Object.keys(modules).map((moduleName) => (
          <Grid item xs={12} justifyContent="space-between" key={moduleName}>
            <Paper
              elevation={1}
              sx={{ paddingInline: 1, backgroundColor: '#f5f5f5' }}
            >
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Typography variant="subtitle1">
                    {accessModule[moduleName]}
                  </Typography>
                </Grid>
                <Grid
                  direction="row"
                  container
                  item
                  xs={9}
                  justifyContent="flex-end"
                >
                  {moduleAccessConfig[moduleName]?.map((accessType) => (
                    <Grid item xs={2} key={accessType}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modules[moduleName]?.includes(accessType)}
                            onChange={handleCheckboxChange(
                              moduleName,
                              accessType
                            )}
                          />
                        }
                        label={accessType}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

ModuleAccess.propTypes = {
  defaultValue: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  onChange: PropTypes.func.isRequired,
};

ModuleAccess.defaultProps = {
  defaultValue: {},
};

export default ModuleAccess;
