import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Stack,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/apps.service';

const AppMetadata = ({
  channelDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      shortDescription: channelDetail.shortDescription || '',
      longDescription: channelDetail.longDescription || '',
      keywords: channelDetail.keywords || '',
      seoWords: channelDetail.seoWords || '',
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    delete payload.code;
    updateDataById(channelDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Channel metadata updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box
      component={Paper}
      sx={{
        padding: 2,
      }}
    >
      <form id="channel-metadata-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="shortDescription"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Short Description"
                  type="text"
                  multiline
                  minRows={6}
                  maxRows={6}
                  error={!!errors.shortDescription}
                  helperText={errors?.shortDescription?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="longDescription"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Long Description"
                  type="text"
                  multiline
                  minRows={6}
                  maxRows={6}
                  error={!!errors.longDescription}
                  helperText={errors?.longDescription?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="keywords"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Keywords"
                  type="text"
                  multiline
                  minRows={6}
                  maxRows={6}
                  error={!!errors.keywords}
                  helperText={errors?.keywords?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="seoWords"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="SEO Words"
                  type="text"
                  multiline
                  minRows={6}
                  maxRows={6}
                  error={!!errors.seoWords}
                  helperText={errors?.seoWords?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="channel-metadata-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Metadata
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

AppMetadata.propTypes = {
  channelDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default AppMetadata;
