import React from 'react';
import {
  Typography,
  Chip,
  Box,
  Switch,
  Link,
  Button,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CELL_DATA_TYPES from '../../config/cell-types.config';
import { getPlatformLabel } from '../../config/platforms.config';
import { getAdminRoleLabel } from '../../config/admin-roles.config';
import { getCouponCodeApplyOnLabel } from '../../config/coupon-codes-offers.config';
import {
  formatNumber,
  formatPercent,
  formatDuration,
  formatCurrency,
} from '../../utils/numbers.util';
import { formatDate, formatTimestamp } from '../../utils/datetime.util';
import StatusLabel from './status-label.component';
import MigrationStatusLabel from './migration-status-label.component';
import PasswordLabel from './password-label.component';
import SubscriptionSku from './subscription-sku.component';
import BrdStatusLabel from './brd-status-label.component';
import Iconify from '../common/iconify.component';
import DefaultImage from '../../assets/images/default-image.png';
import ManualStatusLabel from './manual-status-label.component';
import { getContentOwnerRoleLabel } from '../../config/content-owner-roles.config';
import PNMCellLabel from './pnm-cell.component';
import RokuFeedStatusLabel from './roku-feed-status-label.component';
import { VIEW_TOOLTIP_DATA } from '../../config/module-configs/content-videos.config';
import replaceIdUrl from '../../utils/url.util';
import ROUTE_PATH from '../../config/routes.config';

const CellValue = ({
  type,
  dataKey,
  value,
  color,
  fallbackValue,
  expandSubRow,
  handleShowSubRow,
  isWebsiteLink,
  rowData,
  clickedRowData,
  handleShowTooltip,
}) => {
  const navigate = useNavigate();

  if (type === CELL_DATA_TYPES.NUMBER) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatNumber(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.PERCENTAGE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatPercent(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.MONEY) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatCurrency(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DURATION) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDuration(value || 0)}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DATE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDate(value || null, 'll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.DATETIME) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatDate(value || null, 'lll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.TIMESTAMP) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {formatTimestamp(value || null, 'lll')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.BOOL) {
    return (
      <Chip
        label={Number(value) === 1 ? 'Yes' : 'No'}
        color={Number(value) === 1 ? 'success' : 'error'}
        variant="filled"
        size="small"
        sx={{ fontWeight: 700 }}
      />
    );
  }
  if (type === CELL_DATA_TYPES.PLATFORM) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getPlatformLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.STATUS) {
    return <StatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.MIGRATION_STATUS) {
    return <MigrationStatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.ROKU_FEED_STATUS) {
    return <RokuFeedStatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.PASSWORD) {
    return <PasswordLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.CODE) {
    return (
      <Box
        component="span"
        sx={{
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        }}
        color={color}
      >
        {value || ''}
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.ADMIN_ROLE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getAdminRoleLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.CONTENT_OWNER_ROLE) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getContentOwnerRoleLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.SUBSCRIPTION_SKU) {
    return <SubscriptionSku value={value} />;
  }
  if (type === CELL_DATA_TYPES.COUPON_APPLICABLE_ON) {
    return (
      <Typography variant="body2" component="span" color={color}>
        {getCouponCodeApplyOnLabel(value || '')}
      </Typography>
    );
  }
  if (type === CELL_DATA_TYPES.BRD_STATUS) {
    return value !== null ? <BrdStatusLabel value={value} /> : '-';
  }
  if (type === CELL_DATA_TYPES.HTML) {
    return (
      <Box component="span" sx={{ display: 'block' }}>
        <Box
          dangerouslySetInnerHTML={{
            __html: value || '',
          }}
        />
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.SWITCH) {
    return (
      <Switch
        aria-label="status"
        color="primary"
        defaultChecked={!!value}
        onChange={(e) => fallbackValue(e)}
      />
    );
  }
  if (type === CELL_DATA_TYPES.LINK) {
    return (
      <Link
        to={`${value}${isWebsiteLink ? '?cache=false' : ''}`}
        component={RouterLink}
        sx={{ display: 'contents', color: '#250CA6' }}
        target="_blank"
      >
        {value}
      </Link>
    );
  }
  if (type === CELL_DATA_TYPES.SECONDARY_GENRES) {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}
      >
        {Array.isArray(value) &&
          value.map((subGenre) => (
            <React.Fragment key={subGenre.id}>
              <Button
                onClick={() => fallbackValue(subGenre)}
                variant="outlined"
                size="small"
                color="secondary"
                sx={{
                  px: 1,
                  margin: 0,
                  minWidth: 'auto',
                }}
              >
                {subGenre.name}
              </Button>
            </React.Fragment>
          ))}
        <Button
          color="secondary"
          variant="contained"
          sx={{
            padding: 0,
            minWidth: 16,
            height: 16,
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => fallbackValue({ id: null })}
        >
          <Iconify icon="ic:round-add" sx={{ fontSize: 12 }} />
        </Button>
      </Box>
    );
  }
  if (type === CELL_DATA_TYPES.LIST_IMAGE) {
    return (
      <Box
        component="img"
        sx={{
          p: 0,
          m: 0,
          maxHeight: 65,
          maxWidth: 65,
          objectFit: 'contain',
        }}
        src={value || DefaultImage}
      />
    );
  }
  if (type === CELL_DATA_TYPES.VIEW_IMAGE) {
    if (value === null || value === '') {
      return <div />;
    }

    return (
      <Box
        component="img"
        sx={{
          p: 0,
          m: 0,
          maxHeight: 150,
          maxWidth: 150,
          objectFit: 'contain',
        }}
        src={value}
      />
    );
  }
  if (type === CELL_DATA_TYPES.MANUAL_STATUS) {
    return <ManualStatusLabel value={value} />;
  }
  if (type === CELL_DATA_TYPES.PNM_CELL) {
    return <PNMCellLabel dataKey={dataKey} value={value} />;
  }
  if (type === CELL_DATA_TYPES.AD_TIMING) {
    return (
      value &&
      value
        .split(',')
        .map((time, index) => (
          <Chip
            key={index}
            label={time}
            style={{ margin: '4px' }}
            variant="outlined"
          />
        ))
    );
  }
  if (type === CELL_DATA_TYPES.SUB_LIST) {
    return (
      <IconButton aria-label="arrow" onClick={handleShowSubRow}>
        <Iconify
          icon={
            expandSubRow ? 'iconamoon:arrow-up-2' : 'iconamoon:arrow-down-2'
          }
        />
      </IconButton>
    );
  }
  if (type === CELL_DATA_TYPES.TOOLTIP_TEXT) {
    return (
      <Tooltip
        open={value && clickedRowData?.id === rowData?.id}
        arrow
        placement="right"
        title={
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">{value}</Typography>
              <Typography
                variant="button"
                onClick={() =>
                  navigate(
                    replaceIdUrl(ROUTE_PATH.VIDEO_EDIT, clickedRowData.id)
                  )
                }
                sx={{ cursor: 'pointer' }}
              >
                Edit
              </Typography>
            </Box>

            <Divider />

            <Box mt={1}>
              {VIEW_TOOLTIP_DATA.map((c) => (
                <>
                  {c.type === CELL_DATA_TYPES.BOOL && (
                    <Typography variant="body2">
                      {c.label} - {rowData[c.dataKey] ? 'Yes' : 'No'}
                    </Typography>
                  )}
                  {c.type === CELL_DATA_TYPES.PNM_CELL && (
                    <Typography variant="body2">
                      {c.label} -{' '}
                      <PNMCellLabel
                        dataKey={c.dataKey}
                        value={rowData[c.dataKey]}
                      />
                    </Typography>
                  )}
                  {c.type !== CELL_DATA_TYPES.BOOL &&
                    c.type !== CELL_DATA_TYPES.PNM_CELL && (
                      <Typography variant="body2">
                        {c.label} - {rowData[c.dataKey]}
                      </Typography>
                    )}
                  <Box />
                </>
              ))}
            </Box>
          </>
        }
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'white',
              color: 'black',
              border: '1px solid grey',
            },
          },
        }}
      >
        <Typography
          variant="body2"
          component="span"
          color={color}
          onClick={handleShowTooltip}
          sx={{ cursor: 'pointer' }}
        >
          {value}
        </Typography>
      </Tooltip>
    );
  }
  if (type === CELL_DATA_TYPES.PLAYLIST_CHANNELS) {
    return (
      <Box display="flex" flexDirection="column" gap={1}>
        {value.map((channel, index) => (
          <Box key={index} display="flex" alignItems="center" gap={1}>
            {channel.thumbnail && (
              <Box
                component="img"
                sx={{
                  p: 0,
                  m: 0,
                  maxHeight: 65,
                  maxWidth: 65,
                  objectFit: 'contain',
                }}
                src={channel.thumbnail}
              />
            )}
            <Typography variant="body2">{channel.name}</Typography>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Typography variant="body2" component="span" color={color}>
      {value || ''}
    </Typography>
  );
};

CellValue.propTypes = {
  type: PropTypes.string.isRequired,
  dataKey: PropTypes.string.isRequired,
  value: PropTypes.any,
  color: PropTypes.string,
  fallbackValue: PropTypes.func,
  expandSubRow: PropTypes.bool,
  handleShowSubRow: PropTypes.func,
  isWebsiteLink: PropTypes.bool,
  rowData: PropTypes.object,
  clickedRowData: PropTypes.object,
  handleShowTooltip: PropTypes.func,
};
CellValue.defaultProps = {
  value: null,
  color: 'inherit',
  fallbackValue: () => {},
  expandSubRow: false,
  handleShowSubRow: () => {},
  isWebsiteLink: false,
  rowData: {},
  clickedRowData: {},
  handleShowTooltip: () => {},
};

export default CellValue;
