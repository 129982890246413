import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Channels module configurations
export const LISTING_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    width: '30%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'code',
        type: CELL_DATA_TYPES.TEXT,
      },
      {
        dataKey: 'apiKey',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'websiteUrl',
    label: 'Website URL',
    width: '20%',
    align: 'left',
    dataKey: 'websiteUrl',
    sortable: false,
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'rokuFeedUrl',
    label: 'Roku Feed Url',
    width: '15%',
    align: 'left',
    dataKey: 'rokuFeedUrl',
    sortable: false,
    type: CELL_DATA_TYPES.LINK,
    secondaryFields: [
      {
        dataKey: 'rokuFeedStatus',
        type: CELL_DATA_TYPES.ROKU_FEED_STATUS,
      },
    ],
  },
  {
    id: 'migrated',
    label: 'Migrated',
    width: '15%',
    align: 'left',
    dataKey: 'migrated',
    sortable: false,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.COPY_CHANNEL.value,
    label: ACTIONS.COPY_CHANNEL.label,
  },
  {
    action: ACTIONS.MANAGE_CONTENT.value,
    label: ACTIONS.MANAGE_CONTENT.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_BASICINFO_COLUMNS = [
  { id: 'title', label: 'Title', dataKey: 'title', type: CELL_DATA_TYPES.TEXT },
  {
    id: 'displayTitle',
    label: 'Display Title',
    dataKey: 'displayTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  { id: 'code', label: 'Acronym', dataKey: 'code', type: CELL_DATA_TYPES.TEXT },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'gaMeasurement',
    label: 'GA Measurement',
    dataKey: 'gaMeasurement',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'gtmCode',
    label: 'GTM Code',
    dataKey: 'gtmCode',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'image',
    label: 'Image URL',
    dataKey: 'imageUrl',
    type: CELL_DATA_TYPES.VIEW_IMAGE,
  },
  {
    id: 'introVideo',
    label: 'Intro Video URL',
    dataKey: 'introVideoUrl',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'migrationVideo',
    label: 'Migration Video URL',
    dataKey: 'migrationVideoUrl',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'marketingPromotionalVideo',
    label: 'Marketing Promotional Video',
    dataKey: 'marketingPromotionVideoUrl',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'hintVideoForPaidUser',
    label: 'Hint Video (Paid User)',
    dataKey: 'hintVideoUrlForPaidUser',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'hintVideoForFreeUser',
    label: 'Hint Video (Free User)',
    dataKey: 'hintVideoUrlForFreeUser',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'channelDomain',
    label: 'Channel Domain URL',
    dataKey: 'channelDomainUrl',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'ipToLocationApiUrl',
    label: 'IP to Location API URL',
    dataKey: 'ipServiceUrl',
    type: CELL_DATA_TYPES.LINK,
  },
  {
    id: 'analyticsEndpoint',
    label: 'Analytics Endpoint',
    dataKey: 'analyticsEndpointUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'analyticsApiKey',
    label: 'Analytics API Key',
    dataKey: 'analyticsApiKey',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'analyticsInterval',
    label: 'Analytics Interval',
    dataKey: 'analyticsInterval',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channelGenres',
    label: 'Channel Genre',
    dataKey: 'channelGenreName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'showAdsOnSvodTvod',
    label: 'Show Ads on SVOD/TVOD',
    dataKey: 'alwaysShowAds',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'allowGuestVideoPlayback',
    label: 'Allow Guest Video Playback',
    dataKey: 'allowGuestPlayback',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'allowLinkDevice',
    label: 'Allow Link Device',
    dataKey: 'allowLinkDevice',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'allowPALSDK',
    label: 'Allow PAL SDK',
    dataKey: 'allowPalSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
];

export const VIEW_METADATA_COLUMNS = [
  {
    id: 'shortDescription',
    label: 'Short Description',
    dataKey: 'shortDescription',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'longDescription',
    label: 'Long Description',
    dataKey: 'longDescription',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'keywords',
    label: 'Keywords',
    dataKey: 'keywords',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'seoWords',
    label: 'SEO Words',
    dataKey: 'seoWords',
    type: CELL_DATA_TYPES.TEXT,
  },
];

export const VIEW_SUBSCRIPTIONS_COLUMNS = [
  {
    id: 'monthlySubscriptionPrice',
    label: 'Monthly Subscription Price',
    dataKey: 'monthlySvodPrice',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'yearlySubscriptionPrice',
    label: 'Yearly Subscription Price',
    dataKey: 'yearlySvodPrice',
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'legacySVODProducts',
    label: 'Legacy SVOD Products',
    dataKey: 'legacySvodPackages',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'hideMonthlyPlan',
    label: 'Hide Monthly Plan',
    dataKey: 'hideMonthlyPlan',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'svodButtonText',
    label: 'SVOD Button Text',
    dataKey: 'svodButtonText',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'svodStartPrice',
    label: 'SVOD Start Price',
    dataKey: 'svodStartPrice',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'tvodStartPrice',
    label: 'TVOD Start Price',
    dataKey: 'tvodStartPrice',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'tvodExpire',
    label: 'TVOD Expiry',
    dataKey: 'tvodExpire',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'iapRoku',
    label: 'IAP Roku',
    dataKey: 'rokuIapSetting',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'iapWeb',
    label: 'IAP Web',
    dataKey: 'webIapSetting',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'iapFireTvMobile',
    label: 'IAP Fire TV Mobile',
    dataKey: 'firetvIapSetting',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'iapAndroidTvMobile',
    label: 'IAP Android TV Mobile',
    dataKey: 'androidIapSetting',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'iapAppleTvMobile',
    label: 'IAP Apple TV Mobile',
    dataKey: 'appleIapSetting',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'iapSamsungTv',
    label: 'IAP Samsung TV',
    dataKey: 'samsungTvIapSetting',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
  {
    id: 'iapLgTv',
    label: 'IAP LG TV',
    dataKey: 'lgTvIapSetting',
    type: CELL_DATA_TYPES.PNM_CELL,
  },
];

export const VIEW_VFR_COLUMNS = [
  {
    id: 'VFRRokuLibPath',
    label: 'VFR Roku Lib Path',
    dataKey: 'vfrRokuLibPath',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'VFRChannel',
    label: 'VFR Channel',
    dataKey: 'vfrChannel',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'VFRPublisher',
    label: 'VFR Publisher',
    dataKey: 'vfrPublisher',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'useVFRInDemoMode',
    label: 'Use VFR in Demo Mode',
    dataKey: 'isVfrForDemo',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRRoku',
    label: 'Enable VFR Roku',
    dataKey: 'rokuEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRWeb',
    label: 'Enable VFR Web',
    dataKey: 'webEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRFireTv',
    label: 'Enable VFR Fire TV',
    dataKey: 'firetvEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRFireMobile',
    label: 'Enable VFR Fire Mobile',
    dataKey: 'fireMobileEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRAndroidTv',
    label: 'Enable VFR Android TV',
    dataKey: 'androidtvEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRAndroidMobile',
    label: 'Enable VFR Android Mobile',
    dataKey: 'androidMobileEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRAppleTv',
    label: 'Enable VFR Apple TV',
    dataKey: 'appletvEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRAppleMobile',
    label: 'Enable VFR Apple Mobile',
    dataKey: 'appleMobileEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRSamsungTv',
    label: 'Enable VFR Samsung TV',
    dataKey: 'samsungTvEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRLgTv',
    label: 'Enable VFR LG TV',
    dataKey: 'LgTvEnableVfrSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'infoPages',
    label: 'VFR Info Pages',
    dataKey: 'allowVfrSdkInfoPages',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'insidePages',
    label: 'VFR Inside Pages',
    dataKey: 'allowVfrSdkInsidePages',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'videoPlayerRoku',
    label: 'VFR Video Player Roku',
    dataKey: 'allowVfrSdkVideoPlayerRoku',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'videoPlayerFireTv',
    label: 'VFR Video Player Fire TV',
    dataKey: 'allowVfrSdkVideoPlayerFiretv',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'videoPlayerOtherPlatforms',
    label: 'VFR Video Player Other Platforms',
    dataKey: 'allowVfrSdkVideoPlayer',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'videoPlayerOnRAFAds',
    label: 'VFR Video Player on RAF Ads',
    dataKey: 'allowVfrSdkVideoPlayerOnRafAds',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableVFRInsidePages',
    label: 'Enable VFR Inside Pages',
    dataKey: 'enableVfrInsidePagesWhenBrdOn',
    type: CELL_DATA_TYPES.BOOL,
  },
];

export const VIEW_BRD_COLUMNS = [
  {
    id: 'delayVastAds',
    label: 'Delay Vast Ads (Minutes)',
    dataKey: 'brdDelayAdMinutesWhenOptin',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'showConsentDailogAfterAppOpen',
    label: 'Show Consent Dialog After App Open',
    dataKey: 'brdShowConsentAfterNumberOfAppOpen',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'enableBRDRoku',
    label: 'Enable BRD Roku',
    dataKey: 'rokuEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDWeb',
    label: 'Enable BRD Web',
    dataKey: 'webEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDFireTv',
    label: 'Enable BRD Fire TV',
    dataKey: 'firetvEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDFireMobile',
    label: 'Enable BRD Fire Mobile',
    dataKey: 'fireMobileEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDAndroidTv',
    label: 'Enable BRD Android TV',
    dataKey: 'androidtvEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDAndroidMobile',
    label: 'Enable BRD Android Mobile',
    dataKey: 'androidMobileEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDAppleTv',
    label: 'Enable BRD Apple TV',
    dataKey: 'appletvEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDAppleMobile',
    label: 'Enable BRD Apple Mobile',
    dataKey: 'appleMobileEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDSamsungTv',
    label: 'Enable BRD Samsung TV',
    dataKey: 'samsungtvEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableBRDLgTv',
    label: 'Enable BRD LG TV',
    dataKey: 'lgtvEnableBrdSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'disableCornerAdsWithOptIn',
    label: 'Disable Corner Ads with Opt-In',
    dataKey: 'brdDisableCornerAdsWithOptin',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'disableGumgumWithOptIn',
    label: 'Disable GumGum with Opt-In',
    dataKey: 'disableGumgumWhenBrdOptin',
    type: CELL_DATA_TYPES.BOOL,
  },
];

export const VIEW_GUMGUM_COLUMNS = [
  {
    id: 'firstAdPosition',
    label: 'First Ad Position (Seconds)',
    dataKey: 'gumgumFirstAdPositionSeconds',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'nextAdIntervals',
    label: 'Next Ad Intervals (Seconds)',
    dataKey: 'gumgumNextAdIntervalSeconds',
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'gumgumBaseUrl',
    label: 'GumGum Base URL',
    dataKey: 'gumgumBaseUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'useGumgumInDemo',
    label: 'Use GumGum in Demo',
    dataKey: 'isGumgumForDemo',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumRoku',
    label: 'Enable GumGum Roku',
    dataKey: 'rokuEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumWeb',
    label: 'Enable GumGum Web',
    dataKey: 'webEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumFireTv',
    label: 'Enable GumGum Fire TV',
    dataKey: 'firetvEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumFireMobile',
    label: 'Enable GumGum Fire Mobile',
    dataKey: 'fireMobileEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumAndroidTv',
    label: 'Enable GumGum Android TV',
    dataKey: 'androidtvEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumAndroidMobile',
    label: 'Enable GumGum Android Mobile',
    dataKey: 'androidMobileEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumAppleTv',
    label: 'Enable GumGum Apple TV',
    dataKey: 'appletvEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumAppleMobile',
    label: 'Enable GumGum Apple Mobile',
    dataKey: 'appleMobileEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumSamsungTv',
    label: 'Enable GumGum Samsung TV',
    dataKey: 'samsungtvEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'enableGumgumLgTv',
    label: 'Enable GumGum LG TV',
    dataKey: 'lgtvEnableGumgumSdk',
    type: CELL_DATA_TYPES.BOOL,
  },
];

export const VIEW_USER_ON_BOARD_MESSAGE_COLUMNS = [
  {
    id: 'landingPage',
    label: 'Landing Page Hint Message',
    dataKey: 'landingPageHintMessage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'loginSignupPage',
    label: 'Login/Signup Page Hint Message',
    dataKey: 'authPageHintMessage',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'paidUserNotLoggedIn',
    label: 'Paid User Not Logged In Hint Message',
    dataKey: 'paidUserNotLoggedinHintMsg',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'paidUserLoggedIn',
    label: 'Paid User Logged In Hint Message',
    dataKey: 'paidUserLoggedinHintMsg',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'freeUserNotLoggedIn',
    label: 'Free User Not Logged In Hint Message',
    dataKey: 'freeUserNotLoggedinHintMsg',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'freeUserLoggedIn',
    label: 'Free User Logged In Hint Message',
    dataKey: 'freeUserLoggedinHintMsg',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'freeUserSubscription',
    label: 'Free User Subscription Hint Message',
    dataKey: 'freeUserSubscriptionHintMsg',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'onHoldSubscription',
    label: 'On Hold Subscription Hint Message',
    dataKey: 'onHoldSubscriptionHintMsg',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'onGraceSubscription',
    label: 'On Grace Subscription Hint Message',
    dataKey: 'onGraceSubscriptionHintMsg',
    type: CELL_DATA_TYPES.TEXT,
  },
];
