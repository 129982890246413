/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
  Button,
  Box,
  Checkbox,
  Typography,
  Tooltip,
  Popover,
  Slider,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { SketchPicker } from 'react-color';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import MANAGE_COLUMNS from '../../config/module-configs/assets.config';
import {
  getChannelAssetData,
  updateChannelAssetData,
} from '../../services/apps.service';
import ConfirmPopup from '../common/confirm-popup.component';
import S3ImageUpload from '../common/S3ImageUpload';

const ManageAppContentAssets = ({ channelId, channelCode }) => {
  const columns = MANAGE_COLUMNS;

  const [showConfirmDailog, setShowConfirmDailog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentColorField, setCurrentColorField] = useState(null);

  const { control, handleSubmit, setValue, getValues, watch } = useForm({});

  useEffect(() => {
    getChannelAssetData(channelId)
      .then((res) => {
        Object.keys(res.data).forEach((key) => {
          setValue(`${key}.value`, res.data[key]);
        });
        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, []);

  const handleOpen = (event, fieldName) => {
    setAnchorEl(event.currentTarget);
    setCurrentColorField(fieldName);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentColorField(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const handleSaveData = () => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = getValues();
    updateChannelAssetData(channelId, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Assets & theme details updated successfully.',
        });
        setFormSubmitted(false);
        setShowConfirmDailog(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
        setShowConfirmDailog(false);
      });
  };

  const onFormSubmit = (data) => {
    if (Object.values(data).some((item) => item.createForAllChannel)) {
      setShowConfirmDailog(true);
    } else {
      handleSaveData();
    }
  };

  return (
    <>
      {loading && <Loading />}

      {!loading && (
        <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
          <Box
            component={Paper}
            sx={{
              padding: 1,
              marginTop: 3,
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '75vh',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                flex: 1,
                overflowY: 'auto',
                padding: 2,
              }}
            >
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell width="20%">
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: 'center' }}
                        >
                          Label
                        </Typography>
                      </TableCell>
                      <TableCell width="70%">
                        {' '}
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: 'center' }}
                        >
                          Value
                        </Typography>
                      </TableCell>
                      {!!channelId && (
                        <TableCell width="10%">
                          <Typography
                            variant="subtitle1"
                            sx={{ textAlign: 'center' }}
                          >
                            Update for all channels
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                    {columns.map((c) => (
                      <TableRow key={`col-${c.id}`}>
                        <TableCell
                          width="20%"
                          variant="head"
                          sx={{ border: 0, verticalAlign: 'center' }}
                        >
                          {c.label}
                        </TableCell>
                        <TableCell
                          width="70%"
                          variant="body"
                          sx={{ border: 0, verticalAlign: 'top' }}
                        >
                          <Controller
                            name={`${c.dataKey}.value`}
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                              if (c.type === 'COLOR') {
                                return (
                                  <>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        onClick={(e) =>
                                          handleOpen(e, `${c.dataKey}.value`)
                                        }
                                        sx={{
                                          backgroundColor: field.value,
                                          width: '36px',
                                          height: '36px',
                                          minWidth: '36px',
                                          borderRadius: '4px',
                                          padding: 0,
                                          border: `1px solid ${
                                            field.value || '#ccc'
                                          }`,
                                          '&:hover': {
                                            backgroundColor:
                                              field.value || '#fff',
                                            border: `1px solid ${
                                              field.value || '#ccc'
                                            }`,
                                          },
                                        }}
                                      />

                                      <Typography variant="body2">
                                        {field?.value}
                                      </Typography>
                                    </Box>
                                    {c?.text && (
                                      <Box color="gray" fontSize="12px" mt={2}>
                                        {c?.text}
                                      </Box>
                                    )}

                                    <Popover
                                      open={
                                        isPopoverOpen &&
                                        currentColorField ===
                                          `${c.dataKey}.value`
                                      }
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                    >
                                      <SketchPicker
                                        color={field.value}
                                        onChange={(newColor) => {
                                          const hexColor = `${newColor.hex}`;
                                          setValue(
                                            `${c.dataKey}.value`,
                                            hexColor
                                          );
                                        }}
                                      />
                                    </Popover>
                                  </>
                                );
                              }
                              return (
                                <S3ImageUpload
                                  s3Folder={channelCode}
                                  value={field.value}
                                  updateImage={(v) => {
                                    field.onChange(v);
                                    setValue(`${c.dataKey}.value`, v);
                                  }}
                                  allowedSize={
                                    c.dataKey === 'favIconImage'
                                      ? [[16, 16]]
                                      : c.dataKey === 'logoImage'
                                      ? [[216, 50]]
                                      : null
                                  }
                                  aspectRatio={
                                    c.dataKey === 'homePageHeroImage' ||
                                    c.dataKey === 'browsePageBgImage'
                                      ? '1.77'
                                      : null
                                  }
                                  setSnackbarInfo={setSnackbarInfo}
                                  showAsPerOpacity={
                                    c.dataKey === 'homePageHeroImage'
                                  }
                                  opacity={watch(
                                    `homePageHeroImageOpacity.value`
                                  )}
                                />
                              );
                            }}
                          />
                          {c.dataKey === 'homePageHeroImage' && (
                            <Controller
                              name="homePageHeroImageOpacity.value"
                              control={control}
                              defaultValue={1}
                              render={({ field }) => (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    mt: 2,
                                  }}
                                >
                                  <Typography variant="body2">
                                    Opacity
                                  </Typography>
                                  <Slider
                                    value={field.value * 100}
                                    onChange={(_, newValue) =>
                                      field.onChange(newValue / 100)
                                    }
                                    min={0}
                                    max={100}
                                    step={1}
                                    sx={{ width: 200 }}
                                  />
                                  <Typography variant="body2">
                                    {Math.round(field.value * 100)}%
                                  </Typography>
                                </Box>
                              )}
                            />
                          )}
                          {(c.dataKey === 'homePageHeroImage' ||
                            c.dataKey === 'browsePageBgImage') && (
                            <Box color="gray" fontSize="12px">
                              Note: Upload image in 16:9 aspect ratio (1920 x
                              1080 px) for better experience.
                            </Box>
                          )}
                          {c.dataKey === 'favIconImage' && (
                            <Box color="gray" fontSize="12px">
                              Note: Upload fav icon in 1:1 aspect ratio (16 x 16
                              px) for better experience.
                            </Box>
                          )}
                          {c.dataKey === 'logoImage' && (
                            <Box color="gray" fontSize="12px">
                              Note: Upload logo in 108:25 aspect ratio (216 x 50
                              px) for better experience.
                            </Box>
                          )}
                        </TableCell>
                        {!!channelId && (
                          <TableCell
                            width="10%"
                            variant="body"
                            sx={{ border: 0, verticalAlign: 'top' }}
                          >
                            <Controller
                              name={`${c.dataKey}.createForAllChannel`}
                              control={control}
                              defaultValue={false}
                              render={({ field: { onChange, value } }) => (
                                <Tooltip title="update for all channels" arrow>
                                  <Checkbox
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                    disabled={c.type === 'IMAGE'}
                                    onChange={onChange}
                                    checked={value || false}
                                  />
                                </Tooltip>
                              )}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                padding: 2,
                borderTop: '1px solid #ddd',
                textAlign: 'center',
                backgroundColor: 'white',
              }}
            >
              <Button
                type="submit"
                form="add-edit-form"
                color="primary"
                variant="contained"
                disabled={loading || formSubmitted}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      )}

      {showConfirmDailog && (
        <ConfirmPopup
          title="Update for all channels confirmation"
          message={`You have selected 'update for all channels'. Do you want to update assets & theme for all channels? You can not undo this action!`}
          onCancel={() => setShowConfirmDailog(false)}
          onSuccess={handleSaveData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

ManageAppContentAssets.propTypes = {
  channelId: PropTypes.number.isRequired,
  channelCode: PropTypes.string,
};

ManageAppContentAssets.defaultProps = {
  channelCode: '',
};

export default ManageAppContentAssets;
