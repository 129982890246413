import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Datepicker from '../form-elements/datepicker.component';

const DateBetweenInput = ({ id, name, filters, setFilters }) => {
  const filterObject = filters[id] || {
    value: ['', ''],
    condition: 'BETWEEN',
    type: 'inputDate',
    apply: false,
  };

  return (
    <>
      <Datepicker
        id={`from${id}`}
        name={`from${name}`}
        label="From"
        defaultValue={filterObject.value?.[0]}
        clearable
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            [id]: {
              ...(prev[id] || {}),
              value: [
                e ? dayjs(e).format('YYYY-MM-DD') : '',
                prev[id]?.value?.[1] || '',
              ],
              condition: prev[id]?.condition || 'BETWEEN',
              type: 'inputDate',
              apply: prev[id]?.apply || false,
            },
          }))
        }
        sx={{ width: '200px' }}
      />
      <Typography sx={{ textAlign: 'center' }}>-</Typography>
      <Datepicker
        id={`to${id}`}
        name={`to${name}`}
        label="To"
        defaultValue={filterObject.value?.[1]}
        clearable
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            [id]: {
              ...(prev[id] || {}),
              value: [
                prev[id]?.value?.[0] || '',
                e ? dayjs(e).format('YYYY-MM-DD') : '',
              ],
              condition: prev[id]?.condition || 'BETWEEN',
              type: 'inputDate',
              apply: prev[id]?.apply || false,
            },
          }))
        }
        sx={{ width: '200px' }}
      />

      <RadioGroup
        style={{ flexDirection: 'row' }}
        aria-label={`${name}ConditionType`}
        name={`${name}ConditionType`}
        value={filterObject.condition || 'BETWEEN'}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            [id]: {
              ...(prev[id] || {}),
              condition: e.target.value,
              type: 'inputDate',
              value: prev[id]?.value || ['', ''],
              apply: prev[id]?.apply || false,
            },
          }))
        }
      >
        <FormControlLabel value="BETWEEN" control={<Radio />} label="Between" />
        <FormControlLabel value="OUTSIDE" control={<Radio />} label="Outside" />
      </RadioGroup>
    </>
  );
};

DateBetweenInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default DateBetweenInput;
