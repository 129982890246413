import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Stack,
  Button,
  Drawer,
  Box,
  Typography,
  Chip,
} from '@mui/material';
import { ACTIONS } from '../../config/const.config';
import {
  LISTING_COLUMNS,
  LISTING_ACTIONS,
} from '../../config/module-configs/subscribers.config';
import { formatDate } from '../../utils/datetime.util';
import ListData from '../table-elements/list-data.component';
import Iconify from '../common/iconify.component';
import ConfirmPopup from '../common/confirm-popup.component';
import SnackbarInfo from '../common/snackbar-info.component';
import ViewSubscriber from './view-subscriber.component';
import AddEditSubscriber from './add-edit-subscriber.component';
import ChangePassword from './change-password.component';
import FilterSubscribers from './filter-subscribers.component';
import {
  getListData,
  deleteDataById,
} from '../../services/subscribers.service';
import { deleteDataById as deleteSubscriptionById } from '../../services/subscriptions.service';
import AddEditSubscription from '../billing/add-edit-subscription.component';
import ViewSubscription from '../billing/view-subscription.component';
import SubscriptionsHistory from './subscriptions-history.component';
import { FiltersContext } from '../../context/filter.context';
import useFilteredActions from '../../hooks/filtered-action.hook';
import useAccess from '../../hooks/access.hook';

const ListSubscribers = () => {
  const columns = LISTING_COLUMNS;
  const actions = useFilteredActions('manage_users', LISTING_ACTIONS);

  const { hasOperationAccess } = useAccess();

  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 25,
    totalRows: 0,
    rows: [],
    reloadCounter: 0,
    sortBy: '',
    sortOrder: '',
    error: false,
    loading: true,
    appliedFilters: {
      search: { name: 'Search', value: '' },
      startDate: { name: 'Sign Up From Date', value: null },
      endDate: { name: 'Sign Up To Date', value: null },
      channels: { name: 'Channels', value: [] },
      platforms: { name: 'Signup From', value: [] },
      status: { name: 'Status', value: null },
    },
    filtersCounter: 0,
  });
  const [doAction, setDoAction] = useState({
    data: null,
    action: null,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const { openFilter, handleToggleFilters } = useContext(FiltersContext);

  const handlePageChange = (v) => {
    setOptions({
      ...options,
      page: v,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRowsPerPageChange = (v) => {
    setOptions({
      ...options,
      rowsPerPage: v,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleSortingChange = (sortBy, sortOrder) => {
    setOptions({
      ...options,
      sortBy,
      sortOrder,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleActionSelect = (selectedAction, selectedData) => {
    setDoAction({
      ...doAction,
      data: selectedData || null,
      action: selectedAction || null,
    });
  };
  const handleActionCancel = () => {
    setDoAction({
      ...doAction,
      data: null,
      action: null,
    });
  };
  const handleActionSuccess = (message = '') => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    if (message) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message,
        });
      }, 10);
    }

    handleActionCancel();
    handleRefreshData();
  };

  const handleDeleteData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteDataById(doAction.data.id)
      .then(() => {
        handleActionSuccess('User deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleDeleteSubscriptionData = () => {
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    deleteSubscriptionById(doAction.data.subscriptionId)
      .then(() => {
        handleActionSuccess('Subscription deleted successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        handleActionCancel();
      });
  };

  const handleApplyFilters = (selectedFilters) => {
    let counter = 0;
    if (selectedFilters.search.value.trim()) counter += 1;
    if (selectedFilters.startDate.value) counter += 1;
    if (selectedFilters.endDate.value) counter += 1;
    if (selectedFilters.channels.value.length > 0) counter += 1;
    if (selectedFilters.platforms.value.length > 0) counter += 1;
    if (selectedFilters.status.value !== null) counter += 1;

    setOptions({
      ...options,
      appliedFilters: {
        ...selectedFilters,
      },
      filtersCounter: counter,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };
  const handleClearFilters = () => {
    setOptions({
      ...options,
      appliedFilters: {
        search: { name: 'Search', value: '' },
        startDate: { name: 'Sign Up From Date', value: null },
        endDate: { name: 'Sign Up To Date', value: null },
        channels: { name: 'Channels', value: [] },
        platforms: { name: 'Signup From', value: [] },
        status: { name: 'Status', value: null },
      },
      filtersCounter: 0,
      page: 0,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  const handleDeleteFilter = (field) => {
    if (field === 'clear-all') {
      handleClearFilters();
      return;
    }
    const updatedFilters = { ...options.appliedFilters };
    updatedFilters[field] = {
      ...updatedFilters[field],
      value: Array.isArray(updatedFilters[field]?.value) ? [] : null || '',
    };
    setOptions({
      ...options,
      appliedFilters: updatedFilters,
      reloadCounter: options.reloadCounter + 1,
      loading: true,
    });
  };

  useEffect(() => {
    const params = [];
    params.push(`page=${options.page + 1}`);
    params.push(`perPage=${options.rowsPerPage}`);
    if (options.sortBy && options.sortOrder) {
      params.push(`sortBy=${options.sortBy}`);
      params.push(`sortOrder=${options.sortOrder}`);
    }
    const filters = Object.keys(options.appliedFilters).reduce((acc, key) => {
      acc[key] = options.appliedFilters[key].value;
      return acc;
    }, {});
    if (filters.search) {
      params.push(`q=${encodeURIComponent(filters.search)}`);
    }
    if (filters.startDate) {
      const startDate = formatDate(filters.startDate, 'YYYY-MM-DD');
      params.push(`startDate=${encodeURIComponent(startDate)}`);
    }
    if (filters.endDate) {
      const endDate = formatDate(filters.endDate, 'YYYY-MM-DD');
      params.push(`endDate=${encodeURIComponent(endDate)}`);
    }
    if (filters.channels.length > 0) {
      const channels = [];
      filters.channels.forEach((c) => channels.push(c.id));
      params.push(`channels=${channels.join(',')}`);
    }
    if (filters.platforms.length > 0) {
      const platforms = [];
      filters.platforms.forEach((p) => platforms.push(p.id));
      params.push(`platforms=${platforms.join(',')}`);
    }
    if (filters.status !== null) {
      params.push(`status=${filters.status}`);
    }

    const paramsQuery = params.length > 0 ? `?${params.join('&')}` : '';
    getListData(paramsQuery)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          rows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          rows: [],
          error: true,
        });
      });
  }, [options.reloadCounter]);

  return (
    <Box
      sx={{
        transition: 'margin 0.3s ease, width 0.3s ease',
        width: openFilter ? 'calc(100% - 300px)' : '100%',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 0, py: 2 }}
      >
        <div>
          {hasOperationAccess('manage_users', 'create') && (
            <Button
              color="primary"
              variant="contained"
              startIcon={<Iconify icon="ic:round-add" />}
              onClick={() => handleActionSelect(ACTIONS.CREATE.value, null)}
            >
              Create New
            </Button>
          )}
        </div>
        <div>
          <Button
            color="inherit"
            variant="contained"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading}
          >
            Refresh
          </Button>
        </div>
      </Stack>

      {options.appliedFilters &&
        Object.values(options.appliedFilters).some(
          (filter) =>
            (Array.isArray(filter.value) && filter.value.length > 0) ||
            (!Array.isArray(filter.value) &&
              filter.value !== '' &&
              filter.value !== null)
        ) && (
          <Box mb={2}>
            <Typography variant="body1" fontWeight="bold" display="inline">
              Filters Applied:{' '}
              <Chip
                key="all"
                label="Clear all"
                onDelete={() => handleDeleteFilter('clear-all')}
                sx={{ ml: 1, color: 'red' }}
              />
            </Typography>
            {Object.entries(options.appliedFilters)
              .filter(
                ([, filter]) =>
                  (Array.isArray(filter.value) && filter.value.length > 0) ||
                  (!Array.isArray(filter.value) &&
                    filter.value !== '' &&
                    filter.value !== null)
              )
              .map(([field, filter]) => (
                <Chip
                  key={field}
                  label={filter.name || 'Active'}
                  onDelete={() => handleDeleteFilter(field)}
                  sx={{ ml: 1 }}
                />
              ))}
          </Box>
        )}

      <Card>
        <ListData
          columns={columns}
          rows={options.rows}
          page={options.page}
          rowsPerPage={options.rowsPerPage}
          totalRows={options.totalRows}
          loading={options.loading}
          actions={actions}
          error={options.error}
          sortBy={options.sortBy}
          sortOrder={options.sortOrder}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSortChange={handleSortingChange}
          onAction={handleActionSelect}
        />
      </Card>

      <Drawer
        anchor="right"
        open={openFilter}
        variant="persistent"
        PaperProps={{
          sx: { width: 300, border: 'none', overflow: 'hidden' },
        }}
      >
        <FilterSubscribers
          values={options.appliedFilters}
          onCancel={handleToggleFilters}
          onSuccess={handleApplyFilters}
          onClear={handleClearFilters}
        />
      </Drawer>

      {doAction.action === ACTIONS.CREATE.value && (
        <AddEditSubscriber
          title="Create New User"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.EDIT.value && (
        <AddEditSubscriber
          title={`Edit User Details - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.VIEW.value && (
        <ViewSubscriber
          title={`User Details - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.DELETE.value && (
        <ConfirmPopup
          title="Delete User"
          message={`Do you want to delete ${doAction.data.email} user? You can not undo this action!`}
          noteText={
            doAction.data.subscriptionStatus
              ? 'Deleting the user will not delete/cancel the subscriptions assigned to the user. Please notify the user to delete/cancel subscriptions prior account deletion.'
              : ''
          }
          onCancel={handleActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {doAction.action === ACTIONS.CHANGE_PASSWORD.value && (
        <ChangePassword
          title={`Change Password For User - ${doAction.data.email}`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
        />
      )}

      {doAction.action === ACTIONS.CREATE_SUBSCRIPTION.value && (
        <AddEditSubscription
          title={`Create New Subscription - ${doAction.data.email}`}
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          selectedSubscriberData={doAction.data}
        />
      )}

      {doAction.action === ACTIONS.EDIT_SUBSCRIPTION.value && (
        <AddEditSubscription
          title={`Edit Subscription Details - ${doAction.data.email}`}
          dataId={doAction.data.subscriptionId}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          selectedSubscriberData={doAction.data}
        />
      )}

      {doAction.action === ACTIONS.VIEW_SUBSCRIPTION.value && (
        <ViewSubscription
          title={`Subscription Details - ${doAction.data.email}`}
          dataId={doAction.data.subscriptionId}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.SUBSCRIPTIONS_HISTORY.value && (
        <SubscriptionsHistory
          title={`Subscriptions History - ${doAction.data.email} (${doAction.data.channel})`}
          dataId={doAction.data.id}
          onCancel={handleActionCancel}
        />
      )}

      {doAction.action === ACTIONS.REMOVE_SUBSCRIPTION.value && (
        <ConfirmPopup
          title="Remove Subscription"
          message={`Do you want to remove subscription of ${doAction.data.email} subcriber? You can not undo this action!`}
          noteText={
            doAction.data.manuallyAdded === 0
              ? 'Deleting the subscription will not delete/cancel the subscription on stripe/app stores. Please delete/cancel subscription on stripe/app store before proceeding.'
              : ''
          }
          onCancel={handleActionCancel}
          onSuccess={handleDeleteSubscriptionData}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

export default ListSubscribers;
