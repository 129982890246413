import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListChannelGenres from '../../components/content/list-channel-genres.component';

const ChannelGenresView = () => {
  const pageTitle = 'Manage Channels / Channel Genres';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListChannelGenres />
    </AppLayout>
  );
};

export default ChannelGenresView;
