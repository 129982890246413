import { useContext } from 'react';
import { AccessContext } from '../context/access.context';
import { VALID_ADMIN_ROLES } from '../config/const.config';

const useAccess = () => {
  const { accessData } = useContext(AccessContext);

  const hasAccess = (section) =>
    !!(
      [VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
        accessData.role
      ) ||
      ((accessData.role === VALID_ADMIN_ROLES.CONTENT_OWNER ||
        accessData.role === VALID_ADMIN_ROLES.CHANNEL_OWNER ||
        accessData.role === VALID_ADMIN_ROLES.EDITOR) &&
        accessData.rights[section] &&
        accessData.rights[section].length)
    );

  const hasOperationAccess = (section, key) =>
    !!(
      [VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
        accessData.role
      ) ||
      ((accessData.role === VALID_ADMIN_ROLES.CONTENT_OWNER ||
        accessData.role === VALID_ADMIN_ROLES.CHANNEL_OWNER ||
        accessData.role === VALID_ADMIN_ROLES.EDITOR) &&
        accessData.rights[section] &&
        accessData.rights[section].length &&
        accessData.rights[section]?.includes(key))
    );

  const hasAdminAccess = () =>
    !![VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
      accessData.role
    );

  const hasSuperAdminAccess = () =>
    !![VALID_ADMIN_ROLES.SUPER_ADMIN].includes(accessData.role);

  return { hasAccess, hasOperationAccess, hasAdminAccess, hasSuperAdminAccess };
};

export default useAccess;
