import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableContainer,
  Paper,
  TextField,
  Button,
  Typography,
  Grid,
  TableHead,
  Box,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import MANAGE_COLUMNS from '../../config/module-configs/assign-tags.config';
import AdProviderTags from '../form-elements/ad-provider-tags.component';
import Channels from '../form-elements/channels.component';
import {
  assignAdProviderTagsToChannel,
  getAdProviderTagsByChannel,
} from '../../services/ad-provider-tags.service';
import AppTypes from '../form-elements/app-types.component';
import { APP_TYPES } from '../../config/const.config';

const AssignTags = ({ channelId, fromChannel, viewOnly }) => {
  const columns = MANAGE_COLUMNS;

  const [loading, setLoading] = useState(true);
  const [selectedChannelId, setselectedChannelId] = useState(channelId);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const selectedChannelDetail = useRef(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({});

  useEffect(() => {
    setLoading(true);
    if (selectedChannelId) {
      getAdProviderTagsByChannel(selectedChannelId)
        .then((res) => {
          Object.keys(res.data).forEach((key) => {
            setValue(`${key}.appType`, res.data[key].appType);
            setValue(`${key}.adProvider`, res.data[key].adProvider);
            setValue(
              `${key}.adProviderName`,
              res.data[key]?.adProvider?.adProviderName
            );
            setValue(
              `${key}.privacyPolicyForVast`,
              res.data[key].privacyPolicyForVast
            );
            setValue(
              `${key}.appStoreUrlForVast`,
              res.data[key].appStoreUrlForVast
            );
            setValue(`${key}.bundleIdForVast`, res.data[key].bundleIdForVast);
          });
          setLoading(false);
        })
        .catch((err) => {
          const message =
            err.response?.data?.message ||
            'Something went wrong, please try again.';
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'error',
            message,
          });
          setLoading(false);
        });
    } else {
      columns.forEach((item) => {
        setValue(`${item.id}.adProvider`, '');
        setValue(`${item.id}.adProviderName`, '');
        setValue(`${item.id}.privacyPolicyForVast`, '');
        setValue(`${item.id}.appStoreUrlForVast`, '');
        setValue(`${item.id}.bundleIdForVast`, '');
      });
      // Patch for set value
      setTimeout(() => {
        setLoading(false);
      }, 50);
    }
  }, [selectedChannelId]);

  const formateData = (obj) => {
    const result = {
      appType: {},
      otherData: {},
    };

    Object.entries(obj).forEach(([key, value]) => {
      const { adProvider, appType, ...rest } = value;
      result.appType[key] = appType;
      result.otherData[key] = {
        ...rest,
        adProvider: adProvider ? adProvider.id : '',
      };
    });

    return result;
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });
    const payload = formateData(data);
    assignAdProviderTagsToChannel(selectedChannelId, payload)
      .then(() => {
        setFormSubmitted(false);
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: `${
            fromChannel ? 'Platform' : 'Tag'
          } details updated successfully.`,
        });
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setFormSubmitted(false);

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
      });
  };

  return (
    <Box display="flex" flexDirection="column">
      {!fromChannel && (
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={4} md={4}>
            <Channels
              id="user_channels"
              name="user_channels"
              label="Select Channel"
              defaultValue={selectedChannelDetail.current}
              onChange={(v) => {
                setselectedChannelId(v?.id || null);
                selectedChannelDetail.current = v;
              }}
              sx={{ width: '100%' }}
              error={errors?.channels?.message || ''}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              type="submit"
              form="add-edit-form"
              color="primary"
              variant="contained"
              disabled={loading || !selectedChannelId || formSubmitted}
            >
              {fromChannel ? 'Save Platform Settings' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      )}

      <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="10%">
                  <Typography variant="subtitle2">Platform</Typography>
                </TableCell>
                {fromChannel && (
                  <TableCell width="14%">
                    {' '}
                    <Typography
                      variant="subtitle2"
                      sx={{ textAlign: 'center' }}
                    >
                      App Type
                    </Typography>
                  </TableCell>
                )}
                <TableCell width="14%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Tag
                  </Typography>
                </TableCell>
                <TableCell width="14%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Ad Provider
                  </Typography>
                </TableCell>
                <TableCell width="14%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Privacy Policy
                  </Typography>
                </TableCell>
                <TableCell width="14%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    AppStore URL
                  </Typography>
                </TableCell>
                <TableCell width="14%">
                  {' '}
                  <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
                    Bundle ID
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell width="100%" colSpan={6}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}

              {!loading &&
                columns.map((c) => (
                  <TableRow key={`col-${c.id}`}>
                    <TableCell
                      width="10%"
                      variant="body"
                      sx={{ border: 0, verticalAlign: 'center' }}
                    >
                      {c.label}
                    </TableCell>

                    {fromChannel && (
                      <TableCell
                        width="14%"
                        variant="body"
                        sx={{ border: 0, verticalAlign: 'top' }}
                      >
                        <Controller
                          name={`${c.dataKey}.appType`}
                          control={control}
                          defaultValue=""
                          render={({ field: { onChange, value } }) =>
                            !viewOnly ? (
                              <AppTypes
                                id="app_type"
                                name="app_type"
                                label="App Type"
                                defaultValue={value}
                                onChange={onChange}
                              />
                            ) : (
                              <Typography variant="body1">
                                {APP_TYPES[watch(`${c.dataKey}.appType`)] ||
                                  '-'}
                              </Typography>
                            )
                          }
                        />
                      </TableCell>
                    )}

                    <TableCell
                      width="14%"
                      variant="body"
                      sx={{
                        border: 0,
                        verticalAlign: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Controller
                        name={`${c.dataKey}.adProvider`}
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value } }) =>
                          !viewOnly ? (
                            <AdProviderTags
                              id="adProviderTags"
                              name="adProviderTags"
                              label="Tag"
                              defaultValue={value}
                              onChange={(v) => {
                                onChange(v);
                                setValue(
                                  `${c.dataKey}.adProviderName`,
                                  v?.adProviderName || ''
                                );
                              }}
                              sx={{ width: '100%' }}
                              error={
                                errors?.[c.dataKey]?.adProvider?.message || ''
                              }
                              disabled={!selectedChannelId}
                            />
                          ) : (
                            <Typography variant="body1">
                              {watch(`${c.dataKey}.adProviderTags`) || '-'}
                            </Typography>
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      width="14%"
                      variant="body"
                      sx={{
                        border: 0,
                        verticalAlign: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <Controller
                        name={`${c.dataKey}.adProviderName`}
                        control={control}
                        defaultValue=""
                        render={() => (
                          <Typography variant="body1">
                            {watch(`${c.dataKey}.adProviderName`) || '-'}
                          </Typography>
                        )}
                      />
                    </TableCell>
                    <TableCell
                      width="14%"
                      variant="body"
                      sx={{
                        border: 0,
                        verticalAlign:
                          fromChannel && !viewOnly ? 'top' : 'center',
                        textAlign: fromChannel && !viewOnly ? 'left' : 'center',
                      }}
                    >
                      <Controller
                        name={`${c.dataKey}.privacyPolicyForVast`}
                        control={control}
                        defaultValue=""
                        render={({ field }) =>
                          fromChannel && !viewOnly ? (
                            <TextField
                              {...field}
                              label="Privacy Policy"
                              type="text"
                              error={
                                !!errors?.[c.dataKey]?.privacyPolicyForVast
                              }
                              helperText={
                                errors?.[c.dataKey]?.privacyPolicyForVast
                                  ?.message || null
                              }
                              fullWidth
                              variant="filled"
                              disabled={!selectedChannelId}
                            />
                          ) : (
                            <Typography variant="body1">
                              {watch(`${c.dataKey}.privacyPolicyForVast`) ||
                                '-'}
                            </Typography>
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      width="14%"
                      variant="body"
                      sx={{
                        border: 0,
                        verticalAlign:
                          fromChannel && !viewOnly ? 'top' : 'center',
                        textAlign: fromChannel && !viewOnly ? 'left' : 'center',
                      }}
                    >
                      <Controller
                        name={`${c.dataKey}.appStoreUrlForVast`}
                        control={control}
                        defaultValue=""
                        render={({ field }) =>
                          fromChannel && !viewOnly ? (
                            <TextField
                              {...field}
                              label="AppStore URL"
                              type="text"
                              error={!!errors?.[c.dataKey]?.appStoreUrlForVast}
                              helperText={
                                errors?.[c.dataKey]?.appStoreUrlForVast
                                  ?.message || null
                              }
                              fullWidth
                              variant="filled"
                              disabled={!selectedChannelId}
                            />
                          ) : (
                            <Typography variant="body1">
                              {watch(`${c.dataKey}.appStoreUrlForVast`) || '-'}
                            </Typography>
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      width="14%"
                      variant="body"
                      sx={{
                        border: 0,
                        verticalAlign:
                          fromChannel && !viewOnly ? 'top' : 'center',
                        textAlign: fromChannel && !viewOnly ? 'left' : 'center',
                      }}
                    >
                      <Controller
                        name={`${c.dataKey}.bundleIdForVast`}
                        control={control}
                        defaultValue=""
                        render={({ field }) =>
                          fromChannel && !viewOnly ? (
                            <TextField
                              {...field}
                              label="Bundle ID"
                              type="text"
                              error={!!errors?.[c.dataKey]?.bundleIdForVast}
                              helperText={
                                errors?.[c.dataKey]?.bundleIdForVast?.message ||
                                null
                              }
                              fullWidth
                              variant="filled"
                              disabled={!selectedChannelId}
                            />
                          ) : (
                            <Typography variant="body1">
                              {watch(`${c.dataKey}.bundleIdForVast`) || '-'}
                            </Typography>
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </form>

      {fromChannel && !loading && !viewOnly && (
        <Grid
          item
          xs={12}
          sm={8}
          md={8}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || !selectedChannelId || formSubmitted}
          >
            {fromChannel ? 'Save Platform Settings' : 'Save'}
          </Button>
        </Grid>
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

AssignTags.propTypes = {
  channelId: PropTypes.number,
  fromChannel: PropTypes.bool,
  viewOnly: PropTypes.bool,
};
AssignTags.defaultProps = {
  channelId: null,
  fromChannel: false,
  viewOnly: false,
};

export default AssignTags;
