// eslint-disable-next-line no-unused-vars
const validateImage = (file, requiredAspectRatio, allowedSizes) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      // const { width } = img;
      // const { height } = img;
      // const aspectRatio = Number((width / height).toFixed(2));

      // Check aspect ratio
      // if (
      //   Number(requiredAspectRatio) &&
      //   Math.abs(aspectRatio - Number(requiredAspectRatio)).toFixed(2) > 0.01
      // ) {
      //   reject(
      //     new Error(
      //       `Image aspect ratio must be ${
      //         Number(requiredAspectRatio) === 1.7
      //           ? '16:9'
      //           : Number(requiredAspectRatio)
      //       }.`
      //     )
      //   );
      //   return;
      // }

      // if (allowedSizes) {
      //   const isValidSize = allowedSizes.some(
      //     ([allowedWidth, allowedHeight]) =>
      //       width === allowedWidth && height === allowedHeight
      //   );

      //   if (!isValidSize) {
      //     reject(
      //       new Error(
      //         `Image dimensions must be one of the following: ${allowedSizes
      //           .map(([w, h]) => `${w}x${h}`)
      //           .join(', ')}.`
      //       )
      //     );
      //     return;
      //   }
      // }

      resolve();
    };

    img.onerror = () => {
      reject(new Error('Invalid image file.'));
    };

    fileReader.readAsDataURL(file);
  });

export default validateImage;
