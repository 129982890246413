import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Tab, Tabs } from '@mui/material';
import AppLayout from '../../layout/app.layout';
import { viewDataById } from '../../services/apps.service';
import ROUTE_PATH from '../../config/routes.config';
import Loading from '../../components/table-elements/loading.component';
import SnackbarInfo from '../../components/common/snackbar-info.component';
import PageNotFoundView from '../errors/not-found.view';
import ViewGridData from '../../components/table-elements/view-grid-data.component';
import {
  VIEW_BASICINFO_COLUMNS,
  VIEW_BRD_COLUMNS,
  VIEW_GUMGUM_COLUMNS,
  VIEW_METADATA_COLUMNS,
  VIEW_SUBSCRIPTIONS_COLUMNS,
  VIEW_USER_ON_BOARD_MESSAGE_COLUMNS,
  VIEW_VFR_COLUMNS,
} from '../../config/module-configs/apps.config';
import AssignTags from '../../components/advertisers/assign-tags.component';
import Iconify from '../../components/common/iconify.component';
import replaceIdUrl from '../../utils/url.util';

const TABS_CONFIG = [
  {
    value: 'basicInfo',
    label: 'Basic Info',
    columns: VIEW_BASICINFO_COLUMNS,
  },
  {
    value: 'metadata',
    label: 'Metadata',
    columns: VIEW_METADATA_COLUMNS,
  },
  {
    value: 'subscription',
    label: 'Subscription',
    columns: VIEW_SUBSCRIPTIONS_COLUMNS,
  },
  { value: 'VFR', label: 'VFR SDK', columns: VIEW_VFR_COLUMNS },
  {
    value: 'BRD',
    label: 'Bright-SDK',
    columns: VIEW_BRD_COLUMNS,
  },
  {
    value: 'GUMGUM',
    label: 'GumGum',
    columns: VIEW_GUMGUM_COLUMNS,
  },
  {
    value: 'userOnboardMsg',
    label: 'User Onboard Messaging',
    columns: VIEW_USER_ON_BOARD_MESSAGE_COLUMNS,
  },
  {
    value: 'platformSpecific',
    label: 'Platform Specific',
    columns: [],
  },
];

const AppViewDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(TABS_CONFIG[0].value);
  const [loading, setLoading] = useState(true);
  const [showPageNotFound, setShowPageNotFound] = useState(false);
  const [channelDetail, setChannelDetail] = useState({});
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  useEffect(() => {
    viewDataById(id)
      .then((res) => {
        setChannelDetail(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setShowPageNotFound(true);
          return;
        }
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [id]);

  return Number.isNaN(Number(id)) || showPageNotFound ? (
    <PageNotFoundView />
  ) : (
    <AppLayout
      pageTitle={`View Channel Details - ${channelDetail.title}`}
      showBack
      handleBack={() => navigate(ROUTE_PATH.CONTENT_ALL_CHANNELS)}
    >
      <Box display="flex" justifyContent="flex-end" my={1}>
        <Button
          color="inherit"
          variant="contained"
          startIcon={<Iconify icon="bx:edit" />}
          onClick={() => {
            const url = replaceIdUrl(ROUTE_PATH.CONTENT_CHANNEL_EDIT, id);
            navigate(url);
          }}
          disabled={loading}
          sx={{ mr: 1 }}
        >
          Edit This Channel
        </Button>
      </Box>
      <Box height="100%" display="flex">
        <Tabs
          value={tabValue}
          aria-label="tabs"
          orientation="vertical"
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="transparent"
          sx={{ minWidth: 150, maxWidth: 150 }}
          onChange={(_, v) => setTabValue(v)}
        >
          {TABS_CONFIG.map((tab, index) => (
            <Tab
              key={index + 1}
              value={tab.value}
              label={tab.label}
              sx={{
                background: tabValue === tab.value && '#fff',
                borderLeft: tabValue === tab.value && '2px solid #FD0100',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}
            />
          ))}
        </Tabs>

        <Box flexGrow={1}>
          {loading && <Loading />}

          {!loading && tabValue !== 'platformSpecific' && (
            <ViewGridData
              columns={
                TABS_CONFIG.filter((tab) => tab.value === tabValue)[0]?.columns
              }
              row={channelDetail}
            />
          )}
          {!loading && tabValue === 'platformSpecific' && (
            <AssignTags channelId={id} fromChannel viewOnly />
          )}
        </Box>
      </Box>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </AppLayout>
  );
};

export default AppViewDetails;
