import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const getCurrentTimestamp = () => Math.ceil(Date.now() / 1000);

export const getDate = (days = 0) => {
  const date = new Date();
  if (days) {
    date.setDate(date.getDate() + days);
  }
  return date;
};

export const formatDate = (inputDate, format = 'YYYY-MM-DD') => {
  if (!inputDate) return '';

  return dayjs(inputDate).format(format);
};

export const formatTimestamp = (inputTimestamp, format = 'YYYY-MM-DD') => {
  if (!inputTimestamp) return '';

  return dayjs(inputTimestamp * 1000).format(format);
};

export const formatDateToUtc = (inputDate) => {
  if (!inputDate) return '';

  return dayjs(inputDate).utc().format();
};

export const formatTimestampToUtc = (inputTimestamp) => {
  if (!inputTimestamp) return '';

  return dayjs(inputTimestamp * 1000)
    .utc()
    .format();
};

export const getISOWeekNumber = (date) => {
  const tempDate = new Date(date);
  tempDate.setHours(0, 0, 0, 0);

  tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));

  const firstThursday = new Date(tempDate.getFullYear(), 0, 4);

  const weekNumber = Math.ceil(
    ((tempDate - firstThursday) / 86400000 + firstThursday.getDay() + 1) / 7
  );

  return weekNumber;
};

export const getSundaysForSpecificYear = (year) => {
  const weeklyDates = [];
  const date = new Date(year, 0, 1);

  while (date.getDay() !== 0) {
    date.setDate(date.getDate() + 1);
  }

  while (date.getFullYear() === year) {
    const weekNumber = getISOWeekNumber(new Date(date));

    weeklyDates.push({
      date: formatDate(date),
      week: weekNumber,
    });
    date.setDate(date.getDate() + 7);
  }

  return weeklyDates;
};
