import React from 'react';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
} from '@mui/material';
import PropTypes from 'prop-types';
import CellValue from './cell-value.component';

const ViewData = ({ columns, row }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableBody>
        {columns.map((c) => (
          <TableRow key={`col-${c.id}`}>
            <TableCell
              width="25%"
              variant="head"
              sx={{ border: 0, verticalAlign: 'top' }}
            >
              {c.label}
            </TableCell>
            <TableCell
              width="75%"
              variant="body"
              sx={{ border: 0, verticalAlign: 'top' }}
            >
              <CellValue
                type={c.type}
                dataKey={c.dataKey}
                value={row[c.dataKey]}
              />

              {c.secondaryFields && (
                <>
                  {c.secondaryFields.map((sf, sfIdx) => (
                    <React.Fragment key={`sf-data-col-${c.id}-${sfIdx}`}>
                      <br />
                      <CellValue
                        type={sf.type}
                        dataKey={sf.dataKey}
                        value={row[sf.dataKey]}
                        color="secondary"
                      />
                    </React.Fragment>
                  ))}
                </>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

ViewData.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  row: PropTypes.shape().isRequired,
};

export default ViewData;
