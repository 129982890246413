import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// App sessions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'channel',
    label: 'Channel',
    width: '25%',
    align: 'left',
    dataKey: 'channel',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'subscriberFullName',
        type: CELL_DATA_TYPES.TEXT,
      },
      {
        dataKey: 'subscriberUsername',
        type: CELL_DATA_TYPES.TEXT,
      },
    ],
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '10%',
    align: 'center',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'device',
    label: 'Device UID',
    width: '25%',
    align: 'left',
    dataKey: 'deviceId',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'Location',
    width: '15%',
    align: 'left',
    dataKey: 'ip',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'brdStatus',
    label: 'BRD Status',
    width: '10%',
    align: 'center',
    dataKey: 'brdStatus',
    sortable: true,
    type: CELL_DATA_TYPES.BRD_STATUS,
  },
  {
    id: 'sessionStartedAt',
    label: 'Session Start Time',
    width: '20%',
    align: 'left',
    dataKey: 'sessionStartedAt',
    sortable: true,
    type: CELL_DATA_TYPES.DATETIME,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_COLUMNS = [
  {
    id: 'channel',
    label: 'Channel',
    dataKey: 'channel',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'subscriberFullName',
    label: 'User / Subscriber',
    dataKey: 'subscriberFullName',
    type: CELL_DATA_TYPES.TEXT,
    secondaryFields: [
      {
        dataKey: 'subscriberUsername',
        type: CELL_DATA_TYPES.TEXT,
      },
      {
        dataKey: 'subscriberEmail',
        type: CELL_DATA_TYPES.EMAIL,
      },
    ],
  },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'deviceUID',
    label: 'Device UID',
    dataKey: 'deviceUID',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'Location',
    dataKey: 'ip',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'brdStatus',
    label: 'BRD Status',
    dataKey: 'brdStatus',
    type: CELL_DATA_TYPES.BRD_STATUS,
  },
  {
    id: 'sessionStartedAt',
    label: 'Session Start Time',
    dataKey: 'sessionStartedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'sessionEndedAt',
    label: 'Session End Time',
    dataKey: 'sessionEndedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
