import React from 'react';
import PropTypes from 'prop-types';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const Datepicker = ({
  id,
  name,
  label,
  defaultValue,
  onChange,
  disabled,
  clearable,
  sx,
  error,
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer components={['DatePicker']}>
      <DatePicker
        id={id}
        name={name}
        label={label}
        value={defaultValue ? dayjs(defaultValue) : null}
        onChange={onChange}
        sx={sx}
        disabled={disabled}
        slotProps={{
          textField: {
            error: error !== '',
            helperText: error,
            variant: 'filled',
          },
          field: { clearable, onClear: () => onChange(null) },
        }}
      />
    </DemoContainer>
  </LocalizationProvider>
);

Datepicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  sx: PropTypes.shape(),
  error: PropTypes.string,
};
Datepicker.defaultProps = {
  disabled: false,
  clearable: false,
  sx: {},
  defaultValue: null,
  error: '',
};

export default Datepicker;
