// ----------------------------------------------------------------------

export default function Switch(theme) {
    return {
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    color: theme.palette.primary.main,
                },
                colorPrimary: {
                    '&.Mui-checked': {
                        color: theme.palette.success.main,
                    },
                },
                track: {
                    backgroundColor: theme.palette.primary.light,
                    '.Mui-checked.Mui-checked + &': {
                        backgroundColor: theme.palette.success.light,
                    },
                },
            },
        },
    };
}