import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/ad-providers/tags/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/ad-providers/tags/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/ad-providers/tags/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/ad-providers/tags/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getAdProviderTags = async () => {
  const options = prepareRequestOptions(
    `/admin/ad-providers/tags/search-list`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getAdProviderTagsByChannel = async (channelId) => {
  const options = prepareRequestOptions(
    `/admin/channel/${channelId}/ad-provider-tags/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const assignAdProviderTagsToChannel = async (channelId, payload) => {
  const options = prepareRequestOptions(
    `/admin/channel/${channelId}/ad-provider-tags/assign`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getDeployedTags = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/channels/deployed-tags${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
