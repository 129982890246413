import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Stack,
  Box,
  Paper,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/apps.service';

const AppGUMGUMSettings = ({
  channelDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      firstAdPosition:
        channelDetail.gumgumFirstAdPositionSeconds.toString() || 0,
      nextAdIntervals:
        channelDetail.gumgumNextAdIntervalSeconds.toString() || 0,
      gumgumBaseUrl: channelDetail.gumgumBaseUrl || '',
      useGumgumInDemo: !!(channelDetail.isGumgumForDemo || 0),
      enableGumgumRoku: !!(channelDetail.rokuEnableGumgumSdk || 0),
      enableGumgumWeb: !!(channelDetail.webEnableGumgumSdk || 0),
      enableGumgumFireTv: !!(channelDetail.firetvEnableGumgumSdk || 0),
      enableGumgumFireMobile: !!(channelDetail.fireMobileEnableGumgumSdk || 0),
      enableGumgumAndroidTv: !!(channelDetail.androidtvEnableGumgumSdk || 0),
      enableGumgumAndroidMobile: !!(
        channelDetail.androidMobileEnableGumgumSdk || 0
      ),
      enableGumgumAppleTv: !!(channelDetail.appletvEnableGumgumSdk || 0),
      enableGumgumAppleMobile: !!(
        channelDetail.appleMobileEnableGumgumSdk || 0
      ),
      enableGumgumSamsungTv: !!(channelDetail.samsungtvEnableGumgumSdk || 0),
      enableGumgumLgTv: !!(channelDetail.lgtvEnableGumgumSdk || 0),
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    updateDataById(channelDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'GumGum settings updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box
      component={Paper}
      sx={{
        padding: 2,
      }}
    >
      <form id="gumgum-settings-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="firstAdPosition"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="First Ad Position (in seconds)"
                  type="number"
                  error={!!errors.firstAdPosition}
                  helperText={errors?.firstAdPosition?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="nextAdIntervals"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Next Ad Intervals After First Ad (in seconds)"
                  type="number"
                  error={!!errors.nextAdIntervals}
                  helperText={errors?.nextAdIntervals?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="gumgumBaseUrl"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="GUMGUM Base URL Path"
                  type="text"
                  multiline
                  minRows={4}
                  maxRows={4}
                  error={!!errors.gumgumBaseUrl}
                  helperText={errors?.gumgumBaseUrl?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Controller
              name="useGumgumInDemo"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Use GUMGUM In Demo Mode"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumRoku"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Roku"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumWeb"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Web"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumFireTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Fire TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumFireMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Fire Mobile"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumAndroidTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Android TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumAndroidMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Android Mobile"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumAppleTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Apple TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumAppleMobile"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Apple Mobile"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumSamsungTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM Samsung TV"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="enableGumgumLgTv"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Enable GUMGUM LG TV"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="gumgum-settings-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save GumGum Settings
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

AppGUMGUMSettings.propTypes = {
  channelDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default AppGUMGUMSettings;
