import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Button,
  Divider,
  Typography,
  Box,
  IconButton,
  alpha,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';
import Iconify from '../common/iconify.component';
import Scrollbar from '../common/scrollbar.component';
import { DECADES, URL_CHECK } from '../../config/const.config';
import Decades from '../form-elements/decades.component';
import Countries from '../form-elements/countries.component';
import Regions from '../form-elements/regions.component';
import VideoTypes from '../form-elements/video-types.component';
import Trashy from '../form-elements/trashy.component';
import GoodBad from '../form-elements/good-bad.component';
import Booleans from '../form-elements/boolean.component';
import ImdbRanking from '../form-elements/imdb-ranking.component';
import MpaaRating from '../form-elements/mpaa-rating.component';
import RestrictedContent from '../form-elements/restricted-content.component';
import RestrictedContentReason from '../form-elements/restricted-content-reason.component';
import AdTimingStatus from '../form-elements/ad-timing-status.component';
import GenreStatus from '../form-elements/genre-status.component';
import Resolution from '../form-elements/resolution.component';
import Channels from '../form-elements/channels.component';
import UrlCheckType from '../form-elements/url-check-type.component';
import CollapsibleSection from './collapsible-section.component';
import FilterInputCondition from './filter-input-condition.component';
import MultiValueInputCondition from './multi-value-input.component';
import NumberBetweenInput from './number-between-input.component';
import DateBetweenInput from './date-between-input.component';
import useResponsive from '../../hooks/responsive.hook';
import ContentOwnerRoles from '../form-elements/content-owner-roles.component';
import Collections from '../form-elements/collections.component';
import PrimaryGenres from '../form-elements/primary-genres.component';
import SecondaryGenres from '../form-elements/secondary-genres.component';

const FilterVideos = ({ values, onCancel, onSuccess, onClear, from }) => {
  const sectionFilters = {
    common: ['title_name_only', 'genres'],
    basicMetadata: [
      'UUID',
      'aka',
      'description',
      'duration_minutes',
      'year',
      'decade',
      'v.country',
      'region',
      'directors',
      'writers',
      'cast',
      'imdb_id',
      'video_type',
    ],
    seriesMetadata: ['s.title', 'season', 'episode_num'],
    organization: ['plot_keywords', 'collection_id'],
    classification: ['trashy', 'good_bad', 'pnm_exclusives'],
    rating: [
      'rating_override',
      'trashy_rating',
      'five_star_rating',
      'imdb_ranking',
      'mpaa_rating',
    ],
    status: [
      'offline',
      'offline_reason',
      'restricted_content',
      'restricted_content_reason',
      'exclude_from_db',
      'date_license_expiration',
      'ad_timing_status',
      'genre_status',
      'duplicate_imdb',
      'description_status',
      'avod_enabled',
    ],
    monetization: [
      'svod',
      'avod',
      'avod_validated',
      'ad_timing',
      'placementOfAds',
      'placementOfAutoDetectedAd',
      'avod_watched',
    ],
    distributor: ['d.type', 'd.full_name', 'distributor_sku', 'd.royality'],
    rightsManagement: ['geofencing_deny', 'geofencing_allow'],
    system: [
      'resolution',
      'date_creation',
      'trailer_url',
      'video_url',
      'poster_16x9_url',
      'subtitle_en_url',
    ],
    admin: ['mm_notes', 'ms_notes'],
    channels: ['channels'],
    urlCheck: [
      'quality_video',
      'quality_poster_3x4',
      'quality_subtitle',
      'quality_poster_16x9',
      'quality_trailer',
      'quality_source_url',
    ],
  };

  const isDesktop = useResponsive('up', 'lg');

  const [filters, setFilters] = useState(values || {});
  const [selectedPrimaryGenre, setSelectedPrimaryGenre] = useState(null);
  const [selectedSecondaryGenre, setSelectedSecondaryGenre] = useState(null);

  const filterObject = filters.genres
    ? {
        ...filters.genres,
        value: filters.genres.value || [],
        condition: filters.genres.condition || 'AND',
      }
    : {
        value: [],
        condition: 'AND',
        type: 'multiSelectType',
        apply: false,
      };

  const handleAddValue = () => {
    setFilters((prev) => ({
      ...prev,
      genres: {
        ...filterObject,
        type: 'multiSelectType',
        value: [
          ...new Set([
            ...filterObject.value,
            `${
              selectedPrimaryGenre?.name === '* (All)'
                ? '*'
                : selectedPrimaryGenre.name
            } | ${
              // eslint-disable-next-line no-nested-ternary
              selectedSecondaryGenre?.name === '* (All)'
                ? '*'
                : selectedSecondaryGenre?.name === '/ (None)'
                ? '/'
                : selectedSecondaryGenre?.name
            }`,
          ]),
        ],
      },
    }));
  };

  const handleRemoveValue = (val) => {
    setFilters((prev) => ({
      ...prev,
      genres: {
        ...filterObject,
        value: filterObject.value.filter((item) => item !== val),
      },
    }));
  };

  useEffect(() => {
    setFilters(values || {});
  }, [values]);

  const handleChange = (e, id) => {
    setFilters((prev) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        value: e?.target?.value || e,
        condition: 'EQUALS_TO',
        type: 'selectType',
        apply: prev[id]?.apply || false,
      },
    }));
  };

  const getDefaultValue = (id) => filters[id]?.value || '';

  const handleApplyFilters = () => {
    onSuccess(filters);
  };

  const hasAppliedFilters = (sectionKey) =>
    sectionFilters[sectionKey].some((field) => filters[field]?.apply === true);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ px: 1, py: 2, minHeight: isDesktop ? '92px' : 76 }}
      >
        <Typography variant="subtitle1" sx={{ ml: 1, textAlign: 'center' }}>
          Filters
        </Typography>
        {from !== 'video-assignment' && from !== 'edit-videos-list' && (
          <IconButton
            color="inherit"
            variant="contained"
            sx={{
              marginLeft: 2,
              borderRadius: '100%',
              width: 'auto',
              padding: '8px',
              bgcolor: (theme) => alpha(theme.palette.grey[400], 1),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[400], 1),
                boxShadow: 'none',
              },
            }}
            onClick={onCancel}
          >
            <Iconify icon="mdi:menu-close" />
          </IconButton>
        )}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Scrollbar
        sx={{
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <form id="list-data-filters" onSubmit={(e) => e.preventDefault()}>
          <Stack sx={{ p: 2 }}>
            <CollapsibleSection
              title="Common"
              appliedFilter={hasAppliedFilters('common')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  id="title_name_only"
                  title="Title"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="title_name_only"
                    name="titleSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Genres"
                  id="genres"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ flexWrap: 'wrap', mb: 1 }}
                  >
                    {filterObject.value?.length > 0 &&
                      filterObject.value.map((val) => (
                        <Chip
                          key={val}
                          label={val}
                          onDelete={() => handleRemoveValue(val)}
                          sx={{ cursor: 'pointer' }}
                        />
                      ))}
                  </Stack>
                  <PrimaryGenres
                    id="primary_genres"
                    name="primary_genres"
                    label="Primary Genre"
                    onChange={(e) => setSelectedPrimaryGenre(e)}
                    sx={{ width: '100%', my: 1 }}
                  />
                  <SecondaryGenres
                    id="secondary_genres"
                    name="secondary_genres"
                    label="Secondary Genre"
                    onChange={(e) => setSelectedSecondaryGenre(e)}
                    sx={{ width: '100%', my: 1 }}
                    primaryGenreId={selectedPrimaryGenre?.id || null}
                  />
                  <Box textAlign="center">
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      onClick={handleAddValue}
                      sx={{
                        minWidth: 24,
                        padding: '2px 6px',
                        fontSize: '0.7rem',
                        width: 'fit-content',
                      }}
                      disabled={
                        !selectedPrimaryGenre?.name ||
                        !selectedSecondaryGenre?.name
                      }
                    >
                      Add
                    </Button>
                  </Box>
                  <FormControl fullWidth variant="filled" sx={{ my: 1 }}>
                    <InputLabel id="field-genres-select-label">
                      Condition
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="g.idConditionType"
                      name="genreConditionType"
                      value={filters.genres?.condition || 'AND'}
                      label="Condition"
                      onChange={(e) => {
                        setFilters((prev) => ({
                          ...prev,
                          genres: {
                            ...prev.genres,
                            condition: e.target.value,
                            type: 'multiSelectType',
                            value: prev.genres?.value || '',
                            apply: prev.genres?.apply || false,
                          },
                        }));
                      }}
                    >
                      <MenuItem key="and" value="AND">
                        AND
                      </MenuItem>
                      <MenuItem key="or" value="OR">
                        OR
                      </MenuItem>
                    </Select>
                  </FormControl>
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Basic Metadata"
              appliedFilter={hasAppliedFilters('basicMetadata')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="UUID"
                  id="UUID"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="UUID"
                    name="uuidSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Also Known As"
                  id="aka"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="aka"
                    name="akaSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Description"
                  id="description"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <MultiValueInputCondition
                    id="description"
                    name="descriptionSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Duration (Minutes)"
                  id="duration_minutes"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="duration_minutes"
                    name="duration"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Year"
                  id="year"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="year"
                    name="year"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Decade"
                  id="decade"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Decades
                    id="decade"
                    name="decade"
                    label="Decade"
                    defaultValue={getDefaultValue('decade')}
                    onChange={(e) => handleChange(e, 'decade')}
                    validOptions={Object.keys(DECADES)}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Country"
                  id="v.country"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Countries
                    id="v.country"
                    name="v.country"
                    label="Country"
                    defaultValue={() => {
                      getDefaultValue('v.country');
                    }}
                    onChange={(e) => handleChange(e, 'v.country')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Region"
                  id="region"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Regions
                    id="region"
                    name="region"
                    label="Region"
                    defaultValue={getDefaultValue('region')}
                    onChange={(e) => handleChange(e, 'region')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Directors"
                  id="directors"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="directors"
                    name="directorSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Writers"
                  id="writers"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="writers"
                    name="writerSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Cast"
                  id="cast"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <MultiValueInputCondition
                    id="cast"
                    name="castSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="IMDB ID"
                  id="imdb_id"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <MultiValueInputCondition
                    id="imdb_id"
                    name="imdbIdSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Video Type"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="video_type"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <VideoTypes
                    id="video_type"
                    name="videoType"
                    label="Type"
                    defaultValue={getDefaultValue('video_type')}
                    onChange={(e) => handleChange(e, 'video_type')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Series Metadata"
              appliedFilter={hasAppliedFilters('seriesMetadata')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Series Name"
                  id="s.title"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="s.title"
                    name="seriesNameSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Season Number"
                  id="season"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="season"
                    name="seasonNumberSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Episode Number"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="episode_num"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="episode_num"
                    name="episodeNumberSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Organization"
              appliedFilter={hasAppliedFilters('organization')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Plot Keywords"
                  id="plot_keywords"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <MultiValueInputCondition
                    id="plot_keywords"
                    name="plotKeywordSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Collection Name"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="collection_id"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Collections
                    id="collection_id"
                    name="collection_id"
                    label="Collections"
                    defaultValue={getDefaultValue('collection_id')}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        collection_id: {
                          ...(prev.collection_id || {}),
                          value: e,
                          condition: 'EQUALS_TO',
                          type: 'selectType',
                          apply: prev.collection_id?.apply || false,
                        },
                      }))
                    }
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Classification"
              appliedFilter={hasAppliedFilters('classification')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Trashy"
                  id="trashy"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Trashy
                    id="trashy"
                    name="trashy"
                    label="Trashy"
                    defaultValue={getDefaultValue('trashy')}
                    onChange={(e) => handleChange(e, 'trashy')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Good Bad"
                  id="good_bad"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <GoodBad
                    id="good_bad"
                    name="goodBad"
                    label="Good Bad"
                    defaultValue={getDefaultValue('good_bad')}
                    onChange={(e) => handleChange(e, 'good_bad')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Exclusive"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="pnm_exclusives"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Booleans
                    id="pnm_exclusives"
                    name="exclusive"
                    label="Exclusive"
                    defaultValue={getDefaultValue('pnm_exclusives')}
                    onChange={(e) => handleChange(e, 'pnm_exclusives')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Rating"
              appliedFilter={hasAppliedFilters('rating')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Rating Override"
                  id="rating_override"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="rating_override"
                    name="ratingOverride"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Trashy Rating"
                  id="trashy_rating"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="trashy_rating"
                    name="trashyRating"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="5 Star Rating"
                  id="five_star_rating"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="five_star_rating"
                    name="fiveStarRating"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="IMDB Ranking"
                  id="imdb_ranking"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <ImdbRanking
                    id="imdb_ranking"
                    name="imdbRanking"
                    label="IMDB Ranking"
                    defaultValue={getDefaultValue('imdb_ranking')}
                    onChange={(e) => handleChange(e, 'imdb_ranking')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="MPAA Rating"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="mpaa_rating"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <MpaaRating
                    id="mpaa_rating"
                    name="mpaaRating"
                    label="Mpaa Rating"
                    defaultValue={getDefaultValue('mpaa_rating')}
                    onChange={(e) => handleChange(e, 'mpaa_rating')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Status"
              appliedFilter={hasAppliedFilters('status')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Offline"
                  id="offline"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Booleans
                    id="offline"
                    name="offline"
                    label="Offline"
                    defaultValue={getDefaultValue('offline')}
                    onChange={(e) => handleChange(e, 'offline')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Offline Reason"
                  id="offline_reason"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="offline_reason"
                    name="offlineReasonSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Restricted Content"
                  id="restricted_content"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <RestrictedContent
                    id="restricted_content"
                    name="restrictedContent"
                    label="Restricted Content"
                    defaultValue={getDefaultValue('restricted_content')}
                    onChange={(e) => handleChange(e, 'restricted_content')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Restricted Content Reason"
                  id="restricted_content_reason"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <RestrictedContentReason
                    id="restricted_content_reason"
                    name="restrictedContentReason"
                    label="Restricted Content Reason"
                    defaultValue={getDefaultValue('restricted_content_reason')}
                    onChange={(e) =>
                      handleChange(e, 'restricted_content_reason')
                    }
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Exclude From DB"
                  id="exclude_from_db"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Booleans
                    id="exclude_from_db"
                    name="excludeFromDb"
                    label="Exclude From DB"
                    defaultValue={getDefaultValue('exclude_from_db')}
                    onChange={(e) => handleChange(e, 'exclude_from_db')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Date License Expiration"
                  id="date_license_expiration"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <DateBetweenInput
                    id="date_license_expiration"
                    name="dateLicenseExpire"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Ad Timing Status"
                  id="ad_timing_status"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <AdTimingStatus
                    id="ad_timing_status"
                    name="adTimingStatus"
                    label="Ad Timing Status"
                    defaultValue={getDefaultValue('ad_timing_status')}
                    onChange={(e) => handleChange(e, 'ad_timing_status')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Genre Status"
                  id="genre_status"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <GenreStatus
                    id="genre_status"
                    name="genreStatus"
                    label="Genre Status"
                    defaultValue={getDefaultValue('genre_status')}
                    onChange={(e) => handleChange(e, 'genre_status')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Duplicate IMDB"
                  id="duplicate_imdb"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Booleans
                    id="duplicate_imdb"
                    name="duplicateImdb"
                    label="Duplicate IMDB"
                    defaultValue={getDefaultValue('duplicate_imdb')}
                    onChange={(e) => handleChange(e, 'duplicate_imdb')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Description Status"
                  id="description_status"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="description_status"
                    name="descriptionStatusSearch"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="AVOD Enabled"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="avod_enabled"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Booleans
                    id="avod_enabled"
                    name="avodEnabled"
                    label="avod Enabled"
                    defaultValue={getDefaultValue('avod_enabled')}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        avod_enabled: {
                          ...(prev.avod_enabled || {}),
                          value: e?.target?.value || e,
                          condition: 'EQUALS_TO',
                          type: 'monitizationType',
                          apply: prev.avod_enabled?.apply || false,
                        },
                      }))
                    }
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                {/* need to do Ad timing status (system) */}
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Monetization"
              appliedFilter={hasAppliedFilters('monetization')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="SVOD"
                  id="svod"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <DateBetweenInput
                    id="svod"
                    name="svod"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="AVOD"
                  id="avod"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <DateBetweenInput
                    id="avod"
                    name="avod"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>

                <CollapsibleSection
                  title="AVOD License"
                  id="avod_validated"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <RestrictedContent
                    id="avod_validated"
                    name="avodLicense"
                    label="AVOD License"
                    defaultValue={getDefaultValue('avod_validated')}
                    onChange={(e) => handleChange(e, 'avod_validated')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Ad timing"
                  id="ad_timing"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="ad_timing"
                    name="adTiming"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="# Of Ads"
                  id="placementOfAds"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="placementOfAds"
                    name="placementOfAds"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="# Of Auto-detected Ads"
                  id="placementOfAutoDetectedAd"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="placementOfAutoDetectedAd"
                    name="placementOfAutoDetectedAd"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="AWOD Watched (min)"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="avod_watched"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="avod_watched"
                    name="awodWatchedMin"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Distributor"
              appliedFilter={hasAppliedFilters('distributor')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Type"
                  id="d.type"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <ContentOwnerRoles
                    id="d.type"
                    name="d.type"
                    label="Select Type"
                    defaultValue={getDefaultValue('d.type')}
                    onChange={(e) => handleChange(e, 'd.type')}
                    validOptions={[0, 1, 2]}
                    sx={{ width: '100%' }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Name"
                  id="d.username"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="d.username"
                    name="d.username"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="SKU"
                  id="distributor_sku"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <MultiValueInputCondition
                    id="distributor_sku"
                    name="distributor_sku"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Royality"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="d.royality"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <NumberBetweenInput
                    id="d.royality"
                    name="d.royality"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Rights Management"
              appliedFilter={hasAppliedFilters('rightsManagement')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="GeoFencing-Deny"
                  id="geofencing_deny"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="geofencing_deny"
                    name="geoFencingDeny"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="GeoFencing-Allow"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="geofencing_allow"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="geofencing_allow"
                    name="geoFencingAllow"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="System"
              appliedFilter={hasAppliedFilters('system')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Resolution"
                  id="resolution"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Resolution
                    id="resolution"
                    name="resolution"
                    label="Resolution"
                    defaultValue={getDefaultValue('resolution')}
                    onChange={(e) => handleChange(e, 'resolution')}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Date Added"
                  id="date_creation"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <DateBetweenInput
                    id="date_creation"
                    name="dateAdded"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Trailer URL"
                  id="trailer_url"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="trailer_url"
                    name="trailerUrl"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Video URL"
                  id="video_url"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="video_url"
                    name="videoUrl"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Poster-16x9 URL"
                  id="poster_16x9_url"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="poster_16x9_url"
                    name="poster16x9Url"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Subtitle-EN URL"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="subtitle_en_url"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="subtitle_en_url"
                    name="subtitleUrl"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Admin"
              appliedFilter={hasAppliedFilters('admin')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="MM Notes"
                  id="mm_notes"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="mm_notes"
                    name="MMNotes"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="MS Notes"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="ms_notes"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <FilterInputCondition
                    id="ms_notes"
                    name="MSNotes"
                    filters={filters}
                    setFilters={setFilters}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="Channels"
              appliedFilter={hasAppliedFilters('channels')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Assigned To Channel"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="channels"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <Channels
                    id="channels"
                    name="channels"
                    label="Channels"
                    defaultValue={getDefaultValue('channels')}
                    multiple
                    onChange={(v) =>
                      setFilters((prev) => ({
                        ...prev,
                        channels: {
                          ...(prev.channels || {}),
                          value: v,
                          condition: 'AND',
                          type: 'multiSelectType',
                          apply: prev.channels?.apply || false,
                        },
                      }))
                    }
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
            <CollapsibleSection
              title="URL Check"
              sx={{
                borderBottom: 1,
                borderWidth: '1px',
                borderColor: 'gray',
              }}
              appliedFilter={hasAppliedFilters('urlCheck')}
            >
              <Box sx={{ marginBottom: 1 }}>
                <CollapsibleSection
                  title="Video URL Check"
                  id="quality_video"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <UrlCheckType
                    id="quality_video"
                    name="videoUrlCheck"
                    label="Video URL Check"
                    defaultValue={getDefaultValue('quality_video')}
                    onChange={(e) => handleChange(e, 'quality_video')}
                    validOptions={Object.keys(URL_CHECK)}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Poster 3x4 URL Check"
                  id="quality_poster_3x4"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <UrlCheckType
                    id="quality_poster_3x4"
                    name="poster3x4UrlCheck"
                    label="Poster 3x4 URL Check"
                    defaultValue={getDefaultValue('quality_poster_3x4')}
                    onChange={(e) => handleChange(e, 'quality_poster_3x4')}
                    validOptions={Object.keys(URL_CHECK)}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Subtitles URL Check"
                  id="quality_subtitle"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <UrlCheckType
                    id="quality_subtitle"
                    name="SubtitlesUrlCheck"
                    label="Subtitles URL Check"
                    defaultValue={getDefaultValue('quality_subtitle')}
                    onChange={(e) => handleChange(e, 'quality_subtitle')}
                    validOptions={Object.keys(URL_CHECK)}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Poster 16x9 URL Check"
                  id="quality_poster_16x9"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <UrlCheckType
                    id="quality_poster_16x9"
                    name="poster16x9UrlCheck"
                    label="Poster 16x9 URL Check"
                    defaultValue={getDefaultValue('quality_poster_16x9')}
                    onChange={(e) => handleChange(e, 'quality_poster_16x9')}
                    validOptions={Object.keys(URL_CHECK)}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Trailer URL Check"
                  id="quality_trailer"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <UrlCheckType
                    id="quality_trailer"
                    name="trailerUrlCheck"
                    label="Trailer URL Check"
                    defaultValue={getDefaultValue('quality_trailer')}
                    onChange={(e) => handleChange(e, 'quality_trailer')}
                    validOptions={Object.keys(URL_CHECK)}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
                <CollapsibleSection
                  title="Source URL Check"
                  sx={{
                    borderBottom: 1,
                    borderWidth: '1px',
                    borderColor: 'gray',
                  }}
                  id="quality_source_url"
                  subSection
                  filters={filters}
                  setFilters={setFilters}
                >
                  <UrlCheckType
                    id="quality_source_url"
                    name="sourceUrlCheck"
                    label="Source URL Check"
                    defaultValue={getDefaultValue('quality_source_url')}
                    onChange={(e) => handleChange(e, 'quality_source_url')}
                    validOptions={Object.keys(URL_CHECK)}
                    sx={{ width: '100%', my: 1 }}
                  />
                </CollapsibleSection>
              </Box>
            </CollapsibleSection>
          </Stack>
        </form>
      </Scrollbar>
      <Divider />

      <Stack spacing={1} sx={{ p: 2, flex: 1 }}>
        <Button
          fullWidth
          form="list-data-filters"
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleApplyFilters}
        >
          Apply Filters
        </Button>
        <Button
          fullWidth
          type="button"
          color="secondary"
          variant="contained"
          startIcon={<Iconify icon="ic:round-clear-all" />}
          onClick={onClear}
        >
          Clear All
        </Button>
      </Stack>
    </>
  );
};

FilterVideos.propTypes = {
  values: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  from: PropTypes.string,
};

FilterVideos.defaultProps = {
  from: '',
};

export default FilterVideos;
