// Define commonly used constants
export const LS_ADMIN_USER_AUTH_TOKEN = '__pnm_admin_t';
export const LS_ADMIN_USER_NAME = '__pnm_admin_n';
export const LS_ADMIN_USER_EMAIL = '__pnm_admin_e';
export const LS_ADMIN_USER_ROLE = '__pnm_admin_r';

export const VALID_ADMIN_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  EDITOR: 'EDITOR',
  CHANNEL_OWNER: 'CHANNEL_OWNER',
  CONTENT_OWNER: 'CONTENT_OWNER',
};

export const VALID_CONTENT_OWNER_ROLES = {
  DISTRIBUTOR: 'DISTRIBUTOR',
  FILMMAKER: 'FILMMAKER',
  AGGREGATOR: 'AGGREGATOR',
};

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  5: 'Requested Delete',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
  13: 'Cancelled',
  14: 'Expired',
};

export const MANUAL_STATUS_LABELS = {
  0: 'Manual Off',
  1: 'Manual On',
};

export const MIGRATION_STATUS_LABELS = {
  0: 'None',
  1: 'Imported',
  2: 'Login Attempted',
  3: 'Password Reset',
  4: 'Account Synced',
  5: 'Migrated',
};

export const BRD_STATUS_LABELS = {
  0: 'No',
  1: 'Yes',
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  5: 'warning',
  11: 'warning',
  12: 'warning',
  13: 'error',
  14: 'error',
};

export const MIGRATION_STATUS_COLORS = {
  0: 'error',
  1: 'primary',
  2: 'primary',
  3: 'info',
  4: 'warning',
  5: 'success',
};

export const STATUS_ALL_VALUE = 99999;

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CHANNELS: {
    value: 'assign_channels',
    label: 'Assign Channels',
  },
  MANAGE_CONTENT: {
    value: 'manage_content',
    label: 'Manage Web Content',
  },
  COPY_CHANNEL: {
    value: 'duplicate_channel',
    label: 'Duplicate Channel',
  },
  LOGIN_AS_THIS_USER: {
    value: 'login_as_this_user',
    label: 'Login as this user',
  },
  ENABLE_LOGIN: {
    value: 'enable_login',
    label: 'Enable Login',
  },
  CREATE_SUBSCRIPTION: {
    value: 'create_subscription',
    label: 'Create Subscription',
  },
  EDIT_SUBSCRIPTION: {
    value: 'edit_subscription',
    label: 'Edit Subscription',
  },
  VIEW_SUBSCRIPTION: {
    value: 'view_subscription',
    label: 'View Subscription',
  },
  SUBSCRIPTIONS_HISTORY: {
    value: 'subscriptions_history',
    label: 'Subscriptions History',
  },
  REMOVE_SUBSCRIPTION: {
    value: 'remove_subscription',
    label: 'Remove Subscription',
  },
  MANAGE_VIDEOS: {
    value: 'manage_videos',
    label: 'Manage Videos',
  },
  GENERATE_ROKU_FEED: {
    value: 'generate_roku_feed',
    label: 'Generate Roku Feed',
  },
  MANAGE_EPISODES: {
    value: 'manage_episodes',
    label: 'Manage Episodes',
  },
  PLAY_VIDEO: {
    value: 'play_video',
    label: 'Play Video',
  },
  MANAGE_EDITS: {
    value: 'manage_edits',
    label: 'Manage Edits',
  },
  PREVIEW: {
    value: 'preview',
    label: 'Preview',
  },
  MANAGE_ORDER: {
    value: 'manage_order',
    label: 'Manage Order',
  },
};

export const APP_TYPES = {
  AVOD_TYPE: 'AVOD Only',
  SVOD_TYPE: 'SVOD Only',
  TVOD_TYPE: 'TVOD Only',
  AVOD_SVOD_TYPE: 'AVOD & SVOD',
  AVOD_TVOD_TYPE: 'AVOD & TVOD',
  SVOD_TVOD_TYPE: 'SVOD & TVOD',
  AVOD_SVOD_TVOD_TYPE: 'AVOD & SVOD & TVOD',
};

export const IAP_SETTINGS = {
  ROKU_BB: 'Bach Entertainment (BadBen)',
  ROKU_POV: 'POV Horror LLC',
  ROKU_WICK_CON: 'PlayNowMedia LLC',
  ROKU_HB: 'Hexabrain',
  FIRETV_BB: 'Bach Entertainment (BadBen)',
  FIRETV_POV: 'POV Horror LLC',
  FIRETV_PNM: 'PlayNowMedia LLC',
  FIRETV_HB: 'Hexabrain',
  ANDROID_THUNDER: 'Thunder Road Inc',
  ANDROID_PNM: 'PlayNowMedia LLC',
  ANDROID_HB: 'Hexabrain',
  APPLE_THUNDER: 'Thunder Road Inc',
  APPLE_PNM: 'PlayNowMedia LLC',
  APPLE_HB: 'Hexabrain',
  WEB_BB: 'Bach Entertainment (BadBen)',
  WEB_BB_TEST: 'Bach Entertainment (BadBen) [Test]',
  WEB_POV: 'POV Horror LLC',
  WEB_POV_TEST: 'POV Horror LLC [Test]',
  WEB_HB_TEST: 'Hexabrain [Test]',
};

export const RULESET_CONDITION = {
  AND: 'AND',
  OR: 'OR',
};

export const PLAYLIST_LEVEL = {
  1: '1-Level playlist',
  2: '2-Level playlist',
};

export const VIDEO_ORDER_FIELDS = {
  title_name_only: 'Title',
  video_type: 'Video Type',
  description: 'Description',
  description_status: 'Description Status',
  duration_minutes: 'Duration',
  year: 'Year',
  decade: 'Decade',
  country: 'Country',
  region: 'Region',
  cast: 'Cast',
  imdb_id: 'IMDB ID',
  UUID: 'UUID',
  date_creation: 'Date Creation',
  series__title: 'Series',
  season: 'Season',
  episode_num: 'Episode #',
  categories: 'Genres',
  plot_keywords: 'Keywords',
  collections__name: 'Collections',
  trashy: 'Trashy',
  good_bad: 'Good/Bad',
  pnm_exclusives: 'Exclusives',
  five_star_rating: '5 Star rating',
  offline: 'Offline',
  offline_reason: 'Offline Reason',
  mpaa_rating: 'MPAA Rating',
  restricted_content: 'Restricted Content',
  restricted_content_reason: 'Restricted Content Reason',
  restricted_content_source: 'Restricted Content Source',
  exclude_from_db: 'Exclude From DB',
  date_license_expiration: 'Date License Expiration',
  ad_timing_status: 'Ad timing status',
  genre_status: 'Genre Status',
  duplicate_imdb: 'Duplicate IMDB Check',
  quality_video: 'Video URL check',
  quality_poster_16x9: 'Poster URL check',
  quality_trailer: 'Trailer URL Check',
  video_status: 'Video Status',
  video_status_reason: 'Video Status Reason',
  video_status_reason_details: 'Video Status Details',
  trailer_status: 'Trailer Status',
  trailer_status_reason: 'Trailer Reason',
  tvod: 'TVOD Street Date',
  tvod_enabled: 'TVOD Enabled',
  tvod_iap: 'TVOD Price',
  svod: 'SVOD Street Date',
  avod: 'AVOD Street Date',
  avod_validated: 'AVOD License',
  avod_watched: 'AVOD Minutes Watched',
  avod_watched_7: 'AVOD Minutes Watched (week)',
  svod_watched: 'SVOD Minutes Watched',
  ad_timing: 'Ad Timing',
  ad_timing_detected: 'Auto-detected ad times',
  distributor_sku: 'Distributor SKU',
  distributor__Type: 'Distributor Type',
  distributor__name: 'Distributor Name',
  distributor__royality: 'Royalty',
  public_domain: 'Public Domain',
  custom_royality: 'Custom Royality',
  schedule_priority: 'Priority Schedule',
  geofencing_allow: 'GeoFencing Allow',
  geofencing_deny: 'GeoFencing Deny',
  trailer_url: 'Trailer URL',
  video_url: 'Video URL',
  poster_16x9_url: 'Poster 16x9',
  poster_16x9_status: 'Poster 16x9 Status',
  subtitle_en_url: 'Subtitle-EN URL',
  mm_notes: 'MM Notes',
  ms_notes: 'MS Notes',
  imdb_duration_minutes: 'IMDB Duration',
  imdb_language: 'IMDB Language',
  imdb_year: 'IMDB Year',
  imdb_plot_keywords: 'IMDB Keywords',
  imdb_directors: 'IMDB Directors',
  imdb_writers: 'IMDB Writers',
  imdb_cast: 'IMDB Cast',
  imdb_country: 'IMDB Country',
  imdb_description: 'IMDB Description',
};

export const VIDEO_TYPES = {
  FE: 'Films',
  EP: 'Episode',
};

export const VIDEO_RESOLUTION = {
  480: '480p',
  720: '720p',
  1080: '1080p',
  UK: 'Unknown',
};

export const TRASHY = {
  YES: 'Yes',
  NO: 'No',
  PD: 'Not Checked',
};

export const GOOD_BAD = {
  LF: 'Live Featured',
  FF: 'Future Featured',
  PF: 'Past Featured',
  G: 'Good',
  GB: 'Good-Bad',
  B: 'Bad',
  ND: 'No Data',
  NA: 'N/A',
};

export const IMDB_RANKING = {
  L4: '[<4]',
  45: '[4 to 4.9]',
  56: '[5 to 5.9]',
  67: '[6 to 6.9]',
  78: '[7 to 7.9]',
  89: '[8 to 8.9]',
  A9: '[>= 9]',
  NA: 'N/A',
};

export const MPAA_RATING = {
  G: 'General Audiences',
  PG: 'Parental Guidance Suggested',
  'PG-13': 'Parents Strongly Cautioned',
  R: 'Restricted',
  'NC-17': 'NC-17',
  NR: 'Not Rated',
};

export const RESTRICTED_CONTENT = {
  YES: 'Yes',
  NO: 'No',
  NC: 'Not Checked',
};

export const RESTRICTED_CONTENT_REASON = {
  ND: 'Nudity',
  EX: 'Extreme',
  LG: 'Language',
  GO: 'Gore',
  DR: 'Drug Use',
  PR: 'Remove From DB (Porn)',
  AD: 'Adult Content',
  NA: 'Not Assigned',
};

export const EXCLUDE_FROM_DB_REASON = {
  PR: 'Pornography',
  EX: 'Extreme',
  VO: 'Violence',
  OB: 'Objectionable',
  CN: 'Content',
};

export const AD_TIMING_STATUS = {
  NC: 'Not Checked',
  GD: 'Good',
  FI: 'Fixed Intervals',
  NN: 'No Ad Timing Needed',
};

export const GENRE_STATUS = {
  NC: 'Not Checked',
  GD: 'Good',
  NW: 'Needs Work',
};

export const QUALITY_MAX_RESOLUTION = {
  PS: 'Pass',
  OE: 'Over Encoded',
  NC: 'Not Checked',
};

export const VIDEO_STATUS = {
  NC: 'Not Checked',
  GD: 'Good',
  NI: 'Non-critical Issue',
};

export const VIDEO_STATUS_REASON = {
  NC: 'Not Checked',
  MS: 'Missing',
  BF: 'Bad File',
  WM: 'Wrong Movie',
  WE: 'Watermarked',
  PQ: 'Poor Video Quality',
  AN: 'Audio Not Synced',
  AM: 'Audio Dialog Missing',
  AI: 'Audio in one channel',
  AD: 'Audio Drops/Missing',
};

export const TRAILER_STATUS = {
  NC: 'Not Checked',
  GD: 'Good',
  NI: 'Missing',
  RM: 'Remove',
};

export const TRAILER_STATUS_REASON = {
  NC: 'Not Checked',
  RC: 'Restricted Content',
  WM: 'Wrong Movie',
  WE: 'Watermarked',
  PQ: 'Poor Quality',
  WL: 'Wrong Language',
  WF: 'Wrong Film',
};

export const TVOD_PRICE = {
  1: '0.99$',
  2: '1.99$',
  3: '2.99$',
  4: '3.99$',
  5: '4.99$',
  6: '5.99$',
  7: '6.99$',
  8: '7.99$',
  9: '8.99$',
  10: '9.99$',
};

export const QUALITY_CHECK = {
  PS: 'Pass',
  FA: 'Fail',
  NC: 'Not Checked',
};

export const REGIONS = [
  {
    value: 'NA',
    label: 'North America',
  },
  {
    value: 'SA',
    label: 'South America',
  },
  {
    value: 'EU',
    label: 'Europe',
  },
  {
    value: 'AS',
    label: 'Asia',
  },
  {
    value: 'AU',
    label: 'Australia',
  },
  {
    value: 'AF',
    label: 'Africa',
  },
  {
    value: 'AN',
    label: 'Antarctica',
  },
  {
    value: 'UK',
    label: 'Unknown',
  },
];

export const COUNTRIES = [
  { label: 'Rwanda', value: 'RW', regionCode: 'AF' },
  { label: 'Somalia', value: 'SO', regionCode: 'AF' },
  { label: 'Yemen', value: 'YE', regionCode: 'AS' },
  { label: 'Iraq', value: 'IQ', regionCode: 'AS' },
  { label: 'Saudi Arabia', value: 'SA', regionCode: 'AS' },
  { label: 'Iran', value: 'IR', regionCode: 'AS' },
  { label: 'Cyprus', value: 'CY', regionCode: 'EU' },
  { label: 'Tanzania', value: 'TZ', regionCode: 'AF' },
  { label: 'Syria', value: 'SY', regionCode: 'AS' },
  { label: 'Armenia', value: 'AM', regionCode: 'AS' },
  { label: 'Kenya', value: 'KE', regionCode: 'AF' },
  { label: 'DR Congo', value: 'CD', regionCode: 'AF' },
  { label: 'Djibouti', value: 'DJ', regionCode: 'AF' },
  { label: 'Uganda', value: 'UG', regionCode: 'AF' },
  { label: 'Central African Republic', value: 'CF', regionCode: 'AF' },
  { label: 'Seychelles', value: 'SC', regionCode: 'AF' },
  { label: 'Jordan', value: 'JO', regionCode: 'AS' },
  { label: 'Lebanon', value: 'LB', regionCode: 'AS' },
  { label: 'Kuwait', value: 'KW', regionCode: 'AS' },
  { label: 'Oman', value: 'OM', regionCode: 'AS' },
  { label: 'Qatar', value: 'QA', regionCode: 'AS' },
  { label: 'Bahrain', value: 'BH', regionCode: 'AS' },
  { label: 'United Arab Emirates', value: 'AE', regionCode: 'AS' },
  { label: 'Israel', value: 'IL', regionCode: 'AS' },
  { label: 'Turkey', value: 'TR', regionCode: 'AS' },
  { label: 'Ethiopia', value: 'ET', regionCode: 'AF' },
  { label: 'Eritrea', value: 'ER', regionCode: 'AF' },
  { label: 'Egypt', value: 'EG', regionCode: 'AF' },
  { label: 'Sudan', value: 'SD', regionCode: 'AF' },
  { label: 'Greece', value: 'GR', regionCode: 'EU' },
  { label: 'Burundi', value: 'BI', regionCode: 'AF' },
  { label: 'Estonia', value: 'EE', regionCode: 'EU' },
  { label: 'Latvia', value: 'LV', regionCode: 'EU' },
  { label: 'Azerbaijan', value: 'AZ', regionCode: 'AS' },
  { label: 'Lithuania', value: 'LT', regionCode: 'EU' },
  { label: 'Svalbard and Jan Mayen', value: 'SJ', regionCode: 'EU' },
  { label: 'Georgia', value: 'GE', regionCode: 'AS' },
  { label: 'Moldova', value: 'MD', regionCode: 'EU' },
  { label: 'Belarus', value: 'BY', regionCode: 'EU' },
  { label: 'Finland', value: 'FI', regionCode: 'EU' },
  { label: 'Åland', value: 'AX', regionCode: 'EU' },
  { label: 'Ukraine', value: 'UA', regionCode: 'EU' },
  { label: 'North Macedonia', value: 'MK', regionCode: 'EU' },
  { label: 'Hungary', value: 'HU', regionCode: 'EU' },
  { label: 'Bulgaria', value: 'BG', regionCode: 'EU' },
  { label: 'Albania', value: 'AL', regionCode: 'EU' },
  { label: 'Poland', value: 'PL', regionCode: 'EU' },
  { label: 'Romania', value: 'RO', regionCode: 'EU' },
  { label: 'Zimbabwe', value: 'ZW', regionCode: 'AF' },
  { label: 'Zambia', value: 'ZM', regionCode: 'AF' },
  { label: 'Comoros', value: 'KM', regionCode: 'AF' },
  { label: 'Malawi', value: 'MW', regionCode: 'AF' },
  { label: 'Lesotho', value: 'LS', regionCode: 'AF' },
  { label: 'Botswana', value: 'BW', regionCode: 'AF' },
  { label: 'Mauritius', value: 'MU', regionCode: 'AF' },
  { label: 'Eswatini', value: 'SZ', regionCode: 'AF' },
  { label: 'Réunion', value: 'RE', regionCode: 'EU' },
  { label: 'South Africa', value: 'ZA', regionCode: 'AF' },
  { label: 'Mayotte', value: 'YT', regionCode: 'AF' },
  { label: 'Mozambique', value: 'MZ', regionCode: 'AF' },
  { label: 'Madagascar', value: 'MG', regionCode: 'AF' },
  { label: 'Afghanistan', value: 'AF', regionCode: 'AS' },
  { label: 'Pakistan', value: 'PK', regionCode: 'AS' },
  { label: 'Bangladesh', value: 'BD', regionCode: 'AS' },
  { label: 'Turkmenistan', value: 'TM', regionCode: 'AS' },
  { label: 'Tajikistan', value: 'TJ', regionCode: 'AS' },
  { label: 'Sri Lanka', value: 'LK', regionCode: 'AS' },
  { label: 'Bhutan', value: 'BT', regionCode: 'AS' },
  { label: 'India', value: 'IN', regionCode: 'AS' },
  { label: 'Maldives', value: 'MV', regionCode: 'AS' },
  { label: 'British Indian Ocean Territory', value: 'IO', regionCode: 'AS' },
  { label: 'Nepal', value: 'NP', regionCode: 'AS' },
  { label: 'Myanmar', value: 'MM', regionCode: 'AS' },
  { label: 'Uzbekistan', value: 'UZ', regionCode: 'AS' },
  { label: 'Kazakhstan', value: 'KZ', regionCode: 'AS' },
  { label: 'Kyrgyzstan', value: 'KG', regionCode: 'AS' },
  { label: 'French Southern Territories', value: 'TF', regionCode: 'AU' },
  { label: 'Heard Island and McDonald Islands', value: 'HM', regionCode: 'AU' },
  { label: 'Cocos [Keeling] Islands', value: 'CC', regionCode: 'AU' },
  { label: 'Palau', value: 'PW', regionCode: 'AU' },
  { label: 'Vietnam', value: 'VN', regionCode: 'AS' },
  { label: 'Thailand', value: 'TH', regionCode: 'AS' },
  { label: 'Indonesia', value: 'ID', regionCode: 'AS' },
  { label: 'Laos', value: 'LA', regionCode: 'AS' },
  { label: 'Taiwan', value: 'TW', regionCode: 'AS' },
  { label: 'Philippines', value: 'PH', regionCode: 'AS' },
  { label: 'Malaysia', value: 'MY', regionCode: 'AS' },
  { label: 'China', value: 'CN', regionCode: 'AS' },
  { label: 'Hong Kong', value: 'HK', regionCode: 'AS' },
  { label: 'Brunei', value: 'BN', regionCode: 'AS' },
  { label: 'Macao', value: 'MO', regionCode: 'AS' },
  { label: 'Cambodia', value: 'KH', regionCode: 'AS' },
  { label: 'South Korea', value: 'KR', regionCode: 'AS' },
  { label: 'Japan', value: 'JP', regionCode: 'AS' },
  { label: 'North Korea', value: 'KP', regionCode: 'AS' },
  { label: 'Singapore', value: 'SG', regionCode: 'AS' },
  { label: 'Cook Islands', value: 'CK', regionCode: 'AU' },
  { label: 'East Timor', value: 'TL', regionCode: 'AS' },
  { label: 'Russia', value: 'RU', regionCode: 'AS' },
  { label: 'Mongolia', value: 'MN', regionCode: 'AS' },
  { label: 'Australia', value: 'AU', regionCode: 'AU' },
  { label: 'Christmas Island', value: 'CX', regionCode: 'AU' },
  { label: 'Marshall Islands', value: 'MH', regionCode: 'AU' },
  { label: 'Federated States of Micronesia', value: 'FM', regionCode: 'AU' },
  { label: 'Papua New Guinea', value: 'PG', regionCode: 'AU' },
  { label: 'Solomon Islands', value: 'SB', regionCode: 'AU' },
  { label: 'Tuvalu', value: 'TV', regionCode: 'AU' },
  { label: 'Nauru', value: 'NR', regionCode: 'AU' },
  { label: 'Vanuatu', value: 'VU', regionCode: 'AU' },
  { label: 'New Caledonia', value: 'NC', regionCode: 'AU' },
  { label: 'Norfolk Island', value: 'NF', regionCode: 'AU' },
  { label: 'New Zealand', value: 'NZ', regionCode: 'AU' },
  { label: 'Fiji', value: 'FJ', regionCode: 'AU' },
  { label: 'Libya', value: 'LY', regionCode: 'AF' },
  { label: 'Cameroon', value: 'CM', regionCode: 'AF' },
  { label: 'Senegal', value: 'SN', regionCode: 'AF' },
  { label: 'Congo Republic', value: 'CG', regionCode: 'AF' },
  { label: 'Portugal', value: 'PT', regionCode: 'EU' },
  { label: 'Liberia', value: 'LR', regionCode: 'AF' },
  { label: 'Ivory Coast', value: 'CI', regionCode: 'AF' },
  { label: 'Ghana', value: 'GH', regionCode: 'AF' },
  { label: 'Equatorial Guinea', value: 'GQ', regionCode: 'AF' },
  { label: 'Nigeria', value: 'NG', regionCode: 'AF' },
  { label: 'Burkina Faso', value: 'BF', regionCode: 'AF' },
  { label: 'Togo', value: 'TG', regionCode: 'AF' },
  { label: 'Guinea-Bissau', value: 'GW', regionCode: 'AF' },
  { label: 'Mauritania', value: 'MR', regionCode: 'AF' },
  { label: 'Benin', value: 'BJ', regionCode: 'AF' },
  { label: 'Gabon', value: 'GA', regionCode: 'AF' },
  { label: 'Sierra Leone', value: 'SL', regionCode: 'AF' },
  { label: 'São Tomé and Príncipe', value: 'ST', regionCode: 'AF' },
  { label: 'Gibraltar', value: 'GI', regionCode: 'EU' },
  { label: 'Gambia', value: 'GM', regionCode: 'AF' },
  { label: 'Guinea', value: 'GN', regionCode: 'AF' },
  { label: 'Chad', value: 'TD', regionCode: 'AF' },
  { label: 'Niger', value: 'NE', regionCode: 'AF' },
  { label: 'Mali', value: 'ML', regionCode: 'AF' },
  { label: 'Western Sahara', value: 'EH', regionCode: 'AF' },
  { label: 'Tunisia', value: 'TN', regionCode: 'AF' },
  { label: 'Spain', value: 'ES', regionCode: 'EU' },
  { label: 'Morocco', value: 'MA', regionCode: 'AF' },
  { label: 'Malta', value: 'MT', regionCode: 'EU' },
  { label: 'Algeria', value: 'DZ', regionCode: 'AF' },
  { label: 'Faroe Islands', value: 'FO', regionCode: 'EU' },
  { label: 'Denmark', value: 'DK', regionCode: 'EU' },
  { label: 'Iceland', value: 'IS', regionCode: 'EU' },
  { label: 'United Kingdom', value: 'GB', regionCode: 'EU' },
  { label: 'Switzerland', value: 'CH', regionCode: 'EU' },
  { label: 'Sweden', value: 'SE', regionCode: 'EU' },
  { label: 'Netherlands', value: 'NL', regionCode: 'EU' },
  { label: 'Austria', value: 'AT', regionCode: 'EU' },
  { label: 'Belgium', value: 'BE', regionCode: 'EU' },
  { label: 'Germany', value: 'DE', regionCode: 'EU' },
  { label: 'Luxembourg', value: 'LU', regionCode: 'EU' },
  { label: 'Ireland', value: 'IE', regionCode: 'EU' },
  { label: 'Principality of Monaco', value: 'MC', regionCode: 'EU' },
  { label: 'France', value: 'FR', regionCode: 'EU' },
  { label: 'Andorra', value: 'AD', regionCode: 'EU' },
  { label: 'Liechtenstein', value: 'LI', regionCode: 'EU' },
  { label: 'Jersey', value: 'JE', regionCode: 'EU' },
  { label: 'Isle of Man', value: 'IM', regionCode: 'EU' },
  { label: 'Guernsey', value: 'GG', regionCode: 'EU' },
  { label: 'Slovakia', value: 'SK', regionCode: 'EU' },
  { label: 'Czechia', value: 'CZ', regionCode: 'EU' },
  { label: 'Norway', value: 'NO', regionCode: 'EU' },
  { label: 'Vatican City', value: 'VA', regionCode: 'EU' },
  { label: 'San Marino', value: 'SM', regionCode: 'EU' },
  { label: 'Italy', value: 'IT', regionCode: 'EU' },
  { label: 'Slovenia', value: 'SI', regionCode: 'EU' },
  { label: 'Montenegro', value: 'ME', regionCode: 'EU' },
  { label: 'Croatia', value: 'HR', regionCode: 'EU' },
  { label: 'Bosnia and Herzegovina', value: 'BA', regionCode: 'EU' },
  { label: 'Angola', value: 'AO', regionCode: 'AF' },
  { label: 'Namibia', value: 'NA', regionCode: 'AF' },
  { label: 'Saint Helena', value: 'SH', regionCode: 'AF' },
  { label: 'Bouvet Island', value: 'BV', regionCode: 'AF' },
  { label: 'Barbados', value: 'BB', regionCode: 'SA' },
  { label: 'Cabo Verde', value: 'CV', regionCode: 'AF' },
  { label: 'Guyana', value: 'GY', regionCode: 'SA' },
  { label: 'French Guiana', value: 'GF', regionCode: 'SA' },
  { label: 'Suriname', value: 'SR', regionCode: 'SA' },
  { label: 'Saint Pierre and Miquelon', value: 'PM', regionCode: 'NA' },
  { label: 'Greenland', value: 'GL', regionCode: 'NA' },
  { label: 'Paraguay', value: 'PY', regionCode: 'SA' },
  { label: 'Uruguay', value: 'UY', regionCode: 'SA' },
  { label: 'Brazil', value: 'BR', regionCode: 'SA' },
  { label: 'Falkland Islands', value: 'FK', regionCode: 'SA' },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    regionCode: 'AN',
  },
  { label: 'Jamaica', value: 'JM', regionCode: 'SA' },
  { label: 'Dominican Republic', value: 'DO', regionCode: 'SA' },
  { label: 'Cuba', value: 'CU', regionCode: 'SA' },
  { label: 'Martinique', value: 'MQ', regionCode: 'SA' },
  { label: 'Bahamas', value: 'BS', regionCode: 'SA' },
  { label: 'Bermuda', value: 'BM', regionCode: 'SA' },
  { label: 'Anguilla', value: 'AI', regionCode: 'SA' },
  { label: 'Trinidad and Tobago', value: 'TT', regionCode: 'SA' },
  { label: 'St Kitts and Nevis', value: 'KN', regionCode: 'SA' },
  { label: 'Dominica', value: 'DM', regionCode: 'SA' },
  { label: 'Antigua and Barbuda', value: 'AG', regionCode: 'SA' },
  { label: 'Saint Lucia', value: 'LC', regionCode: 'SA' },
  { label: 'Turks and Caicos Islands', value: 'TC', regionCode: 'SA' },
  { label: 'Aruba', value: 'AW', regionCode: 'SA' },
  { label: 'British Virgin Islands', value: 'VG', regionCode: 'SA' },
  { label: 'Saint Vincent and the Grenadines', value: 'VC', regionCode: 'SA' },
  { label: 'Montserrat', value: 'MS', regionCode: 'SA' },
  { label: 'Saint Martin', value: 'MF', regionCode: 'NA' },
  { label: 'Saint Barthélemy', value: 'BL', regionCode: 'NA' },
  { label: 'Guadeloupe', value: 'GP', regionCode: 'SA' },
  { label: 'Grenada', value: 'GD', regionCode: 'SA' },
  { label: 'Cayman Islands', value: 'KY', regionCode: 'SA' },
  { label: 'Belize', value: 'BZ', regionCode: 'SA' },
  { label: 'El Salvador', value: 'SV', regionCode: 'SA' },
  { label: 'Guatemala', value: 'GT', regionCode: 'SA' },
  { label: 'Honduras', value: 'HN', regionCode: 'SA' },
  { label: 'Nicaragua', value: 'NI', regionCode: 'SA' },
  { label: 'Costa Rica', value: 'CR', regionCode: 'SA' },
  { label: 'Venezuela', value: 'VE', regionCode: 'SA' },
  { label: 'Ecuador', value: 'EC', regionCode: 'SA' },
  { label: 'Colombia', value: 'CO', regionCode: 'SA' },
  { label: 'Panama', value: 'PA', regionCode: 'SA' },
  { label: 'Haiti', value: 'HT', regionCode: 'SA' },
  { label: 'Argentina', value: 'AR', regionCode: 'SA' },
  { label: 'Chile', value: 'CL', regionCode: 'SA' },
  { label: 'Bolivia', value: 'BO', regionCode: 'SA' },
  { label: 'Peru', value: 'PE', regionCode: 'SA' },
  { label: 'Mexico', value: 'MX', regionCode: 'SA' },
  { label: 'French Polynesia', value: 'PF', regionCode: 'AU' },
  { label: 'Pitcairn Islands', value: 'PN', regionCode: 'AU' },
  { label: 'Kiribati', value: 'KI', regionCode: 'AU' },
  { label: 'Tokelau', value: 'TK', regionCode: 'AU' },
  { label: 'Tonga', value: 'TO', regionCode: 'AU' },
  { label: 'Wallis and Futuna', value: 'WF', regionCode: 'AU' },
  { label: 'Samoa', value: 'WS', regionCode: 'AU' },
  { label: 'Niue', value: 'NU', regionCode: 'AU' },
  { label: 'Northern Mariana Islands', value: 'MP', regionCode: 'AU' },
  { label: 'Guam', value: 'GU', regionCode: 'AU' },
  { label: 'Puerto Rico', value: 'PR', regionCode: 'SA' },
  { label: 'U.S. Virgin Islands', value: 'VI', regionCode: 'SA' },
  { label: 'U.S. Minor Outlying Islands', value: 'UM', regionCode: 'NA' },
  { label: 'American Samoa', value: 'AS', regionCode: 'AU' },
  { label: 'Canada', value: 'CA', regionCode: 'NA' },
  { label: 'United States', value: 'US', regionCode: 'NA' },
  { label: 'Palestine', value: 'PS', regionCode: 'AS' },
  { label: 'Serbia', value: 'RS', regionCode: 'EU' },
  { label: 'Antarctica', value: 'AQ', regionCode: 'AN' },
  { label: 'Sint Maarten', value: 'SX', regionCode: 'NA' },
  { label: 'Curaçao', value: 'CW', regionCode: 'SA' },
  { label: 'Bonaire, Sint Eustatius, and Saba', value: 'BQ', regionCode: 'SA' },
  { label: 'South Sudan', value: 'SS', regionCode: 'AF' },
  { label: 'Unknown', value: 'UN', regionCode: '' },
];

export const FILTER_CONDITION_TYPE = {
  CONTAINS: 'Contains',
  DOES_NOT_CONTAIN: 'Does not contain',
  EQUALS_TO: 'Equals to',
  BLANK: 'Blank',
  NOT_BLANK: 'Not Blank',
};

export const DECADES = {
  '1910s': '1910s',
  '1920s': '1920s',
  '1930s': '1930s',
  '1940s': '1940s',
  '1950s': '1950s',
  '1960s': '1960s',
  '1970s': '1970s',
  '1980s': '1980s',
  '1990s': '1990s',
  '2000s': '2000s',
  '2010s': '2010s',
  '2020s': '2020s',
  '2030s': '2030s',
};

export const BOOLEAN = {
  YES: 'Yes',
  NO: 'No',
};

export const URL_CHECK = {
  PS: 'Pass',
  FA: 'Fail',
  NC: 'Not Checked',
};

export const WATCH_TIME_TYPE = [
  {
    label: 'Seconds',
    value: 'SECONDS',
  },
  {
    label: 'Minutes',
    value: 'MINUTES',
  },
  {
    label: 'Hours',
    value: 'HOURS',
  },
];

export const ROKU_FEED_STATUS_LABELS = {
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  NOT_GENERATED: 'Not Generated',
};

export const ROKU_FEED_STATUS_COLORS = {
  PENDING: 'warning',
  PROCESSING: 'warning',
  COMPLETED: 'success',
  FAILED: 'error',
  NOT_GENERATED: 'info',
};

export const PUBLIC_DOMAIN = {
  YES: 'Yes',
  LK: 'Likely',
  PR: 'Probably',
  MY: 'Maybe',
  UV: 'Unverified',
  NO: 'No',
  PD: 'Not Checked',
};

export const POSTER_16X9_STATUS = {
  GD: 'Good',
  MS: 'Missing',
  NC: 'Not Checked',
  OC: 'Offensive Content',
  PQ: 'Poor Quality',
  WS: 'Wrong Size',
};

export const ALWAYS_PUBLISHED = {
  NO: 'No',
  PD: 'Public Domain',
  PL: 'Public Domain Likely',
  HQ: 'High Quality',
};

export const moduleAccessConfig = {
  video_library: ['view', 'create', 'edit', 'delete'],
  series_library: ['view', 'create', 'edit', 'delete'],
  manage_descriptions: ['view', 'manage'],
  license_management: ['view', 'manage'],
  genres: ['view', 'create', 'edit', 'delete'],
  playlists: ['view', 'create', 'edit', 'delete'],
  collections: ['view', 'create', 'edit', 'delete'],
  channel_management: ['view', 'create', 'edit', 'delete'],
  channel_assignment: ['view', 'manage'],
  rulesets: ['view', 'create', 'edit', 'delete'],
  manage_users: ['view', 'create', 'edit', 'delete'],
  advertising_tag_library: ['view', 'create', 'edit', 'delete'],
  advertising_assign_tags: ['view', 'manage'],
  analytics: ['view'],
};

export const accessModule = {
  video_library: 'Videos Library',
  series_library: 'Series Library',
  manage_descriptions: 'Manage Descriptions',
  license_management: 'License Management',
  genres: 'Genres',
  playlists: 'Playlists',
  collections: 'Collections',
  channel_management: 'Manage Channels',
  channel_assignment: 'Channel Assignment',
  rulesets: 'Assign/Schedule Content',
  manage_users: 'Manage Users',
  advertising_tag_library: 'Advertising > Tag Library',
  advertising_assign_tags: 'Advertising > Assign Tags',
  analytics: 'Analytics',
};

export const VIDEOJS_OPTIONS = {
  controls: true,
  preload: 'auto',
  responsive: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
  sources: [
    {
      src: '',
    },
  ],
};

export const VIDEO_EDIT_FIELDS = {
  description: 'Description',
  poster_16x9_status: 'Poster 16x9 Status',
  avod_validated: 'Avod License',
  restricted_content: 'Restricted Content',
  avod: 'Avod',
  video_type: 'Video Type',
  season: 'Season',
  episode_num: 'Episode Num',
  date_license_expiration: 'Date License Expiration',
  distributor_sku: 'Distributor Sku',
  categories: 'Categories',
  ms_notes: 'Ms Notes',
  offline: 'Offline',
  offline_reason: 'Offline Reason',
  geofencing_deny: 'Geofencing Deny',
  geofencing_allow: 'Geofencing Allow',
  region: 'Region',
  video_url: 'Video Url',
  trailer_url: 'Trailer Url',
  subtitle_en_url: 'Subtitle En Url',
  svod: 'Svod',
  ad_timing: 'Ad Timing',
  ad_timing_status: 'Ad Timing Status',
  imdb_id: 'Imdb Id',
  year: 'Year',
  decade: 'Decade',
  restricted_content_reason: 'Restricted Content Reason',
  trashy: 'Trashy',
  good_bad: 'Good Bad',
  mm_notes: 'Mm Notes',
  duration_minutes: 'Duration Minutes',
  avod_watched: 'Avod Watched (In Minutes)',
  public_domain: 'Public Domain',
  country: 'Country',
  restricted_content_source: 'Restricted Content Source',
  custom_royality: 'Custom Royality',
  cast: 'Cast',
  tvod: 'Tvod',
  description_status: 'Description Status',
  pnm_exclusives: 'Pnm Exclusives',
  avod_watched_7: 'Avod Weekly Watched (In Minutes)',
  tvod_iap: 'Tvod Price',
  five_star_rating: 'Five Star Rating',
  tvod_enabled: 'Tvod Enabled',
  plot_keywords: 'Plot Keywords',
  schedule_priority: 'Schedule Priority',
  exclude_from_db: 'Exclude From Db',
  quality_video: 'Quality Video',
  title_name_only: 'Title Name Only',
  directors: 'Directors',
  writers: 'Writers',
  resolution: 'Resolution',
  series_id: 'Series Id',
  collection_id: 'Collection Id',
  poster_16x9_url: 'Poster 16x9 Url',
  poster_3x4_url: 'Poster 3x4 Url',
  trashy_rating: 'Trashy Rating',
  aspect_ratio: 'Aspect Ratio',
  mpaa_rating: 'Mpaa Rating',
  genre_status: 'Genre Status',
  featured_playlist: 'Featured Playlist',
  quality_max_resolution: 'Quality Max Resolution',
  mm_notes2trailer: 'Mm Notes2trailer',
  video_status: 'Video Status',
  video_status_reason: 'Video Status Reason',
  video_status_reason_details: 'Video Status Reason Details',
  trailer_status: 'Trailer Status',
  trailer_status_reason: 'Trailer Status Reason',
};
