import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getVideoEditHistoryByUser = async (id, params = '') => {
  const options = prepareRequestOptions(
    `/admin/users/${id}/video-edit-history${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getVideoDescriptionEditHistoryByUser = async (id, params = '') => {
  const options = prepareRequestOptions(
    `/admin/users/${id}/video-description-edit-history${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getVideoEditHistoryByVideo = async (id, params = '') => {
  const options = prepareRequestOptions(
    `/admin/content/video/${id}/video-edit-history${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getVideoDescriptionEditHistoryByVideo = async (
  id,
  params = ''
) => {
  const options = prepareRequestOptions(
    `/admin/content/video/${id}/video-description-edit-history${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const approveVideoEdits = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/videos/approve-video-edits',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const rejectVideoEdits = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/videos/reject-video-edits',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const approveVideoDescriptionEdits = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/videos/approve-description-video-edits',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const rejectVideoDescriptionEdits = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/content/videos/reject-description-video-edits',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
