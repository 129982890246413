import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, LinearProgress } from '@mui/material';
import validateImage from '../../utils/Image-validation';
import { getSignedUrl, uploadFileToS3 } from '../../services/videos.service';

const S3ImageUpload = ({
  value,
  updateImage,
  s3Folder,
  aspectRatio,
  allowedSize,
  setSnackbarInfo,
  showAsPerOpacity,
  opacity,
}) => {
  const hiddenFileInput = useRef(null);
  const [imageURL, setImageURL] = useState(value);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    setImageURL(value);
  }, [value]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    setSnackbarInfo({
      show: false,
    });
    setBtnDisabled(true);
    setUploadProgress(1);
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message: 'No file selected.',
      });
      return;
    }

    const acceptedImages = [
      'image/jpeg',
      'image/gif',
      'image/png',
      'image/jpg',
      'image/vnd.microsoft.icon',
      'image/x-icon',
    ];
    if (!acceptedImages.includes(selectedFile.type)) {
      setSnackbarInfo({
        show: true,
        type: 'error',
        message: 'Please select an image of type jpeg, jpg, png, gif or ico.',
      });
      hiddenFileInput.current.value = '';
      return;
    }

    validateImage(selectedFile, aspectRatio, allowedSize)
      .then(async () => {
        const signedUrlData = await getSignedUrl({
          fileName: selectedFile.name,
          fileType: selectedFile.type,
          folder: s3Folder || 'uploads',
        });

        if (!signedUrlData) {
          return;
        }

        try {
          await uploadFileToS3(
            selectedFile,
            signedUrlData.data.uploadUrl,
            (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(progress);
            }
          );
          setBtnDisabled(false);
          setImageURL(signedUrlData.data.fileUrl);
          updateImage(signedUrlData.data.fileUrl);
          setSnackbarInfo({
            show: true,
            type: 'success',
            message: 'Image uploaded successfully.',
          });
          hiddenFileInput.current.value = '';
          setTimeout(() => {
            setUploadProgress(0);
          }, 250);
        } catch (error) {
          setImageURL('');
          updateImage('');
          setBtnDisabled(false);
          const message =
            error.response?.data?.message ||
            'Something went wrong, please try again.';
          setSnackbarInfo({
            show: true,
            type: 'error',
            message,
          });
          hiddenFileInput.current.value = '';
          setUploadProgress(0);
        }
      })
      .catch((err) => {
        setImageURL('');
        updateImage('');
        setBtnDisabled(false);
        setUploadProgress(0);
        const message =
          err?.message || 'Something went wrong, please try again.';
        setSnackbarInfo({
          show: true,
          type: 'error',
          message,
        });
      });
  };

  const handleRemoveImage = () => {
    updateImage('');
    setImageURL('');
    hiddenFileInput.current.value = '';
  };

  return (
    <>
      <input
        type="file"
        accept=".png, .jpg, .jpeg, .gif, .ico"
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <Box
        spacing={1}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          marginBottom: 10,
        }}
      >
        {imageURL && (
          <Box
            sx={{
              width: '100px',
              height: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              src={imageURL}
              style={{
                maxHeight: '100px',
                maxWidth: '100px',
                objectFit: 'contain',
                opacity: showAsPerOpacity ? opacity : 1,
              }}
              alt="Uploaded"
            />
          </Box>
        )}
        <Box
          item
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            width: '100%',
          }}
        >
          <Button
            onClick={handleClick}
            variant="outlined"
            size="medium"
            disabled={btnDisabled}
          >
            Select File
          </Button>
          {uploadProgress > 0 && uploadProgress <= 100 && (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '150px',
                gap: 10,
              }}
            >
              <LinearProgress
                style={{ width: '100%' }}
                variant="determinate"
                value={uploadProgress}
              />
              <p>{uploadProgress}%</p>
            </Box>
          )}
          {imageURL && (
            <Button
              onClick={handleRemoveImage}
              variant="outlined"
              size="medium"
            >
              Remove File
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

S3ImageUpload.propTypes = {
  value: PropTypes.string.isRequired,
  updateImage: PropTypes.func.isRequired,
  s3Folder: PropTypes.string,
  aspectRatio: PropTypes.number.isRequired,
  allowedSize: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSnackbarInfo: PropTypes.func.isRequired,
  showAsPerOpacity: PropTypes.bool,
  opacity: PropTypes.number,
};

S3ImageUpload.defaultProps = {
  s3Folder: 'partners',
  showAsPerOpacity: false,
  opacity: 100,
};

export default S3ImageUpload;
