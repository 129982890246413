import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getListData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/genres/list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const createNewData = async (payload) => {
  const options = prepareRequestOptions(
    '/admin/genres/create',
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const viewDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/genres/${id}/view`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const updateDataById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/genres/${id}/update`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const deleteDataById = async (id) => {
  const options = prepareRequestOptions(
    `/admin/genres/${id}/delete`,
    'DELETE',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const addVideosById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/genres/${id}/add-video`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};
export const removeVideosById = async (id, payload) => {
  const options = prepareRequestOptions(
    `/admin/genres/${id}/remove-video`,
    'POST',
    payload,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchableListData = async () => {
  const options = prepareRequestOptions(
    '/admin/genres/search-list',
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchableRokuPrimaryGenresData = async () => {
  const options = prepareRequestOptions(
    '/admin/roku-primary-genres/search-list',
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchablePrimaryGenresData = async () => {
  const options = prepareRequestOptions(
    '/admin/primary-genres/search-list',
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getSearchableSecondaryGenresData = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/secondary-genres/search-list${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
