import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListAppSessions from '../../components/reports/list-app-sessions.component';

const ReportsAppSessionsView = () => {
  const pageTitle = 'Analytics / Engagement / Views by Session (App)';

  return (
    <AppLayout pageTitle={pageTitle} showFilter>
      <ListAppSessions />
    </AppLayout>
  );
};

export default ReportsAppSessionsView;
