import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  Stack,
  Box,
  Paper,
  FormHelperText,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import SnackbarInfo from '../common/snackbar-info.component';
import { updateDataById } from '../../services/apps.service';
import Status from '../form-elements/status.component';
import ChannelGenres from '../form-elements/channel-genres.component';

const AppBasicInfo = ({
  channelDetail,
  type,
  handleRefreshData,
  formValueChangedCallback,
}) => {
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm({
    defaultValues: {
      title: channelDetail.title || '',
      displayTitle: channelDetail.displayTitle || '',
      code: channelDetail.code || '',
      status: channelDetail.status || 0,
      gaMeasurement: channelDetail.gaMeasurement || '',
      gtmCode: channelDetail.gtmCode || '',
      image: channelDetail.imageUrl || '',
      introVideo: channelDetail.introVideoUrl || '',
      migrationVideo: channelDetail.migrationVideoUrl || '',
      marketingPromotionalVideo: channelDetail.marketingPromotionVideoUrl || '',
      hintVideoForPaidUser: channelDetail.hintVideoUrlForPaidUser || '',
      hintVideoForFreeUser: channelDetail.hintVideoUrlForFreeUser || '',
      channelDomain: channelDetail.channelDomainUrl || '',
      ipToLocationApiUrl: channelDetail.ipServiceUrl || '',
      analyticsEndpoint: channelDetail.analyticsEndpointUrl || '',
      analyticsApiKey: channelDetail.analyticsApiKey || '',
      analyticsInterval: channelDetail.analyticsInterval.toString() || '',
      channelGenres: {
        id: channelDetail.channelGenreId || null,
        name: channelDetail.channelGenreName || 'None',
      },
      showAdsOnSvodTvod: !!(channelDetail.alwaysShowAds || 0),
      allowGuestVideoPlayback: !!(channelDetail.allowGuestPlayback || 0),
      allowLinkDevice: !!(channelDetail.allowLinkDevice || 0),
      allowPALSDK: !!(channelDetail.allowPalSdk || 0),
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      ...data,
      type,
    };
    delete payload.code;
    updateDataById(channelDetail.id, payload)
      .then(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'success',
          message: 'Channel basic info updated successfully.',
        });
        formValueChangedCallback(false);
        handleRefreshData();
        setFormSubmitted(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      formValueChangedCallback(true);
    } else {
      formValueChangedCallback(false);
    }
  }, [Object.keys(dirtyFields).length]);

  return (
    <Box
      component={Paper}
      sx={{
        padding: 2,
      }}
    >
      <form id="channel-basic-info-form" onSubmit={handleSubmit(onFormSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="title"
              control={control}
              rules={{
                ...validationRules.REQUIRED,
                ...maxLengthValidation(100),
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name *"
                  type="text"
                  error={!!errors.title}
                  helperText={errors?.title?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="displayTitle"
              control={control}
              rules={{
                ...validationRules.REQUIRED,
                ...maxLengthValidation(100),
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Display Name *"
                  type="text"
                  error={!!errors.displayTitle}
                  helperText={errors?.displayTitle?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="code"
              control={control}
              rules={{
                ...validationRules.REQUIRED_CHANNEL_CODE,
                ...maxLengthValidation(30),
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Acronym *"
                  type="text"
                  autoComplete="off"
                  error={!!errors.code}
                  helperText={errors?.code?.message || null}
                  fullWidth
                  variant="filled"
                  disabled={channelDetail.id}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="status"
              control={control}
              rules={validationRules.REQUIRED}
              render={({ field: { onChange, value } }) => (
                <Status
                  id="channel_status"
                  name="channel_status"
                  label="Status"
                  defaultValue={value}
                  onChange={onChange}
                  validOptions={[0, 1]}
                  sx={{ width: '100%' }}
                  error={errors?.status?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="gaMeasurement"
              control={control}
              rules={{ ...maxLengthValidation(25) }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="GA Measurement"
                  type="text"
                  error={!!errors.gaMeasurement}
                  helperText={errors?.gaMeasurement?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="gtmCode"
              control={control}
              rules={{ ...maxLengthValidation(25) }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="GTM Code"
                  type="text"
                  autoComplete="off"
                  error={!!errors.gtmCode}
                  helperText={errors?.gtmCode?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="image"
              control={control}
              rules={maxLengthValidation(255)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Image"
                  type="text"
                  error={!!errors.image}
                  helperText={errors?.image?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="introVideo"
              control={control}
              rules={maxLengthValidation(255)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Intro Video"
                  type="text"
                  error={!!errors.introVideo}
                  helperText={errors?.introVideo?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="migrationVideo"
              control={control}
              rules={maxLengthValidation(255)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Migration Video"
                  type="text"
                  error={!!errors.migrationVideo}
                  helperText={errors?.migrationVideo?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="marketingPromotionalVideo"
              control={control}
              rules={maxLengthValidation(255)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Marketing Promotional Video"
                  type="text"
                  error={!!errors.marketingPromotionalVideo}
                  helperText={
                    errors?.marketingPromotionalVideo?.message || null
                  }
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="hintVideoForPaidUser"
              control={control}
              rules={maxLengthValidation(255)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Hint Video For Paid User W/O A/c"
                  type="text"
                  error={!!errors.hintVideoForPaidUser}
                  helperText={errors?.hintVideoForPaidUser?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="hintVideoForFreeUser"
              control={control}
              rules={maxLengthValidation(255)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Hint Video For Free User"
                  type="text"
                  error={!!errors.hintVideoForFreeUser}
                  helperText={errors?.hintVideoForFreeUser?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="channelDomain"
              control={control}
              rules={{
                ...maxLengthValidation(100),
                ...validationRules.CHANNEL_DOMAIN_URL,
              }}
              render={({ field }) => (
                <>
                  <TextField
                    {...field}
                    label="Channel Domain"
                    type="text"
                    error={!!errors.channelDomain}
                    helperText={errors?.channelDomain?.message || null}
                    fullWidth
                    variant="filled"
                  />
                  <FormHelperText>
                    Example:{' '}
                    <strong style={{ marginRight: '10px' }}>
                      https://example.com
                    </strong>
                    <strong>https://www.example.com</strong>
                  </FormHelperText>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="ipToLocationApiUrl"
              control={control}
              rules={maxLengthValidation(100)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="State / Country API Url"
                  type="text"
                  error={!!errors.ipToLocationApiUrl}
                  helperText={errors?.ipToLocationApiUrl?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="analyticsEndpoint"
              control={control}
              rules={maxLengthValidation(100)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Analytics Endpoint"
                  type="text"
                  error={!!errors.analyticsEndpoint}
                  helperText={errors?.analyticsEndpoint?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="analyticsApiKey"
              control={control}
              rules={maxLengthValidation(55)}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Analytics API Key"
                  type="text"
                  error={!!errors.analyticsApiKey}
                  helperText={errors?.analyticsApiKey?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="analyticsInterval"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Video Progress Analytics Interval"
                  type="number"
                  error={!!errors.analyticsInterval}
                  helperText={errors?.analyticsInterval?.message || null}
                  fullWidth
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="channelGenres"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ChannelGenres
                  id="channel_genres"
                  name="channel_genres"
                  label="Select Channel Genre"
                  defaultValue={value}
                  onChange={onChange}
                  sx={{ width: '100%' }}
                  error={errors?.channelGenres?.message || ''}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="showAdsOnSvodTvod"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Show Ads On SVOD/TVOD"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="allowGuestVideoPlayback"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Allow Guest Video Playback"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="allowLinkDevice"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Allow Link Device"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="allowPALSDK"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value}
                      onChange={onChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="primary"
                    />
                  }
                  label="Allow PAL SDK"
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 2.5 }}
      >
        <Button
          type="submit"
          form="channel-basic-info-form"
          color="primary"
          variant="contained"
          disabled={formSubmitted}
        >
          Save Basic Info
        </Button>
        <Typography variant="body2" color="secondary">
          * indicates a required field
        </Typography>
      </Stack>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Box>
  );
};

AppBasicInfo.propTypes = {
  channelDetail: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  handleRefreshData: PropTypes.func.isRequired,
  formValueChangedCallback: PropTypes.func.isRequired,
};

export default AppBasicInfo;
